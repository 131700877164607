import Vue from 'vue'
import VueRouter from 'vue-router'
import expenseRoutes from './expenseRoutes'
import catalogRoutes from './catalogRoutes'
import hedgeRoutes from './hedgeRoutes'
import inventoryRoutes from './inventoryRoutes'
import financeRoutes from './financeRoutes'
import logisticsRoutes from './logisticsRoutes'
import lotRoutes from './lotRoutes'
import purchaseRoutes from './purchaseRoutes'
import yardRoutes from './yardRoutes'
import warehouseRoutes from './warehouseRoutes'
import publicRoutes from '@/router/publicRoutes'

Vue.use(VueRouter)

/**
 * gotoDoc and urlToRoute mixins utilize reverse to get correct urls
 * the format for reverse should be either 'documents-single' or 'documents-many'
 * This enables the resolution of Doc names Document-ID and urls https://api.trackkat.com/api/DOCUMENT/ID/
 */

let routes = [
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "index-routes" */ '@/views/Calendar/CalendarView'),
    meta: { permission: 'view-calendar' }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "index-routes" */ '@/views/Dashboard/Dashboard'),
    meta: { permission: 'view-basics' }
  },
  {
    path: '/patch-notes/',
    name: 'patch-notes',
    component: () => import(/* webpackChunkName: "index-routes" */ '@/views/PatchNotes'),
    meta: { permission: 'view-basics' }
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: () => import(/* webpackChunkName: "index-routes" */ '@/views/UserProfile/UserProfile'),
    meta: { permission: 'view-basics' }
  },
  {
    path: '/report-list',
    name: 'reports',
    component: () => import(/* webpackChunkName: "index-routes" */ '@/views/Reports/ReportList'),
    meta: { permission: 'view-reports' }
  },
  {
    path: '/index/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index-routes" */ '@/views/Index')
  },
  ...publicRoutes,
  ...catalogRoutes,
  ...expenseRoutes,
  ...hedgeRoutes,
  ...financeRoutes,
  ...logisticsRoutes,
  ...lotRoutes,
  ...purchaseRoutes,
  ...inventoryRoutes,
  ...yardRoutes,
  ...warehouseRoutes,
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "index-routes" */ '@/views/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/' && to.hash.startsWith('#/')) {
    next(to.hash.slice(1))
  } else {
    next()
  }
})

/**
 * Dynamically set the page titles
 */
router.beforeEach((to, from, next) => {
  if (to.name) {
    let nameSet = to.name
      .split('-')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    if (to.params.id) nameSet.push(to.params.id)
    if (to.query.search) nameSet.push('search for ' + to.query.search)
    if (to.query.page) nameSet.push('#' + to.query.page)
    nameSet.push('- TrackKat.com')
    document.title = nameSet.join(' ')
  }
  next()
})

/**
 * Log user route changes
 */
router.beforeEach((to, from, next) => {
  Vue.prototype.$logger.info(`User navigation URL=${window.location} PAGE=${to.name}`)
  next()
})

export default router
