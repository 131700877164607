<template>
  <div>
    <slot>
      <v-row>
        <v-col
          class="text-center"
          cols="6"
          offset="3"
          md="4"
          offset-md="4"
        >
          LOADING...
          <v-progress-linear
            color="grey accent-4"
            indeterminate
            rounded
            height="6"
          />
        </v-col>
      </v-row>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'AppLoadingIndicator'
}
</script>
