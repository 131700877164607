import router from './router'
import axios from 'axios'

/**
 * Resolves a route for a given component name
 * @param name
 * @param isSingle
 * @returns {RouteConfig}
 */
function reverseRoute (name, isSingle = false) {
  name = name.toLowerCase()
  name = (isSingle) ? name + '-single' : name + '-many'
  return router.options.routes.find(route => route.reverse && route.reverse === name)
}

export default {
  methods: {
    /**
     * Go to an entity route based on a given document
     * @param {String} document
     */
    gotoDoc: async (document) => {
      const documentName = document.split('-')[0]
      let documentId = document.split('-')[1]
      let i = 0
      let routeName = documentName.split('').reduce((accu, char) => {
        if (char.toUpperCase() === char && i > 0) accu += '-' + char.toLowerCase()
        else accu += char.toLowerCase()
        i++
        return accu
      }, '')
      if (routeName === 'inventory-line') {
        routeName = 'inventory'
        const invRes = await axios.get(`/api/inventory_lines/${documentId}`)
        documentId = invRes.data.inventory_id
      }
      if (routeName) {
        return router.push({ name: routeName, params: { id: documentId, updated: true } })
      } else {
        throw Error('Unable to resolve Document name ' + documentName)
      }
    },
    /**
     * Accepts a URL and attempts to resolve into a name and route to object
     * @throws Error Unable to parse url
     * @throws Error Unable tor resolve route
     * @param url
     * @returns {[string, {name: string, [params: {id: number}]}]}
     */
    urlToRouteTo: (url) => {
      const regex = /\/api\/([a-zA-Z-_]+)\/(\d+)\/?$/i
      const result = url.match(RegExp(regex))
      if (!result) {
        throw Error('Unable to parse url: ' + url)
      }
      const apiName = result[1]
      const apiId = result[2]
      const hasId = !!apiId
      const route = reverseRoute(result[1], hasId)
      // return if no route found
      if (!route) {
        throw Error(`Unable to resolveRoute(${apiName}, ${hasId}) `)
      }
      let text = route.name
      const to = { name: route.name, disabled: false }
      if (hasId) {
        text += ' #' + apiId
        to.params = { id: Number(apiId) }
      }
      return [text, to]
    },
    /**
     * Remove all null and empty string values
     * @param {Object} obj
     * @return {Object}
     */
    filterObject (obj) {
      const filtered = {}
      for (const [key, val] of Object.entries(obj)) {
        if (val === null || (typeof val === 'string' && val.trim() === '')) continue
        filtered[key] = val
      }
      return filtered
    },
    /**
     * Get the text for a given value in an array of objects
     * @param {String} value
     * @param {Array[Object]} arr
     */
    getTextGivenValue (value, arr) {
      for (const obj of arr) {
        if (obj.value === value) return obj.text
      }
      return null
    },
    /**
     * Get the value for a given text in an array of objects
     * @param {String} value
     * @param {Array[Object]} arr
     */
    getValueGivenText (text, arr) {
      for (const obj of arr) {
        if (obj.text === text) return obj.value
      }
      return null
    },
    /**
     * Get the more human readable version of a designation (house, brokered, separate)
     * @param {String} designation
     * @param {Array of Objects} designations
     */
    formatDesignation (designation, designations) {
      if (!designation) return null
      for (const obj of designations) {
        if (obj.value === designation) return obj.text
      }
      return null
    },
    /**
     * Get the more human readable version of a lot status
     * @param {String} status
     * @param {Array of Objects} lotStatuses
     */
    formatLotStatus (status, lotStatuses) {
      if (!status) return null
      for (const obj of lotStatuses) {
        if (obj.value === status) return obj.text
      }
      return null
    },
    /**
     * Get the more human readable version of a purchase status
     * @param {String} status
     * @param {Array of Objects} purchaseStatuses
     */
    formatPurchaseStatus (status, purchaseStatuses) {
      if (!status) return null
      for (const obj of purchaseStatuses) {
        if (obj.value === status) return obj.text
      }
      return null
    },
    /**
     * Convert to a octet array for fileSaver
     * @param {Object} s - the workbook to be converted for saving
     */
    convertToOctetArray (s) {
      const buf = new ArrayBuffer(s.length)
      const view = new Uint8Array(buf)
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF
      return buf
    },
    /**
     * Return a formatted date string
     * @param {String} date
     * @return {String}
     */
    formatDate (date) {
      if (date && date !== '') {
        if (date.split('').indexOf('T') === -1) {
          const ymd = date.split(' ')[0]
          const [year, month, day] = ymd.split('-')
          return `${month}/${day}/${year}`
        } else {
          const ymd = date.split('T')[0]
          const [year, month, day] = ymd.split('-')
          return `${month}/${day}/${year}`
        }
      }
      return '--'
    },
    /**
     * Add dollar sign and commas
     * @param {String | Float} money
     * @return {String}
     */
    formatMoney (money) {
      return '$' + this.addCommas(parseFloat(money).toFixed(2))
    },
    /**
     * Add commas to larger numbers
     * @param {String | Float} money
     * @return {String}
     */
    addCommas (number) {
      number = number + ''
      return number.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    /**
     * Capitalize the first letter of the given string
     * @param {String} str
     * @return {String}
     */
    capitalize (str) {
      if (typeof str !== 'string' || str.length < 1) return ''
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    /**
     * Remove all spaces from a string
     * @param {String} str
     * @return {String}
     */
    removeSpaces (str) {
      if (typeof str !== 'string' || str.length < 1) return ''
      return str.split('').filter(char => char !== ' ').join('')
    },
    /**
     * Reset all data values given a data object
     */
    reset () {
      const values = this.setInitialData()
      for (const [key, value] of Object.entries(values)) {
        this[key] = value
      }
    }
  }
}
