import axios from 'axios'
import editTransit from './transitDashboardEdit'
import requests from './transitDashboardRequests'

/**
 * Returns True if found
 * @param transit
 * @param search
 */
function transitFilter (transit, search) {
  const s = search.toLowerCase()
  if (String(transit.id).includes(s)) return true
  if (transit.check_ins.find(x => x.name.toLowerCase().includes(s))) return true
  if (transit.manifests.find(x => x.name.toLowerCase().includes(s))) return true
  return false
}

export default {
  namespaced: true,
  state: {
    filter: '',
    transits: {},
    locations: {},
    destinationFilter: [],
    destinationOptions: []
  },
  getters: {
    getFilter (state) {
      return state.filter
    },
    getDestinationOptions (state) {
      return state.destinationOptions
    },
    getDestinationFilter (state) {
      return state.destinationFilter
    },
    getBookedTransits (state) {
      let carry = {}
      for (const [key, value] of Object.entries(state.transits)) {
        if (!['NE', 'IT', 'DI', 'RE'].includes(value.status)) continue
        if (!transitFilter(value, state.filter)) continue
        if (state.destinationFilter.length && !state.destinationFilter.includes(value.to_address)) continue
        carry[key] = value
      }
      return Object.values(carry).sort((a, b) => new Date(b.delivery_date) - new Date(a.delivery_date))
    },
    getReceivedTransits (state) {
      let carry = {}
      for (const [key, value] of Object.entries(state.transits)) {
        if (value.status !== 'DE') continue
        if (!transitFilter(value, state.filter)) continue
        if (state.destinationFilter.length && !state.destinationFilter.includes(value.to_address)) continue
        carry[key] = value
      }
      return carry
    }
  },
  mutations: {
    setFilter (state, value) {
      state.filter = value
    },
    // setDestinationOptions (state, locations) {
    //   state.destinationOptions = locations
    // },
    addDestinationOptions (state, locations) {
      const options = state.destinationOptions
      state.destinationOptions = options.concat(locations)
    },
    setDestinationFilter (state, destinations) {
      state.destinationFilter = destinations
    },
    addTransit (state, transit) {
      const obj = Object.assign({}, state.transits)
      obj[transit.url] = transit
      state.transits = obj
    },
    removeTransit (state, transitUrl) {
      const obj = Object.assign({}, state.transits)
      if (obj[transitUrl]) delete obj[transitUrl]
      state.transits = obj
    }
  },
  actions: {
    /**
     * Fetches divisions and create locations if necessary
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchDivisionDestinations ({ commit, dispatch }) {
      const destinations = []
      const params = { is_inactive: false, page_size: 0 }
      const res = await axios.get('/api/divisions/', { params })

      if (res.status !== 200) return

      for (const division of res.data) {
        if (division.logistics_location) {
          destinations.push(division.logistics_location)
        } else {
          commit('setSnackbarWarning', `Division ${division.name} is missing Logistics Location`, { root: true })
        }
      }
      commit('addDestinationOptions', destinations)
      dispatch('applyDefaultDestinationFilter')
    },
    // sets default filters based on the user's division code
    async applyDefaultDestinationFilter ({ commit, state, rootGetters, dispatch }) {
      if (rootGetters.can('see-all-destinations')) return
      // setting the default destination filters
      await dispatch('fetchConfig', null, { root: true })
      const destinationNames = rootGetters['getConfigLocations'].reduce((carry, location) => {
        carry[location.code] = location.name
        return carry
      }, {})

      const code = rootGetters.currentUser.divisioncode
      if (code && destinationNames[code]) {
        const urls = state.destinationOptions.filter(d => d.name === destinationNames[code]).map(d => d.url)
        commit('setDestinationFilter', urls)
      }
    },
    async createTransit ({ commit }, payload) {
      if (!payload.hasOwnProperty('inventorymanifest_set')) {
        payload['inventorymanifest_set'] = []
      }
      const res = await axios.post('/logistics/transits/', payload)
      if (res.status === 201) {
        commit('addTransit', res.data)
      }
      return res
    },
    async patchTransit ({ commit }, { transitUrl, payload }) {
      const res = await axios.patch(transitUrl, payload)
      if (res.status === 200) commit('addTransit', res.data)
      return res
    },
    /**
     * Deletes a transit entry
     * Re-fetches requests because they should have been removed on delete
     * @param commit
     * @param state
     * @param transit
     * @return {Promise<void>}
     */
    async deleteTransit ({ dispatch, commit }, transit) {
      const res = await axios.delete(transit.url)
      if (res.status === 204) {
        commit('removeTransit', transit.url)
        dispatch('transitDashboardStore/requests/fetchTransitRequests', null, { root: true })
      }
    },
    async autoTransferManifest ({ commit }, transit) {
      try {
        const res = await axios.post(`/logistics/transits/${transit.id}/transfer_manifest/`)
        if (res.status === 201) {
          commit('addTransit', res.data.transit)
          commit('editTransit/setTransit', null)
          commit('addTransit', res.data.new_transit)
        }
        return res
      } catch (err) {
        commit('setSnackbarError', 'Unable to transfer manifest: ' + err.message, { root: true })
      }
      return false
    },
    mergeTransits ({ commit }, { targetTransitId, transitIds }) {
      return axios.post(`/logistics/transits/${targetTransitId}/merge_transits/`, {
        'transit_ids': transitIds
      })
    },
    /**
     * Fetches all of the Transit entries that are Open or are within a 30 day time frame
     * @param commit
     * @param deliveryDateGte
     * @returns {Promise<AxiosResponse<T>>}
     */
    async fetchTransits ({ commit }) {
      const fields = [
        'id',
        'url',
        'name',
        'actual_cost',
        'actual_cost_denomination',
        'actual_cost_date',
        'additional_fees',
        'additional_fees_denomination',
        'additional_fees_date',
        'transitfile_set',
        'transitlot_set',
        'broker_data',
        'carrier_data',
        'rate_quote',
        'from_address',
        'from_address_details',
        'to_address',
        'to_address_details',
        'pickup_date',
        'delivery_date',
        'status',
        'status_display',
        'color',
        'check_ins',
        'manifests',
        'fee_description',
        // 'get_status_display',
        'dispute_notes',
        'booked_by__username',
        'booked_at',
        'received_by__username',
        'received_at',
        'references',
        'invoice_number',
        'invoice_date'
      ].join(',')
      const params = {
        requires_transit: true,
        transit_type: 'IN',
        page_size: 0,
        status__in: ['NE', 'IT', 'RE', 'DI', 'DE'].join(','),
        fields
      }
      const response = await axios.get('/logistics/transits/', { params: params })
      for (let i = 0; i < response.data.length; i++) {
        commit('addTransit', response.data[i])
      }
    }
  },
  modules: {
    requests,
    editTransit
  }
}
