import { ValidationProvider, extend } from 'vee-validate'
import {
  required,
  required_if, /* eslint-disable-line camelcase */
  email,
  confirmed,
  max_value, /* eslint-disable-line camelcase */
  max,
  min,
  length,
  min_value, /* eslint-disable-line camelcase */
  double
} from 'vee-validate/dist/rules'

import isURL from 'validator/lib/isURL'

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('max_value', {
  ...max_value, /* eslint-disable-line camelcase */
  message: 'Exceeded maximum value'
})

extend('min_value', {
  ...min_value, /* eslint-disable-line camelcase */
  message: 'This value is below the minimum'
})

extend('email', {
  ...email,
  message: 'Input must be an email'
})

extend('length', {
  ...length,
  message: 'Bad Length Provided'
})

extend('confirmed', {
  ...confirmed,
  message: 'Both fields must match'
})

extend('min', {
  ...min,
  message: 'Too short'
})

extend('max', {
  ...max,
  message: 'Exceeded max length'
})

extend('double', {
  ...double,
  message: 'Must be a Number/Decimal'
})

extend('required_if', {
  ...required_if, /* eslint-disable-line camelcase */
  message: 'This field is required'
})

extend('valid_vendor_number', {
  validate: value => /^\w{1,2}?-\d{5}$/.test(value),
  message: 'Enter a valid Vendor No. (#-#####) OR (##-#####)'
})

extend('url', {
  validate: value => isURL(value),
  message: 'Must be a valid Url'
})

extend('valid_lot_name', {
  validate: value => {
    return /^[A-Z0-9-]+$/.test(value)
  },
  message: 'Can only contain capital letters, numbers, and dashes (ex. PGM-123)'
})

function countDecimals (count) {
  const s = count.toString().split('.')
  if (s.length < 2) return 0
  return s[1].length
}

extend('decimal', {
  validate: (value, args) => {
    return countDecimals(value) <= Number(args)
  },
  message: 'Exceeded max decimal places.'
})

extend('two_decimal', {
  validate: value => {
    return countDecimals(value) < 3
  },
  message: 'May only have 2 decimal places.'
})

extend('one_decimal', {
  validate: value => {
    return countDecimals(value) < 2
  },
  message: 'May only have 2 decimal places.'
})

extend('special_char', {
  validate: value => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value),
  message: 'Must contain a special character'
})

extend('one_capital', {
  validate: value => /[A-Z]/.test(value),
  message: 'Must contain at least 1 capital letter'
})

extend('one_lowercase', {
  validate: value => /[a-z]/.test(value),
  message: 'Must contain at least 1 lower case letter'
})

extend('one_number', {
  validate: value => /\d/.test(value),
  message: 'Must contain at least 1 number'
})

extend('must_be_zero', {
  validate: value => Number(value) === 0,
  message: 'Must be Zero'
})

export default ValidationProvider
