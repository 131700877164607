/*
* Logging module for Trackkat.
* Logs to console in development.
* Logs to external logging server endpoint specified in .env in production.
* Should include authorization header with requests.  Token specified in .env.
*
* Log v2 uses the Athena compatible date format, ensures messages are arrays not dicts
*/
import axios from 'axios'
import store from '@/store'

const Logger = require('js-logger')

// create new axios instance without Trackat's interceptors
const axiosInstance = axios.create()

// Gets the current date in the yyyy-mm-dd hh:mm:ss[.fffffffff] format for Athena
function getCurrentDateTime () {
  const date = new Date()
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')
  const milliseconds = String(date.getMilliseconds()).padEnd(9, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`
}

if (process.env.NODE_ENV === 'production') {
  Logger.setLevel(Logger.ERROR)
  // handlers control where the logs go
  // side note: js-logger supports multiple handlers
  Logger.setHandler((messages, context) => {
    if (!Array.isArray(messages)) {
      messages = Object.values(messages)
    }
    const log = {
      timestamp: getCurrentDateTime(),
      log_level: context.level.name,
      messages,
      user: store.getters.currentUser ? store.getters.currentUser.id : null,
      system: `Trackkat Client ${process.env.PACKAGE_VERSION}`,
      log_version: 2
    }
    axiosInstance.post(process.env.VUE_APP_LOGGING_SERVER_URL, { message: log })
  })
} else {
  Logger.setLevel(Logger.DEBUG)
  Logger.setHandler((messages, context) => {
    if (messages.length === 1) {
      console.log(context.level.name, messages[0])
    } else {
      console.group(context.level.name)
      for (const message of messages) {
        console.log(message)
      }
      console.groupEnd()
    }
  })
}

export default Logger
