import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './filters'
import mixins from './mixins'
import vuetify from './plugins/vuetify'
import ValidationProvider from './plugins/veeValidate'
import { ValidationObserver } from 'vee-validate'
import InfiniteLoading from 'vue-infinite-loading'
import draggable from 'vuedraggable'
import JsonExcel from 'vue-json-excel'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Logger from './logger'

import AppLoadingIndicator from '@/base/components/AppLoadingIndicator'
import CardListItem from '@/base/components/CardListItem'
import BaseDialog from './base/components/BaseDialog'
import EditDialog from './base/components/EditDialog'
import BaseTitle from './base/components/BaseTitle'
import DateInput from '@/base/components/DateInput'
import DateRangeInput from '@/base/components/DateRangeInput'
import DollarSign from '@/base/components/DollarSign'
import ExpansionPanels from '@/base/components/ExpansionPanels'
import HelpDialog from '@/base/components/HelpDialog'
import InfiniteList from '@/base/components/InfiniteList'
import InfoTooltip from '@/base/components/InfoTooltip'
import MaterialCard from '@/base/components/MaterialCard'
import PaginatedCombobox from '@/base/components/PaginatedCombobox'
import ParentTabs from '@/base/components/ParentTabs'
import PaginatedAutocomplete from '@/base/components/PaginatedAutocomplete'
import TimeInput from '@/base/components/TimeInput'
import Unauthorized from '@/base/components/Unauthorized'
import UrlBreadcrumbs from '@/base/components/UrlBreadcrumbs'
import BaseButton from '@/base/components/BaseButton'
import RouterLinkId from '@/base/components/RouterLinkId'
import DatatableDynamicRow from '@/base/components/DatatableDynamicRow'
import ExternalLink from './base/components/ExternalLink'
import authPlugin from '@/auth'
import axiosSetup from '@/plugins/axios'
import TextFab from '@/components/Buttons/TextFab.vue'
import FxTag from '@/components/FxTag.vue'
import RulesCurrency from '@/components/ValidationFields/RulesCurrency.vue'
import UomTag from '@/components/UomTag.vue'

axiosSetup()

Chart.plugins.register(ChartDataLabels)
Vue.config.productionTip = false
Vue.component('downloadExcel', JsonExcel)
Vue.component('draggable', draggable)
Vue.component('InfiniteLoading', InfiniteLoading)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// Form validation provider
Vue.component('AppLoadingIndicator', AppLoadingIndicator)
Vue.component('BaseTitle', BaseTitle)
Vue.component('BaseDialog', BaseDialog)
Vue.component('EditDialog', EditDialog)
Vue.component('CardListItem', CardListItem)
Vue.component('DateInput', DateInput)
Vue.component('DateRangeInput', DateRangeInput)
Vue.component('DollarSign', DollarSign)
Vue.component('ExpansionPanels', ExpansionPanels)
Vue.component('HelpDialog', HelpDialog)
Vue.component('MaterialCard', MaterialCard)
Vue.component('InfiniteList', InfiniteList)
Vue.component('InfoTooltip', InfoTooltip)
Vue.component('PaginatedCombobox', PaginatedCombobox)
Vue.component('ParentTabs', ParentTabs)
Vue.component('PaginatedAutocomplete', PaginatedAutocomplete)
Vue.component('TimeInput', TimeInput)
Vue.component('TextFab', TextFab)
Vue.component('Unauthorized', Unauthorized)
Vue.component('UrlBreadcrumbs', UrlBreadcrumbs)
Vue.component('BaseButton', BaseButton)
Vue.component('ExternalLink', ExternalLink)
Vue.component('DatatableDynamicRow', DatatableDynamicRow)
Vue.component('RouterLinkId', RouterLinkId)
Vue.component('FxTag', FxTag)
Vue.component('RulesCurrency', RulesCurrency)
Vue.component('Uom', UomTag)

Vue.mixin(mixins)
Vue.use(authPlugin)

Vue.prototype.$logger = Logger

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
