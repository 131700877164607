<!--
Provides a very basic ID link
Implement ID + Name for routing to work
Also has a slot to replace the default button
-->
<template>
  <router-link
    :to="{ name, params: { id } }"
    :target="target"
  >
    <slot>
      <v-btn
        elevation="0"
        small
      >
        {{ display || id }}
      </v-btn>
    </slot>
  </router-link>
</template>

<script>
export default {
  name: 'RouterLinkId',
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    display: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_self'
    }
  }
}
</script>
