<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ title }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot>
          {{ content }}
        </slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'ExpansionPanels',
  props: {
    title: { type: String, required: false, default: null },
    content: { type: String, required: false, default: null }
  }
}
</script>
