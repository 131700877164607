export default [
  {
    path: '/inventory-on-hand',
    name: 'inventory-on-hand',
    component: () => import(/* webpackChunkName: "warehouse-routes" */ '@/views/Warehouse/InventoryOnHandView'),
    meta: { permission: 'view-inventory-on-hand' }
  },
  {
    path: '/inventory-on-hand/manage-locations',
    name: 'inventory-on-hand-locations',
    component: () => import(/* webpackChunkName: "warehouse-routes" */ '@/views/Warehouse/InventoryLocationsManageView'),
    meta: { permission: 'view-inventory-on-hand' }
  },
  {
    path: '/inventory-on-hand/:id',
    name: 'inventory-on-hand-box',
    component: () => import(/* webpackChunkName: "warehouse-routes" */ '@/views/Warehouse/InventoryBoxView'),
    meta: { permission: 'view-inventory-on-hand' }
  },
  {
    path: '/inventory-on-hand/:id/edit',
    name: 'inventory-on-hand-box-edit',
    component: () => import(/* webpackChunkName: "warehouse-routes" */ '@/views/Warehouse/InventoryBoxEditView'),
    meta: { permission: 'view-inventory-on-hand' }
  }
]
