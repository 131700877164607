let ax = {}

// GENERAL
ax['debug'] = () => false
ax['view-basics'] = () => true
ax['photobooth-import'] = () => false
ax['photobooth-list'] = () => false
ax['view-calendar'] = () => true

ax['edit-lot'] = (state, getters) => getters['lotStore/lot'].status && getters['lotStore/lot'].status !== 'CO'

// ability to update lot status is dependent on current status
ax['update-lot-status'] = function (_, getters) {
  const COORDINATOR = 'coordinator'
  const WAREHOUSE = 'warehousing'

  const status = getters['lotStore/lot'].status
  if (['HE', 'CU', 'CT'].includes(status)) {
    return getters.hasRole(COORDINATOR) || getters.hasRole(WAREHOUSE)
  } else if (['SH', 'RE', 'RC'].includes(status)) {
    return getters.hasRole(COORDINATOR)
  }
  return false
}

// Export the actions
export default ax
