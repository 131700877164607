import axios from 'axios'
import Vue from 'vue'

export default {
  getters: {
    roles: () => {
      const groups = Vue.prototype.$auth.user?.groups
      return !groups ? [] : groups
    },
    currentUser: () => {
      return Vue.prototype.$auth.user
    },
    /**
     * Passing back a function to see if a user has a role
     * @return {(function(*): (boolean|*))|*}
     */
    hasRole: () => (roleName) => {
      const groups = Vue.prototype.$auth.user?.groups
      if (!groups) return false
      return !!groups.find(r => r === roleName)
    },
    /**
     * Passing back function to see if user assigned to a trip
     * @return {(function(*): (boolean|*))|*}
     */
    isTripMember: () => (trip) => {
      const uid = Vue.prototype.$auth.user?.id
      if (!uid) return false
      if (!trip.users_details) return false
      return !!trip.users_details.find(ud => ud.id === uid)
    }
  },
  actions: {
    /**
     * Update the user profile and refresh
     * @param commit
     * @param payload
     * @returns {Promise<void>}
     */
    updateUser ({ commit }, payload) {
      axios.patch('/api/refresh/', payload)
    }
  }
}
