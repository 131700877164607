import axios from 'axios'

export default {
  namespaced: true,
  state: {
    hedges: [],
    hedge: {},
    // Hedge Report
    reportFilters: [
      { label: 'Smelter', path: '/api/smelters/', query: 'smelter__name__in', itemValue: null, clearable: true, index: 1, type: 'autocomplete' },
      { label: 'Designation', query: 'designation__in', itemValue: null, clearable: true, index: 2, type: 'select', itemGetter: 'lotStore/lotTypes' },
      { label: 'Created From', query: 'created_at__gte', itemValue: null, index: 3, type: 'date' },
      { label: 'Created To', query: 'created_at__lte', itemValue: null, index: 4, type: 'date' }
    ],
    reportHedges: [],
    reportHedgeCount: 0,
    reportHedgePage: 1,
    hedgesPerPage: 20,
    hedgeData: {}
  },
  getters: {
    hedges: state => state.hedges,
    hedge: state => state.hedge,
    // Hedge Report
    getReportHedges: state => state.reportHedges,
    getReportHedgeCount: state => state.reportHedgeCount,
    getReportHedgePage: state => state.reportHedgePage,
    getReportFilters: state => state.reportFilters,
    getHedgesPerPage: state => state.hedgesPerPage,
    getHedgeData: state => state.hedgeData
  },
  mutations: {
    setHedges (state, hedges) {
      state.hedges = hedges
    },
    appendHedges (state, hedges) {
      state.hedges = [...state.hedges, ...hedges]
    },
    setHedge (state, hedge) {
      state.hedge = hedge
    },
    // Hedge Report
    setReportHedges (state, reportHedges) {
      state.reportHedges = reportHedges
    },
    setReportHedgeCount (state, reportHedgeCount) {
      state.reportHedgeCount = reportHedgeCount
    },
    setReportHedgePage (state, reportHedgePage) {
      state.reportHedgePage = reportHedgePage
    },
    setReportFilters (state, reportFilters) {
      state.reportFilters = reportFilters
    },
    setHedgesPerPage (state, hedgesPerPage) {
      state.hedgesPerPage = hedgesPerPage
    },
    setHedgeData (state, hedgeData) {
      state.hedgeData = hedgeData
    }
  },
  actions: {
    /**
     * Fetch all hedges in the hedge bank
     * @param {Object} commit - to call store setters
     */
    async fetchHedgeBank ({ commit }, config) {
      const params = (config && config.params) ? config.params : {}
      try {
        params.search = 'hedgebank'
        const response = await axios.get('/api/hedge_search/', { params })
        if (params && params.page && params.page > 1) commit('appendHedges', response.data.results)
        else commit('setHedges', response.data.results)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get the hedge bank', { root: true })
      }
    },
    /**
     * Get a hedge by its id
     * @param {Object} commit - to call store setters
     * @param {Number} id - the id of the hedge to get
     */
    async fetchHedge ({ commit }, id) {
      try {
        const response = await axios.get(`/api/hedges/${id}/`)
        commit('setHedge', response.data)
      } catch (err) {
        commit('setSnackbarError', `An error occurred while trying to get hedge with id: ${id}`, { root: true })
      }
    },
    /**
     * Create a new hedge
     * @param {Object} commit - to call store setters
     * @param {Object} body - the params of the hedge to create
     */
    async createHedge ({ commit }, body) {
      try {
        const res = await axios.post('/api/hedges/', body)
        return res.data.id
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to create the hedge', { root: true })
      }
    },
    /**
     * Edit an existing hedge
     * @param {Object} commit - to call store setters
     * @param {Object} updates
     */
    async editHedge ({ commit }, updates) {
      try {
        const response = await axios.patch(`/api/hedges/${updates.id}/`, updates)
        commit('setHedge', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to update the hedge', { root: true })
      }
    },
    /**
     * Gets hedges with filters for Hedge Report Page
     * @param {Object} commit - to call store setters
     * @param {Object} state - filters stored in state
     */
    async fetchReportHedges ({ commit, state, dispatch }) {
      try {
        let params = state.reportFilters.reduce((list, f) => {
          list[f.query] = f.itemValue
          return list
        }, {})
        params['page'] = state.reportHedgePage
        params['page_size'] = state.hedgesPerPage
        params['fx_currency'] = 'USD'
        let hedgeResponse = await axios.get('/api/hedges/report_view/', { params })
        dispatch('fetchHedgeData')
        commit('setReportHedges', hedgeResponse.data.results)
        commit('setReportHedgeCount', hedgeResponse.data.total)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get the hedges', { root: true })
      }
    },
    // Gets totals/averages using same filters as fetchReportHedges
    async fetchHedgeData ({ commit, state }) {
      try {
        let params = state.reportFilters.reduce((list, f) => {
          list[f.query] = f.itemValue
          return list
        }, {})
        params['fx_currency'] = 'USD'
        let res = await axios.get('/api/hedges/toz_aggregate/', { params })
        commit('setHedgeData', res.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get the hedge data', { root: true })
      }
    }
  }
}
