import axios from 'axios'
// import Vue from 'vue'
import { abortControllerInterceptor } from '@/plugins/requestAbortController'
import interceptors from '@/plugins/interceptors'
import publicRoutes from '@/router/publicRoutes'
import { tokenRefreshInterceptor } from '@/auth/interceptors'
import config from '@/../izzi_config'

export default function () {
  axios.defaults.baseURL = config.api
  axios.interceptors.request.use(tokenRefreshInterceptor) // ensure the firebase token doesn't expire
  axios.interceptors.request.use(abortControllerInterceptor)
  axios.interceptors.request.use(interceptors.startLoaderSuccess)
  axios.interceptors.response.use(interceptors.stopLoaderSuccess, interceptors.stopLoaderError)

  axios.interceptors.response.use(null, interceptors.errorInterceptor)
  axios.interceptors.response.use(null, interceptors.authInterceptor({ publicRoutes }))
  axios.interceptors.response.use(null, interceptors.notFoundRedirect)
}
