import store from '@/store'
import moment from 'moment'

// Allows a check to see if a interval of time has passed fmt YYYY/MM/DD
function timeHasPassed (createdDate, amount = 1, unit = 'M') {
  const end = moment(createdDate, 'YYYY/MM/DD').add(amount, unit)
  const timeUntil = end.diff(moment().startOf('day'))
  // There is still time until the end date
  return timeUntil < 0
}

function formatDate (date) {
  if (date && date !== '' && date !== '--') {
    const ymd = date.split('T')[0]
    const [year, month, day] = ymd.split('-')
    return `${month}/${day}/${year}`
  }
  return '--'
}

export default {
  timeHasPassed,
  formatDate
}

// returns object with text classes to use for text on a color
export function getFriendlyTextClasses (hexBaseColor) {
  const classes = {
    'white--text': false,
    'black--text': false
  }
  if (hexBaseColor) {
    const hex = hexBaseColor.replace('#', '')
    const cr = parseInt(hex.substr(0, 2), 16)
    const cg = parseInt(hex.substr(2, 2), 16)
    const cb = parseInt(hex.substr(4, 2), 16)
    const brightness = ((cr * 299) + (cg * 587) + (cb * 114)) / 1000
    if (brightness > 155) {
      classes['black--text'] = true
    } else {
      classes['white--text'] = true
    }
  }
  return classes
}

export function removeParenthesesText (text) {
  return text.replace(/ \([\s\S]*?\)/g, '')
}

export function getSafeColor (color) {
  if (color) {
    const classes = getFriendlyTextClasses(color)
    if (classes['black--text']) return 'black'
    if (classes['white--text']) return 'white'
  }
  return 'black'
}

export function expectsSettlement (designationCode) {
  const designation = store.getters.getMaterialDesignations.find(x => x.value === designationCode)
  if (designation) return designation.expects_settlement
  return false
}
