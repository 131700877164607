import axios from 'axios'

export default {
  namespaced: true,
  state: {
    market: null
  },
  getters: {
    get: state => state.market
  },
  mutations: {
    set (state, market) {
      state.market = market
    }
  },
  actions: {
    /**
     * Get the latest market rate from the database
     * @param {Object} commit - access to store setters
     */
    async fetch ({ commit }) {
      const ci = await axios.get('/api/market_rates/latest/')
      if (ci && ci.status === 200) {
        commit('set', ci.data)
      }
      return ci
    }
  }
}
