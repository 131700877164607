import { setup } from '@/auth/index'
import Vue from 'vue'
import izziConfig from '@/../izzi_config'

const auth = setup()

/**
 * Axios interceptor that gets a new id token, which triggers the auth token to be updated if expired.
 * @param config
 * @returns {Promise<*>}
 */
export async function tokenRefreshInterceptor (config) {
  const user = auth.firebaseUser
  if (!user) return config
  // need to change this to only work on default urls
  if (config.url.startsWith('/') ||
    config.url.startsWith('api/') ||
    config.url.startsWith('logistics/') ||
    config.url.includes(izziConfig.api)) {
    if (Vue.prototype.$auth.accessToken) {
      config.headers.Authorization = 'firebase ' + await user.getIdToken()
    }
  }
  return config
}
