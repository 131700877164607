import axios from 'axios'

/**
 * Safely returns values from localStorage and catches errors
 *
 * @param { String } name - name of the localStorage property
 * @param { Object } defaultValue - The default object
 */
function retrieveFromLocalStorage (name, defaultValue) {
  if (!window.localStorage.hasOwnProperty(name)) return defaultValue
  try {
    return JSON.parse(window.localStorage.getItem(name))
  } catch {
    return defaultValue
  }
}

export default {
  namespaced: true,
  state: {
    lots: [],
    hedges: [],
    smelters: [],
    allocations: [],
    expandedLot: null,
    expandedHedge: null,
    lotName: '',
    hedgeId: '',
    smelterHedgeId: '',
    manager: '',
    // User-applied view filters against hedge bank
    smelter: retrieveFromLocalStorage('filter-smelter', { name: 'All Smelters', id: null }),
    lotType: retrieveFromLocalStorage('filter-lot', { text: 'All Types', value: null }),
    yardFilter: retrieveFromLocalStorage('filter-yard', { name: 'All Yards', id: null })
  },
  getters: {
    getExpandedLot: state => state.expandedLot,
    getExpandedHedge: state => state.expandedHedge,
    getManager: state => {
      return state.manager
    },
    getLotType: state => {
      return state.lotType
    },
    getSmelter: state => {
      return state.smelter
    },
    getYardFilter: state => {
      return state.yardFilter
    },
    getLotName: state => {
      return state.lotName
    },
    getHedgeId: state => {
      return state.hedgeId
    },
    getSmelterHedgeId: state => {
      return state.smelterHedgeId
    },
    /**
     * Returns array of incomplete Hedges filtered by user-selected smelter and/or lotType
     * @param { Object } state - Hedge Bank state
     * @returns { Array } Hedges
     */
    getHedges: (state) => {
      let hedges = state.hedges
      if (state.smelter && state.smelter.id) {
        hedges = hedges.filter(h => h.smelter === state.smelter.id)
      }
      if ((state.manager)) {
        hedges = hedges.filter(h => h.manager__name === state.manager)
      }
      if ((state.lotType && state.lotType.value)) {
        hedges = hedges.filter(h => h.designation === state.lotType.value)
      }
      if ((state.yardFilter && state.yardFilter.id)) {
        hedges = hedges.filter(h => h.yard_details?.id === state.yardFilter.id)
      }
      if (state.hedgeId) {
        hedges = hedges.filter(h => h.id.toString().toUpperCase().includes(state.hedgeId.toUpperCase()))
      }
      if (state.smelterHedgeId) {
        hedges = hedges.filter(h => {
          const shi = state.smelterHedgeId.toUpperCase()
          return (h.pt_reference.toUpperCase().includes(shi) || h.pd_reference.toUpperCase().includes(shi) ||
            h.rh_reference.toUpperCase().includes(shi))
        })
      }
      return hedges
    },
    /**
     * Returns array of yard ids
     * @param state
     * @returns {*}
     */
    getFilterableYards (state) {
      let yards = {}
      state.hedges.forEach(hedge => {
        if (hedge.yard_details && !yards[hedge.yard_details.id]) {
          yards[hedge.yard_details.id] = hedge.yard_details
        }
      })
      return Object.values(yards)
    },
    /**
     * Returns array of incomplete Lots filtered by user-selected smelter and/or lotType
     * @param { Object } state - Hedge Bank state
     * @returns { Array } Hedges
     */
    getLots: (state) => {
      let lots = state.lots
      if (state.smelter && state.smelter.id) {
        lots = lots.filter(l => l.smelter === state.smelter.id)
      }
      if (state.lotType && state.lotType.value) {
        lots = lots.filter(l => l.designation === state.lotType.value)
      }
      if (state.lotName) {
        lots = lots.filter(l => l.name.toUpperCase().includes(state.lotName.toUpperCase()))
      }
      return lots
    },
    getAllocations: state => {
      return state.allocations
    },
    getSmelters: state => state.smelters,
    getLotAllocations: state => {
      return function (lot) {
        return state.allocations.filter(a => a.lot === lot.id)
      }
    },
    getHedgeAllocations: (state) => {
      return function (hedge) {
        return state.allocations.filter(a => a.hedge === hedge.id)
      }
    }
  },
  mutations: {
    setExpandedLot (state, id) {
      state.expandedLot = id
    },
    setExpandedHedge (state, id) {
      state.expandedHedge = id
    },
    setSmelters (state, smelters) {
      state.smelters = smelters
    },
    setLots (state, lots) {
      state.lots = lots
    },
    setHedges (state, hedges) {
      state.hedges = hedges
    },
    setAllocations (state, allocations) {
      state.allocations = allocations
    },
    selectLotType (state, lotType) {
      window.localStorage.setItem('filter-lot', JSON.stringify(lotType))
      state.lotType = lotType
    },
    selectSmelter (state, smelter) {
      window.localStorage.setItem('filter-smelter', JSON.stringify(smelter))
      state.smelter = smelter
    },
    selectYardFilter (state, yardFilter) {
      window.localStorage.setItem('filter-yard', JSON.stringify(yardFilter))
      state.yardFilter = yardFilter
    },
    setManager (state, name) {
      state.manager = name
    },
    setLotName (state, name) {
      state.lotName = name
    },
    setHedgeId (state, id) {
      state.hedgeId = id
    },
    setSmelterHedgeId (state, id) {
      state.smelterHedgeId = id
    },
    setAllocation (state, allocation) {
      const i = state.allocations.findIndex(a => a.id === allocation.id)
      if (i === -1) {
        state.allocations.push(allocation)
      } else {
        state.allocations[i] = allocation
        state.allocations = [...state.allocations]
      }
    },
    removeAllocation (state, allocationId) {
      state.allocations = [...state.allocations.filter(h => h.id !== allocationId)]
    },
    setHedge (state, hedge) {
      const i = state.hedges.findIndex(a => a.id === hedge.id)
      if (i === -1) {
        state.hedges.push(hedge)
      } else {
        state.hedges[i] = hedge
        state.hedges = [...state.hedges]
      }
    },
    setLot (state, lot) {
      if (state.lots.find(x => (x.id !== lot.id))) {
        state.lots = state.lots.map(x => (x.id !== lot.id) ? x : lot)
      } else {
        state.lots = [...state.lots.filter(x => (x.id !== lot.id)), lot]
      }
    }
  },
  actions: {
    async fetchManageable ({ commit }) {
      const r = await axios.get('/api/hedge_bank/', { params: { fx_currency: 'USD' } })
      if (r.data) {
        commit('setSmelters', r.data.smelters)
        commit('setLots', r.data.lots)
        commit('setHedges', r.data.hedges)
        commit('setAllocations', r.data.allocations)
      }
    },
    async updateHedge ({ commit }, { hedge, payload }) {
      commit('setHedge', Object.assign(hedge, payload))
      return axios.patch(`/api/hedges/${hedge.id}/`, payload, { params: { fx_currency: 'USD' } })
    },
    async updateLot ({ commit }, { lot, payload }) {
      return axios.patch(`/api/lots/${lot.id}/`, payload, { params: { fx_currency: 'USD' } }).then(r => {
        const l = r.data
        commit('setLot', Object.assign(lot, {
          smelter: l.smelter && l.smelter.id
        }))
      })
    },
    // Re fetches a specific hedge
    async reFetchHedge ({ commit }, { id }) {
      const response = await axios.get(`/api/hedges/${id}/bank`, { params: { fx_currency: 'USD' } })
      if (response.data) commit('setHedge', response.data)
    },
    async updateAllocation ({ commit, dispatch }, { allocation, payload }) {
      const response = await axios.patch(`/api/allocations/${allocation.id}/`, payload, { params: { fx_currency: 'USD' } })
      const a = response.data
      commit('setAllocation', {
        id: a.id,
        hedge_code: a.hedge.code,
        lot: a.lot.id,
        lot_name: a.lot.name,
        hedge: a.hedge.id,
        pt: a.pt,
        pt_rate: a.hedge.pt_rate_per_toz_fx,
        pd: a.pd,
        pd_rate: a.hedge.pd_rate_per_toz_fx,
        rh: a.rh,
        rh_rate: a.hedge.rh_rate_per_toz_fx,
        status: a.status
      })
      await dispatch('reFetchHedge', { id: a.hedge.id })
      return response
    },
    async postAllocation ({ commit, dispatch }, { lotId, hedgeId, pt, pd, rh, status }) {
      const payload = { lot_id: lotId, hedge_id: hedgeId, pt, pd, rh, status }
      const response = await axios.post(`/api/allocations/`, payload, { params: { fx_currency: 'USD' } })
      const a = response.data
      commit('setAllocation', {
        id: a.id,
        hedge_code: a.hedge.code,
        lot: a.lot.id,
        lot_name: a.lot.name,
        hedge: a.hedge.id,
        pt: a.pt,
        pt_rate: a.hedge.pt_rate_per_toz_fx,
        pd: a.pd,
        pd_rate: a.hedge.pd_rate_per_toz_fx,
        rh: a.rh,
        rh_rate: a.hedge.rh_rate_per_toz_fx,
        status: a.status
      })
      await dispatch('reFetchHedge', { id: a.hedge.id })
      return response
    },
    async deleteAllocation ({ commit, dispatch }, allocation) {
      const response = await axios.delete(`/api/allocations/${allocation.id}/`).then(r => {
        commit('removeAllocation', allocation.id)
      })
      await dispatch('reFetchHedge', { id: allocation.hedge })
      return response
    }
  }
}
