<template>
  <div>
    <ValidationProvider
      :rules="validationRules"
      v-slot="{ errors }"
    >
      <div class="d-flex justify-space-between align-center">
        <v-text-field
          v-bind="[$attrs, $props]"
          v-on="$listeners"
          v-model="amountInternal"
          class="mr-2"
          type="number"
          :error-messages="errors"
          :append-icon="showDateSelect ? 'mdi-calendar' : ''"
          @input="dateSelectToggle = true"
          @click:append="dateSelectToggle = !dateSelectToggle"
        />
        <v-btn
          small
          @click="updateCurrency"
        >
          {{ currency }} <v-icon
            class="pl-1"
            small
          >
            mdi-swap-horizontal
          </v-icon>
        </v-btn>
      </div>
    </ValidationProvider>
    <v-expand-transition v-if="showDateSelect">
      <div
        v-show="dateSelectToggle"
        class="ml-4"
      >
        <rules-date
          label="FX Date"
          v-model="dateInternal"
          :max="today"
        />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import RulesDate from '@/components/ValidationFields/RulesDate.vue'

export default {
  name: 'RulesCurrency',
  components: { RulesDate },
  props: {
    amount: {
      type: [String, Number],
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    showDateSelect: {
      type: Boolean,
      default: false
    },
    validationRules: {
      type: String,
      default: 'required|two_decimal'
    }
  },
  data () {
    return {
      dateSelectToggle: false,
      today: new Date().toISOString().slice(0, 10)
    }
  },
  computed: {
    amountInternal: {
      get () {
        return this.amount
      },
      set (value) {
        this.$emit('updateAmount', value)
        this.$emit('changed')
      }
    },
    dateInternal: {
      get () {
        return this.date
      },
      set (value) {
        this.$emit('updateDate', value)
        this.$emit('changed')
      }
    }
  },
  methods: {
    updateCurrency () {
      for (const currency of this.$store.getters.getCurrencies) {
        if (!['NA', this.currency].includes(currency.value)) {
          this.$emit('updateCurrency', currency.value)
          this.$emit('changed')
          return
        }
      }
    }
  }
}
</script>
