<template>
  <v-menu
    v-model="dialog"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <ValidationProvider
        :rules="validationRules"
        v-slot="{ errors }"
      >
        <v-text-field
          v-bind="[$attrs, $props]"
          :value="value"
          type="date"
          readonly
          v-on="on"
          append-icon="mdi-calendar-month"
          :error-messages="errors"
          @change="$emit('input', $event)"
        />
      </ValidationProvider>
    </template>
    <v-date-picker
      v-if="showDatePicker"
      :value="value"
      no-title
      scrollable
      :max="max"
      :min="min"
      @input="dialog = false"
      @change="$emit('input', $event)"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'RulesDate',
  props: {
    value: {
      type: null,
      required: true
    },
    validationRules: {
      type: String,
      default: 'required'
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    showDatePicker: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    dialog: false
  })
}
</script>
