export default [
  {
    path: '/lot-settlement-list/',
    name: 'lot-settlement-list',
    component: () => import(/* webpackChunkName: "finance-routes" */ '@/views/Finance/LotSettlementList'),
    reverse: 'lot-settlement-list'
  },
  {
    path: '/advances/',
    name: 'advance-list',
    component: () => import(/* webpackChunkName: "finance-routes" */ '@/views/Advances/AdvanceList.vue')
  },
  {
    path: '/advances/create/',
    name: 'advance-create',
    component: () => import(/* webpackChunkName: "finance-routes" */ '@/views/Advances/AdvanceCreate.vue')
  },
  {
    path: '/advances/:id/',
    name: 'advance-view',
    component: () => import(/* webpackChunkName: "finance-routes" */ '@/views/Advances/AdvanceView.vue')
  }
]
