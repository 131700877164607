import axios from 'axios'

export function createInventoryBox (params) {
  return axios.post(`/api/inventory_boxes/`, params)
}

export function fetchInventoryBoxes (options, onHand = true, complete = false) {
  if (!options.params) {
    options.params = {}
  }

  if (complete) {
    options.params.page_size = 0
  }

  options.params.is_on_hand = onHand
  return axios.get('/api/inventory_boxes/', options)
}

export function fetchInventoryBox (id) {
  return axios.get(`/api/inventory_boxes/${id}/`)
}

export function patchInventoryBox (id, payload) {
  return axios.patch(`/api/inventory_boxes/${id}/`, payload)
}

export function splitInventoryBox (id, sourceWeight, sourcePieces, newWeight, newPieces) {
  const payload = {
    source_weight: sourceWeight,
    source_pieces: sourcePieces,
    new_weight: newWeight,
    new_pieces: newPieces
  }
  return axios.post(`/api/inventory_boxes/${id}/split/`, payload)
}

export function mergeInventoryBox (id, boxIds) {
  return axios.post(`/api/inventory_boxes/${id}/merge/`, {
    box_ids: boxIds
  })
}

// export function fetchInventoryLocation (id) {
//   return axios.get(`/logistics/locations/${id}/`)
// }

export function fetchInventoryLocations () {
  return axios.get('/logistics/locations/?is_inventory_location=True&page_size=0')
}

export function disableInventoryLocation (id) {
  return axios.patch(`/logistics/locations/${id}/disable_location/`)
}

export function fetchInventoryBoxHistory (id) {
  return axios.get(`/api/inventory_boxes/${id}/history/`)
}

export function deleteInventoryBox (id) {
  return axios.patch(`/api/inventory_boxes/${id}/`, { status: 'DE' })
}

export function fetchCatalogOptions () {
  return axios.get('/api/catalogs/intake/')
}
