import axios from 'axios'

export default {
  // Module dedicated to transit requests explicitly
  namespaced: true,
  state: () => ({
    requests: {},
    requestToEdit: null
  }),
  getters: {
    getRequestToEdit (state) {
      return state.requestToEdit
    },
    /**
     * Gets current location from all requests and return as an array
     * @param state
     * @return {unknown[]}
     */
    getOriginOptions (state) {
      const m = new Map()
      for (const k in state.requests) {
        const req = state.requests[k]
        if (req.current_location) m.set(req.current_location.url, req.current_location)
      }
      return Array.from(m.values())
    },
    getRequested (state, getters, rootState) {
      const s = rootState.transitDashboardStore.filter.toLowerCase()
      return Object.values(state.requests)
        .filter(x => x.name.toLowerCase().includes(s))
        .sort((a, b) => a.id - b.id)
    }
  },
  mutations: {
    setRequestToEdit (state, request) {
      state.requestToEdit = request
    },
    removeRequestTransit (state, request) {
      const obj = Object.assign({}, state.requests)
      delete obj[request.url]
      state.requests = obj
    },
    /**
     * Adds or updates an existing request entry
     * @param state
     * @param request
     */
    addTransitRequest (state, request) {
      const obj = Object.assign({}, state.requests)
      obj[request.url] = request
      state.requests = obj
    },
    /**
     * Performs bulk operation for add transit request
     * @param state
     * @param requests
     */
    setTransitRequests (state, requests) {
      state.requests = requests.reduce((obj, request) => {
        obj[request.url] = request
        return obj
      }, {})
    }
  },
  actions: {
    async createCheckInRequest ({ commit, dispatch }, { checkInUrl, toLocationUrl }) {
      const r = await axios.patch(checkInUrl, {
        requires_transit: true,
        to_location: toLocationUrl
      })
      await dispatch('fetchTransitRequests')
      return r
    },
    fetchTransitRequests ({ commit }) {
      return axios.get('/logistics/transits/requests/').then(response => {
        if (response.status !== 200) return
        const toLocations = response.data.map(r => r.to_location)
        commit('setTransitRequests', response.data)
        commit('transitDashboardStore/addDestinationOptions', toLocations, { root: true })
      })
    },
    // Updates the toLocation for request
    patchTransitRequest ({ commit }, request) {
      let url
      const payload = { to_location: request.toLocation.url }
      if (request.type === 'CHECKIN') {
        url = `/api/check_ins/${request.id}/`
      } else if (request.type === 'MANIFEST') {
        url = `/api/inventory_manifests/${request.id}/`
      } else if (request.type === 'LOT_MANIFEST') {
        url = `/api/lot_manifests/${request.id}/`
      }
      if (!url) return
      axios.patch(url, payload).then(response => {
        // how are we going to get the corrected formatting... you don't
        commit('addTransitRequest', request)
      })
    },
    /**
     * Sets requires_transit (checkin) or transit_requested (inventorybox) to false
     * commit - remove request from list
     * @param request
     */
    removeRequest ({ commit }, request) {
      let url
      let payload
      if (request.type === 'CHECKIN') {
        url = `/api/check_ins/${request.id}/`
        payload = { 'requires_transit': false }
      } else if (request.type === 'MANIFEST') {
        url = `/api/inventory_manifests/${request.id}/`
        payload = { 'transit_requested': false }
      } else if (request.type === 'LOT_MANIFEST') {
        url = `/api/lot_manifests/${request.id}/`
        payload = { 'transit_requested': false }
      }
      if (!url) return
      axios.patch(url, payload).then(response => {
        commit('removeRequestTransit', request)
      })
    }
  }
}
