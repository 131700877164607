<template>
  <v-toolbar-items>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
        >
          <v-icon
            class="pr-1"
            small
            :color="fxColor"
          >
            mdi-circle
          </v-icon>
          {{ fxCurrency }}
          <v-icon small>
            mdi-swap-vertical
          </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="value in currencies.filter(x => !['NA', fxCurrency].includes(x.value))"
          :key="value.value"
          dense
          @click="changeCurrency(value.value)"
        >
          <v-list-item-title class="text-center">
            {{ value.value }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      icon
      @click="documentSearchDialog = !documentSearchDialog"
    >
      <v-icon>
        mdi-text-box-search-outline
      </v-icon>
    </v-btn>
    <v-menu
      v-if="$store.getters.currentUser"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          @click="$vuetify.theme.dark = !$vuetify.theme.dark"
        >
          <v-list-item-icon>
            <v-icon>
              mdi-brightness-4
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $vuetify.theme.dark ? 'Light Mode' : 'Dark Mode' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'user-profile' }"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $store.getters.currentUser.username }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <document-search
      v-model="documentSearchDialog"
    />
  </v-toolbar-items>
</template>

<script>
import DocumentSearch from '@/base/components/DocumentSearch'
export default {
  name: 'AuthenticatedToolbarItems',
  components: { DocumentSearch },
  computed: {
    fxCurrency () {
      return this.$auth.user?.fx_currency
    },
    fxColor () {
      switch (this.fxCurrency) {
        case 'USD':
          return '#0A3161'
        case 'CAD':
          return '#D80621'
        default:
          return '#b1a5ed'
      }
    },
    currencies () {
      return this.$store.getters.getCurrencies
    },
    documentSearchDialog: {
      get () {
        return this.$store.getters['getDocumentSearchDialog']
      },
      set (v) {
        this.$store.commit('setDocumentSearchDialog', v)
      }
    }
  },
  watch: {
    '$vuetify.theme.dark' (setting) {
      this.$store.dispatch('updateUser', { dark_theme: setting })
    }
  },
  methods: {
    /**
     *  Change the users currency and reload page
     */
    async changeCurrency (value) {
      await this.$store.dispatch('updateUser', {
        'fx_currency': value
      })
      window.location.reload()
    },
    logout () {
      if (confirm('Are you sure you would like to sign out?')) {
        this.$auth.logout()
      }
    }
  },
  mounted () {
    this.showNavigation = this.$vuetify.breakpoint.mdAndUp
  }
}
</script>
