export default [
  {
    path: '/transit/create',
    name: 'transit-create',
    component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Logistics/Transit/CreateTransitView.vue'),
    meta: { permission: 'view-transit' }
  },
  {
    path: '/transit/manifest/create',
    name: 'manifest-create',
    component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Manifest/ManifestCreateView'),
    meta: { permission: 'create-manifests' }
  },
  {
    path: '/transit',
    component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Logistics/Transit/TransitParent'),
    children: [
      {
        path: 'dashboard',
        name: 'transit-dashboard',
        component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Logistics/TransitDashboard'),
        meta: { permission: 'view-transit' }
      },
      {
        path: 'merge',
        name: 'transit-merge',
        component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/MergeTransit/MergeTransitView.vue'),
        meta: { permission: 'view-transit' }
      },
      {
        path: 'outbound-list',
        name: 'outbound-list',
        component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Logistics/Outbound/OutboundList.vue'),
        meta: { permission: 'view-transit' }
      },
      {
        path: 'transit-list',
        name: 'transit-list',
        component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Logistics/TransitList/InboundList.vue'),
        meta: { permission: 'view-transit' }
      },
      {
        path: 'manifest-list/',
        name: 'manifest-list',
        component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Manifest/ManifestList'),
        meta: { permission: 'view-manifests' }
      },
      {
        path: 'lot-manifest-list/',
        name: 'lot-manifest-list',
        component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Manifest/LotManifestList.vue'),
        meta: { permission: 'view-lot-manifests' }
      }
    ]
  },
  {
    path: '/carrier-list',
    name: 'carrier-list',
    component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Carriers/CarrierList'),
    meta: { permission: 'view-carrier' }
  },
  {
    path: '/broker-list',
    name: 'broker-list',
    component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Brokers/BrokerList'),
    meta: { permission: 'view-broker' }
  },
  {
    path: '/transit/manifest/:id',
    name: 'manifest-view',
    component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Manifest/ManifestView'),
    reverse: 'inventory_manifest-single'
  },
  {
    path: '/transit/manifest/:id/print',
    name: 'manifest-print',
    component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Manifest/ManifestPrint')
  },
  {
    path: '/transit/transit-list/:id',
    name: 'transit-view',
    component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Logistics/Transit/TransitView'),
    meta: { permission: 'view-transit' },
    reverse: 'transits-single'
  },
  {
    path: '/carrier/carrier-list/:id',
    name: 'carrier-view',
    component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Carriers/CarrierView'),
    meta: { permission: 'view-carrier' },
    reverse: 'carriers-single'
  },
  {
    path: '/transit/broker-list/:id',
    name: 'broker-view',
    component: () => import(/* webpackChunkName: "logistics-routes" */ '@/views/Brokers/BrokerView'),
    meta: { permission: 'view-broker' },
    reverse: 'brokers-single'
  }
]
