<template>
  <p :class="autoCenter ? 'text-center pa-3' : otherStyleValue">
    <v-btn
      @click="$emit('clicked')"
      :block="block"
      :text="text"
      :disabled="disabled"
      :loading="loading"
      :small="small"
      :x-small="xSmall"
      :x-large="xLarge"
      :bottom="bottom"
      :right="right"
      :absolute="absolute"
      :fab="fab"
      :dark="dark"
      :fixed="fixed"
      :top="top"
      :color="color"
      :type="type"
      :icon="iconBool"
      :target="target"
      :class="styles"
      :to="to"
    >
      {{ label }}
      <slot />
      <v-icon>
        {{ icon }}
      </v-icon>
    </v-btn>
  </p>
</template>

<script>
/**
 * autoCenter prop is used to apply the text center and margins; text button
 * iconBool prop is used for icon buttons
 * IF you want a 'fab' button, do not use iconbool or autocenter as they will
 * doesn't apply fab background if used
 * Type is generally used for buttons within forms, ex. type="submit"
 * Shouldn't be used in conjunction with @click
 */
export default {
  name: 'BaseButton',
  props: {
    block: { type: Boolean, required: false, default: false },
    text: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },
    small: { type: Boolean, required: false, default: false },
    xSmall: { type: Boolean, required: false, default: false },
    xLarge: { type: Boolean, required: false, default: false },
    bottom: { type: Boolean, required: false, default: false },
    right: { type: Boolean, required: false, default: false },
    absolute: { type: Boolean, required: false, default: false },
    fab: { type: Boolean, required: false, default: false },
    dark: { type: Boolean, required: false, default: false },
    iconBool: { type: Boolean, required: false, default: false },
    autoCenter: { type: Boolean, rquired: false, default: false },
    fixed: { type: Boolean, required: false, default: false },
    top: { type: Boolean, required: false, default: false },
    color: { type: String, required: false, default: 'primary' },
    label: { type: String, required: false, default: null },
    type: { type: String, required: false, default: null },
    icon: { type: String, required: false, default: null },
    target: { type: String, required: false, default: null },
    styles: { type: String, required: false, default: null },
    otherStyleValue: { type: String, required: false, default: null },
    to: { type: Object, required: false, default: null }
  }
}
</script>
