<template>
  <v-btn
    v-bind="[$attrs, $props]"
    @click="authenticate"
  >
    <slot>
      Login
    </slot>
  </v-btn>
</template>

<script>
import { signInWithPopup } from 'firebase/auth'
import { auth, provider } from '../../firebase_provider'

export default {
  name: 'MsftAuthButton',
  methods: {
    errorHandler (err) {
      this.$store.commit('setSnackbarError', err)
    },
    authenticate () {
      signInWithPopup(auth, provider)
        .catch(error => {
          this.errorHandler(error)
        })
    }
  }
}
</script>
