<template>
  <ValidationProvider
    :rules="validationRules"
    v-slot="{ errors }"
    :immediate="immediate"
  >
    <v-text-field
      v-bind="[$attrs, $props]"
      v-on="$listeners"
      :error-messages="errors"
    />
  </ValidationProvider>
</template>

<script>
export default {
  name: 'RulesText',
  props: {
    value: {
      type: null,
      required: true
    },
    validationRules: {
      type: String,
      default: 'required'
    },
    immediate: {
      type: Boolean,
      default: false
    }
  }
}
</script>
