<template>
  <tr v-if="renderDefaultView || $vuetify.breakpoint.smAndUp">
    <td> {{ sectionTitle }} </td>
    <td
      v-for="(title, i) in titles"
      :key="i"
    >
      <slot :name="`col_${i + 1}`" />
    </td>
  </tr>
  <tr v-else-if="renderPhoneView">
    <h4 class="my-2 text-center">
      {{ sectionTitle }}
    </h4>
    <div
      class="d-flex my-4"
      v-for="(title, i) in filteredTitles"
      :key="i"
    >
      <h4 class="ml-4">
        {{ title.name }}
      </h4>
      <v-spacer />
      <div class="mr-4">
        <slot :name="`col_${title.index + 1}`" />
      </div>
    </div>
  </tr>
</template>

<script>
export default {
  name: 'DatatableDynamicRow',
  props: {
    renderPhoneView: { type: Boolean, required: false, default: true },
    renderDefaultView: { type: Boolean, required: false, default: null },
    titles: { type: Array, required: true },
    sectionTitle: { type: String, required: true }
  },
  computed: {
    filteredTitles () {
      const refactoredTitles = this.titles.map((title, i) => { return { name: title, index: i } })
      return refactoredTitles.filter(title => title.name.trim() !== '')
    }
  }
}
</script>
