export default [
  {
    path: '/purchase/:id',
    name: 'purchase',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/Purchases/Purchase'),
    reverse: 'purchases-single'
  },
  {
    path: '/purchase-list',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/Purchases/PurchaseParent'),
    children: [
      {
        path: '',
        name: 'purchase-list',
        component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/Purchases/PurchaseList'),
        meta: { permission: 'view-purchase' }
      },
      {
        path: '/create-purchase',
        name: 'create-purchase',
        component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/Purchases/CreatePurchase'),
        meta: { permission: 'create-purchases' }
      },
      {
        path: '/price-quote',
        name: 'price-quote',
        component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/PriceQuote/PriceQuote'),
        meta: { permission: 'view-purchase' }
      }
    ]
  },

  {
    path: '/checkin-list/',
    name: 'checkin-list',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/CheckIns/CheckInList'),
    meta: { permission: 'view-purchase' }
  },
  {
    path: '/checkin/:id/edit',
    name: 'edit-checkin',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/CheckIns/Edit/EditCheckIn'),
    props: true
  },
  {
    path: '/checkin/:id/wrap-up',
    name: 'complete-checkin',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/CheckIns/Complete/CompleteCheckIn'),
    props: true
  },
  {
    path: '/checkin/:id/payment',
    name: 'payment-checkin',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/CheckIns/Payment/CheckInPayment'),
    props: true
  },
  {
    path: '/checkin/:id/modify',
    name: 'check-in-modify',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/CheckIns/CheckInModify')
  },
  {
    path: '/checkin/:id/split',
    name: 'check-in-split',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/CheckIns/CheckInSplit.vue'),
    meta: { permission: 'create-check-ins' }
  },
  {
    path: '/checkin/:id',
    name: 'check-in',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/CheckIns/CheckIn'),
    reverse: 'check_ins-single'
  },
  {
    path: '/reports/check-in/dump',
    name: 'check-in-dump-report',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/Reports/CheckInDumpReport'),
    meta: { permission: 'view-check-in-dump-report' }
  },
  {
    path: '/reports/cat-check-in',
    name: 'cat-check-in-report',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/Reports/CatCheckInReport'),
    meta: { permission: 'view-cat-check-in-report' }
  },
  {
    path: '/intake/create-using-boxes',
    name: 'create-intake-using-boxes',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/Intakes/CreateIntakeUsingBoxes'),
    meta: { permission: 'create-intakes' }
  },
  {
    path: '/intake/create',
    name: 'create-intake',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/Intakes/CreateIntake'),
    meta: { permission: 'create-intakes' }
  },
  {
    path: '/intake/:id',
    name: 'intake',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/Intakes/Intake'),
    reverse: 'intakes-single'
  },
  {
    path: '/intake',
    name: 'intake-list',
    component: () => import(/* webpackChunkName: "purchase-routes" */ '@/views/Intakes/IntakeList'),
    meta: { permission: 'view-intakes' }
  },
  {
    path: '/identify-token/:id',
    name: 'identify-token',
    component: () => import(/* webpackChunkName: "identify-token" */ '@/views/IdentifierToken/IdentifierTokenView.vue')
  }
]
