<template>
  <v-btn
    :loading="isLocked"
    :rounded="rounded"
    v-bind="[$attrs, $props]"
    v-on="$listeners"
    :fab="!$vuetify.breakpoint.smAndUp && rounded"
    @click="clickHandle"
  >
    <span
      v-if="$vuetify.breakpoint.smAndUp"
      class="mr-2"
    >
      <slot />
    </span>
    <v-icon
      v-if="iconText"
      small
    >
      {{ iconText }}
    </v-icon>
  </v-btn>
</template>

<script>

export default {
  name: 'TextFab',
  props: {
    iconText: {
      type: String,
      required: false,
      default: null
    },
    rounded: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    timeoutButton: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    lockTimeout: false
  }),
  computed: {
    isLocked () {
      return !!(this.loading || this.lockTimeout)
    }
  },
  methods: {
    // Normal event will still fire even while timed out
    clickHandle () {
      if (self.timeoutButton) {
        this.lockTimeout = true
        setTimeout(() => {
          this.lockTimeout = false
        }, 3000)
      }
    }
  }
}
</script>
