import Vue from 'vue'
import store from '@/store'

const appendValueToStr = (str, val) => isNaN(str) ? str : `${str}${val}`

Vue.filter('formatDate', (date) => {
  if (date && date !== '' && date !== '--') {
    const ymd = date.split('T')[0]
    const [year, month, day] = ymd.split('-')
    return `${month}/${day}/${year}`
  }
  return '--'
})

Vue.filter('formatTimeDate', (date) => {
  if (date && date !== '' && date !== '--') {
    const formattedDate = new Date(date)
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }

    return formattedDate.toLocaleDateString(undefined, options)
  }
  return '--'
})

Vue.filter('appendFull', (str) => {
  return appendValueToStr(str, ' full')
})

Vue.filter('appendToz', (str) => {
  return appendValueToStr(str, ' toz')
})

Vue.filter('appendPerToz', (str) => {
  return appendValueToStr(str, '\\toz')
})

Vue.filter('appendPpm', (str) => {
  return appendValueToStr(str, ' ppm')
})

Vue.filter('appendCtoz', (str) => {
  return appendValueToStr(str, ' ctoz')
})

Vue.filter('appendRtoz', (str) => {
  return appendValueToStr(str, ' rtoz')
})

Vue.filter('appendPcs', (str) => {
  return appendValueToStr(str, ' pcs')
})

Vue.filter('appendLbs', (str) => {
  return appendValueToStr(str, ' lbs')
})

Vue.filter('appendPerLb', (str) => {
  return appendValueToStr(str, '/lb')
})

Vue.filter('appendPercent', (str) => {
  return appendValueToStr(str, '%')
})

Vue.filter('appendPcsPerCtoz', (str) => {
  return appendValueToStr(str, ' pcs/ctoz')
})

/**
 * Return Yes or No instead of True or False for readability
 * @param {Boolean} bool
 */
Vue.filter('trueFalse', (bool) => {
  return bool ? 'Yes' : 'No'
})

Vue.filter('dollarSign', (str) => {
  if (str && str !== '' && str !== '--' && str !== 'null') {
    return `$${str}`
  }
  return str
})

Vue.filter('trimDecimals', (decimal) => {
  if (isNaN(decimal)) return decimal
  return Number(decimal).toString()
})

Vue.filter('filterNA', (obj) => {
  if (obj === '0' || obj === 0) return '0'
  if (!obj) return '--'
  if (typeof obj === 'string' && obj.trim() === '') return '--'
  if (typeof obj === 'string' && obj.split(' ')[0] === 'NaN') return '--'
  return obj
})

Vue.filter('filterObject', (obj, property) => {
  if (obj && typeof obj === 'object' && obj.hasOwnProperty(property)) {
    return obj[property]
  }
  return '--'
})

Vue.filter('objectValue', (obj, property) => {
  if (obj && typeof obj === 'object') {
    return obj.value
  }
  return '--'
})

Vue.filter('toUpperCase', (str) => {
  if (str && typeof str === 'string') {
    return str.toUpperCase()
  }
  return '--'
})

Vue.filter('capitalize', (str) => {
  if (typeof str !== 'string' || str.length < 1) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
})

Vue.filter('spaceOnCapital', (str) => {
  if (str && typeof str === 'string') {
    return str.replace(/([A-Z])/g, ' $1').trim()
  }
  return '--'
})

Vue.filter('filterLoadType', (loadType) => {
  if (loadType === 'FTL') return 'Full Truck Load'
  return 'Less Than Truck Load'
})

Vue.filter('addCommas', (number) => {
  if (isNaN(number)) return number
  if (typeof number !== 'string') number = number + ''
  const [after, before] = number.split('.')
  const wComma = after.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  return before ? `${wComma}.${before}` : wComma
})

Vue.filter('negativeWrap', (number) => {
  if (isNaN(number) || number >= 0) return number
  return `(${Math.abs(number)})`
})

Vue.filter('decimalToPercent', (dec, toFixed = 0) => {
  if (typeof dec === 'string') dec = parseFloat(dec)
  return (dec * 100).toFixed(toFixed) + '%'
})

Vue.filter('converterType', (str) => {
  if (str === 'AM') return 'After Market'
  if (str === 'IF') return 'Import Foil'
  if (str === 'DF') return 'Domestic Foil'
  if (str === 'OM') return 'OEM'
  if (str === 'DP') return 'DPF'
  if (str === 'DS') return 'Diesel'
  if (str === 'GN') return 'Generic'
  if (str === 'BD') return 'Bead'
  return str
})

Vue.filter('purchaseStatus', (str) => {
  if (str === 'OP') return 'Created'
  if (str === 'RE') return 'Received'
  if (str === 'IN') return 'Intook'
  if (str === 'SO') return 'Sorted'
  if (str === 'CU') return 'Cut'
  return str
})

Vue.filter('purchasingMethod', (str) => {
  if (str === 'NO') return 'No'
  if (str === 'GR') return 'Yes, by Grade'
  if (str === 'AS') return 'Yes, by Assay'
  return str
})

Vue.filter('sellingMethods', (str) => {
  if (str === 'PC') return 'By Piece'
  if (str === 'AS') return 'By Assay'
  if (str === 'AV') return 'Average'
  if (str === 'ST') return 'Straight Through'
  return str
})

Vue.filter('averageCarTypes', (str) => {
  if (str === 'TR') return 'Mostly Trucks'
  if (str === 'DO') return 'Mostly Domestic'
  if (str === 'FR') return 'Mostly Foreign'
  return str
})

Vue.filter('monthQuarters', (str) => {
  if (str === '1') return 'Week One'
  if (str === '2') return 'Week Two'
  if (str === '3') return 'Week Three'
  if (str === '4') return 'Week Four'
  return str
})

Vue.filter('lotType', (str) => {
  if (str === 'AM') return 'After Market'
  if (str === 'IF') return 'Import Foil'
  if (str === 'DF') return 'Domestic Foil'
  if (str === 'OM') return 'OEM'
  if (str === 'DP') return 'DPF'
  if (str === 'DS') return 'Diesel'
  if (str === 'GN') return 'Generic'
  if (str === 'BD') return 'Bead'
  if (str === 'OA') return 'OEM/After Market'
  if (str === 'LC') return 'Loose Catalyst'
  if (str === 'HD') return 'High Grade Dust'
  if (str === 'O2') return 'O2 Sensor'
  return str
})

Vue.filter('converterFill', (str) => {
  if (str === 'QR') return 'Quarter'
  if (str === 'HL') return 'Half'
  if (str === 'TQ') return 'Three Quarter'
  if (str === 'FL') return 'Full'
  return str
})

Vue.filter('yardStatus', (str) => {
  if (str === 'OB') return 'Out of Business'
  if (str === 'GS') return 'Good Standing'
  if (str === 'BL') return 'Black Listed'
  if (str === 'IN') return 'Inactive'
  return str
})

Vue.filter('yardDays', (str) => {
  if (str === 'MO') return 'Monday'
  if (str === 'TU') return 'Tuesday'
  if (str === 'WE') return 'Wednesday'
  if (str === 'TH') return 'Thursday'
  if (str === 'FR') return 'Friday'
  if (str === 'SA') return 'Saturday'
  if (str === 'SU') return 'Sunday'
  if (str === 'WW') return 'Mon-Fri'
  return str
})

Vue.filter('yardCategory', function (str) {
  const found = store.getters['converterTypes'].find(t => t.value === str)
  return (found) ? found.text : str
})

Vue.filter('toFixed', (str, int = 2) => {
  const val = parseFloat(str)
  return isNaN(val) ? str : val.toFixed(int)
})

Vue.filter('limitUrl', str => {
  const arr = str.split('//')
  if (arr.length > 1) {
    return arr[1].split('/')[0]
  }
  return str
})

Vue.filter('filterZero', num => {
  return num || '0'
})

Vue.filter('countsToString', obj => {
  if (!obj) return ''
  let s = []
  for (const k of Object.keys(obj)) {
    if (obj[k] > 0) s.push(`${k} ${obj[k]}`)
  }
  return s.join(', ')
})

Vue.filter('formatNumber', formatNumber)
export function formatNumber (number) {
  if (number) {
    return new Intl.NumberFormat().format(number)
  } else return 0
}

const CURRENCY_USD = new Intl.NumberFormat('en-US',
  { style: 'currency', currency: 'USD' }
)
Vue.filter('currencyUSD', formatCurrency)
export function formatCurrency (number) {
  if (number) return CURRENCY_USD.format(number)
  return CURRENCY_USD.format(0)
}

export function formatCurrencyV (number, currency = 'USD') {
  if (currency === 'NA') currency = 'USD'
  const currencyIntl = new Intl.NumberFormat('en-US',
    { style: 'currency', currency: currency }
  )
  if (number) return currencyIntl.format(number)
  return currencyIntl.format(0)
}

Vue.filter('formatCurrencyV', formatCurrencyV)

export function currencyParentheses (value) {
  if (typeof value === 'string' && value[0] === '-') {
    return '(' + value.slice(1) + ')'
  }
  return value
}

Vue.filter('currencyParentheses', currencyParentheses)
