<template>
  <v-dialog
    :value="value"
    content-class="my-custom-dialog"
    transition="slide-y-transition"
    scrollable
    max-width="800"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-toolbar
        flat
      >
        <v-toolbar-items>
          <v-text-field
            class="pt-4"
            v-model="search"
            :loading="loading"
            label="Document Search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            single-line
            autofocus
            clearable
          />
        </v-toolbar-items>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            @click="$emit('input', false)"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div style="height: 75vh">
          <div class="text-center">
            <v-chip
              v-for="(count, key) in documentDataTypes"
              :key="key"
              class="ma-1 font-weight-bold"
              small
              :color="nameFilter === key ? 'primary' : ''"
              @click="setNameFilter(key)"
            >
              {{ key.toUpperCase() }}
            </v-chip>
          </div>
          <v-list three-line>
            <div
              v-for="(doc, index) in documentData"
              :key="doc.id"
            >
              <v-divider
                v-if="index !== 0"
                inset
              />
              <v-list-item
                v-if="doc.link !== ''"
                :to="doc.link"
                @click="closeDialog(doc)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ doc.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ doc.document }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ doc.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ doc.document }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
          <v-fade-transition>
            <div v-show="!documentData.length">
              <p>
                Document search will show the 50 most recently updated entries regarding a search.<br>
                The files are index and searchable by configured searchable fields.
              </p>
              <p>
                You can further improve results by typing the type of document followed by the id. <code>hedge 123</code><br>
                Furthermore if you know the exact id enter <code>hedge-123</code>
              </p>
              <p>
                <em>
                  Hint: you can middle mouse click to open lines in new tabs
                  <br>
                  Hint: The shortcut for opening the Document Search is <code>~</code> the key right under escape.
                </em>
              </p>
            </div>
          </v-fade-transition>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DocumentSearch',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: '',
    nameFilter: '',
    documentData: [],
    routeHandlers: {},
    timeout: null,
    loading: false,
    tableHeaders: [
      { text: 'Name', sortable: true, value: 'name' },
      { text: 'Info', sortable: true, value: 'document' }
    ],
    docHandlers: {
      BrokeredSettlement: { routeName: 'brokered-settlement' },
      CheckIn: { routeName: 'check-in' },
      Hedge: { routeName: 'hedge' },
      Intake: { routeName: 'intake' },
      Inventory: { routeName: 'inventory' },
      Lot: { routeName: 'lot' },
      PpmCalculation: { routeName: 'ppm-calculation' },
      Purchase: { routeName: 'purchase' },
      ShipmentSummary: { routeName: 'shipment-summary' },
      Yard: { routeName: 'yard' },
      Smelter: { routeName: 'smelter' },
      FinalReport: { routeName: 'lot-final-report' },
      InventoryManifest: { routeName: 'manifest-view' },
      SpendingPlan: { routeName: 'spending-plan-report' },
      Trip: { routeName: 'trip' },
      TripBlueprint: { routeName: 'blueprint' },
      Broker: { routeName: 'broker-view' },
      Carrier: { routeName: 'carrier-view' },
      Transit: { routeName: 'transit-view' }
    }
  }),
  computed: {
    documentDataTypes () {
      let chips = {}
      // getting the initial keys
      for (const key of Object.keys(this.routeHandlers).sort()) {
        chips[key] = 0
      }
      // adding counts
      this.documentData.forEach(data => {
        if (data.modelName in chips) chips[data.modelName] += 1
      })
      return chips
    }
  },
  watch: {
    value (open) {
      if (open) this.search = ''
    },
    search () {
      this.fetchDocuments()
    },
    nameFilter () {
      this.fetchDocuments()
    }
  },
  methods: {
    setNameFilter (value) {
      if (this.nameFilter === value) {
        this.nameFilter = ''
      } else {
        this.nameFilter = value
      }
    },
    /**
     * Apply navigation for a table click
     * @param doc
     */
    closeDialog () {
      this.$emit('input', false)
    },
    /**
     * Formats doc data before setting it to a local variable
     * @param docs
     */
    setDocuments (docs) {
      let documents = []
      docs.forEach(doc => {
        const [modelName, pk] = doc.id.split('-')
        const document = doc.document.split('||').join(', ')
        let link = ''
        if (this.routeHandlers[modelName]) {
          link = this.routeHandlers[modelName](pk)
          documents.push(Object.assign({ modelName, pk, link }, doc, { document }))
        } else {
          console.warn(`There is no route handler for ${modelName} and will be omitted`)
        }
      })
      this.documentData = documents
    },
    /**
     * Performs the lookup queries for doc search
     */
    fetchDocuments () {
      const val = this.search
      const nameFilter = this.nameFilter
      if (this.timeout) clearTimeout(this.timeout)
      if (!val) {
        this.setDocuments([])
        return
      }
      this.timeout = setTimeout(async () => {
        this.loading = true
        let params = {
          search: val
        }
        if (nameFilter.length) params.name__icontains = nameFilter
        const response = await axios.get('/api/app_search/', { params })
        this.loading = false
        this.setDocuments(response.data.results)
      }, 450)
    }
  },
  mounted () {
    const routeHandlers = {}
    for (const key in this.docHandlers) {
      routeHandlers[key.toLowerCase()] = id => ({ name: this.docHandlers[key].routeName, params: { id } })
    }
    this.routeHandlers = routeHandlers
  }
}
</script>

<style scoped>
.my-custom-dialog {
  align-self: flex-start;
}
</style>
