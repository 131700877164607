<template>
  <v-alert
    v-model="displayAlert"
    transition="slide-y-transition"
    :type="alertContent.type"
    outlined
  >
    <div class="d-flex justify-space-between">
      <div>
        {{ alertContent.message }}
      </div>
      <div>
        <v-btn
          small
          text
          @click="displayAlert = false"
        >
          decline
        </v-btn>
        <v-btn
          small
          class="ml-4"
          color="primary"
          @click="runCallback"
        >
          Accept
        </v-btn>
      </div>
    </div>
  </v-alert>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppAlert',
  data: () => ({
    displayAlert: false
  }),
  computed: {
    ...mapGetters(['alertContent'])
  },
  watch: {
    alertContent: {
      handler () {
        if (this.alertContent.message !== null) {
          this.displayAlert = true
        }
      }
    }
  },
  methods: {
    runCallback () {
      this.displayAlert = false
      this.alertContent.callback()
    }
  }
}
</script>
