import axios from 'axios'

export default {
  namespaced: true,
  state: {
    lots: [],
    lot: [],
    // these are actually designations
    lotProvisionals: [],
    lotProvisional: {},
    frResults: 10
  },
  getters: {
    // Complete, Dissolved
    finalStatuses: () => ['CO', 'DS'],
    lots: state => state.lots,
    lot: state => state.lot,
    lotTypes: (state, getters, rootState, rootGetters) => rootGetters['getMaterialDesignations'],
    lotTypesActive: (state, getters, rootState, rootGetters) => rootGetters['getMaterialDesignations']
      .filter(d => d.is_active),
    lotMaterialType: (state, getters, rootState, rootGetters) => rootGetters['getLotTypes'],
    lotStatuses: (state, getters, rootState, rootGetters) => rootGetters['getLotStatuses'],
    lotProvisionals: state => state.lotProvisionals,
    lotProvisional: state => state.lotProvisional,
    frResults: state => state.frResults
  },
  mutations: {
    setLots (state, lots) {
      state.lots = lots
    },
    appendLots (state, lots) {
      state.lots = [...state.lots, ...lots]
    },
    setLot (state, lot) {
      state.lot = lot
    },
    setLotProvisional (state, lotProvisional) {
      state.lotProvisional = lotProvisional
    },
    setLotProvisionals (state, lotProvisionals) {
      state.lotProvisionals = lotProvisionals
    },
    setFinalReportsPerPage (state, frResults) {
      state.frResults = frResults
    }
  },
  actions: {
    /**
     * Get the next page of lots given optional query params
     * @param {Object} commit - access to store setters
     * @param {Object} params - optional filters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchLots ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/lots/', { params })
        commit('setLots', response.data.results)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred loading the lots', { root: true })
      }
    },
    /**
     * Get the next page of detailed lots given optional query params
     * @param {Object} commit - access to store setters
     * @param {Object} params - optional filters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchLotsDetailed ({ commit }, params = null) {
      try {
        const lotsResponse = await axios.get('/api/lots/', { params })
        const lots = lotsResponse.data
        const lotIds = lots.map(lot => lot.id).join(',')
        params = { lot__id__in: lotIds }
        const sslResponse = await axios.get('/api/shipment_summary_lots/', { params })
        const ssls = sslResponse.data.results
        lots.map((lot) => {
          const matches = ssls.filter(ssl => ssl.url === lot.shipment_summary_lot)
          if (matches.length > 0) lot.shipment_summary_lot = matches[0]
          return lot
        })
        commit('setLots', lots)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred loading the lots', { root: true })
      }
    },
    /**
     * Get all lots given optional query params
     * @param {Object} commit - access to store setters
     * @param {Object} params - optional filters
     */
    async fetchAllLots ({ commit }, params = null) {
      try {
        !params ? params = { page_size: 0 } : params.page_size = 0
        const response = await axios.get('/api/lots/', { params })
        commit('setLots', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred loading the lots', { root: true })
      }
    },
    /**
     * Get a lot by its id
     * @param {Object} commit - access to store setters
     * @param {Number} id - id of the lot to get
     */
    async fetchLot ({ commit }, id) {
      try {
        const response = await axios.get('/api/lots/' + id + '/')
        commit('setLot', response.data)
      } catch (err) {
        commit('setSnackbarError', `An error occurred while trying to get lot with id: ${id}`, { root: true })
      }
    },
    /**
     * Get all lots given optional query params
     * @param {Object} commit - access to store setters
     * @param {Object} params - optional filters
     */
    async fetchLotProvisionals ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/lot_provisionals/', { params })
        commit('setLotProvisionals', response.data.results)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred loading the lot provisionals', { root: true })
      }
    },
    /**
     * Get a lot provisional by its id
     * @param {Object} commit - access to store setters
     * @param {Integer} id
     */
    async fetchLotProvisional ({ commit }, id) {
      try {
        const response = await axios.get(`/api/lot_provisionals/${id}`)
        commit('setLotProvisional', response.data)
      } catch (err) {
        commit('setSnackbarError', `An error occurred while getting lot provisional with id: ${id}`, { root: true })
      }
    },
    /**
     * Update a given lot by its id
     * @param {Object} commit - access to store setters
     * @param {Object} body - id of lot and all params to update wrapped in formdata
     */
    async updateLot ({ commit }, body) {
      try {
        const id = body.id
        if (body.formData) {
          await axios.patch(`/api/lots/${id}/`, { formData: body.formData })
        } else {
          await axios.patch(`/api/lots/${id}/`, body)
        }
      } catch (err) {
        commit('setSnackbarError', 'Cannot upload settlement', { root: true })
      }
    },
    /**
     * Create a new lot given the appropriate body
     * @param {Object} commit - access to store setters
     * @param {Object} body
     */
    async createLot ({ commit }, body) {
      try {
        const payload = body.payload
        const response = await axios.post('/api/lots/', payload)
        const id = response.data.id
        const invParams = { lot_id: id, division_id: payload.division_id, name: payload.name }
        await axios.post('/api/inventories/', invParams)
        return id
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to create the lot', { root: true })
      }
    },
    /**
     * Create a new lot provisional given the appropriate body
     * @param {Object} commit - access to store setters
     * @param {Object} body
     * @return {Integer} id
     */
    async createLotProvisional ({ commit }, body) {
      try {
        const existing = await axios.get('/api/lot_provisionals/', { params: { lot_id: body.lot_id } })
        let response
        if (existing && existing.data.count > 0) {
          const id = existing.data.results[0].id
          response = await axios.patch(`/api/lot_provisionals/${id}/`)
        } else {
          response = await axios.post('/api/lot_provisionals/', body)
        }
        commit('setLotProvisional', response.data)
        return response.data.id
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to create the lot provisional', { root: true })
      }
    },
    async deleteInventoryLines ({ commit }, lotId) {
      const res = await axios.post(`/api/inventories/${lotId}/delete_lines/`)
      if (res.status === 201) commit('setSnackbarInfo', 'Lines deleted for lot', { root: true })
      return res
    }
  }
}
