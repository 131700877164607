/* Store reserved for globally accessible items like the global app loader
* */
import Vue from 'vue'

function getRoute () {
  return window.location.href
}

function getUnixTime () {
  return Math.floor(Date.now() / 1000)
}

function getUnixTimeMinusMinutes (minutes) {
  return Math.floor((Date.now() - 60000 * minutes) / 1000)
}

export default {
  namespaced: false,
  state: {
    loaders: []
  },
  getters: {
    isLoading: state => !!state.loaders.length
  },
  mutations: {
    clearLoadersOldTokens (state, minutes) {
      let clearedTokens = []
      const expirationTime = getUnixTimeMinusMinutes(minutes)
      state.loaders = state.loaders.filter(tokenSet => {
        if (tokenSet[1] > expirationTime) return true
        clearedTokens.push(tokenSet)
        return false
      })
      if (clearedTokens.length) {
        Vue.prototype.$logger.info(`clearLoadersOldTokens: Cleared ${clearedTokens.length} tokens`)
      }
    },
    /**
     * clears all tokens
     * @param state
     */
    clearPreviousGetLoadingTokens (state) {
      state.loaders = state.loaders.filter(tokenSet => {
        if (tokenSet[0] === 'GET:' + getRoute()) return true // keep tokens for this page
        return !tokenSet[0].startsWith('GET') // keep tokens that are not GET
      })
      Vue.prototype.$logger.debug(`clearPreviousGetLoadingTokens: Remaining Tokens ${state.loaders.join(',')}`)
    },
    /**
     * Clear a specific loading token
     * @param state
     * @param token
     */
    clearLoadingToken (state, token) {
      token = token + ':' + getRoute()
      for (const key in state.loaders) {
        if (state.loaders[key][0] === token) {
          Vue.prototype.$logger.debug(`clearLoadingToken: ${state.loaders[key]}`)
          const loaders = state.loaders
          loaders.splice(key, 1)
          state.loaders = loaders
          return
        }
      }
    },
    /**
     * Only sets tokens for POST PUT PATCH DELETE methods as of 20230329
     * Register new loading tokens
     * @param state
     * @param token
     */
    addLoadingBarToken (state, token) {
      if (token === 'GET') return
      token = token + ':' + getRoute() // Produces GET:path/to/page
      Vue.prototype.$logger.debug(`addLoadingBarToken: ${token}`)
      let loaders = state.loaders
      loaders.push([token, getUnixTime()])
      state.loaders = loaders
    }
  }
}
