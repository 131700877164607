<template>
  <v-menu
    ref="menu"
    v-model="openDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="displaytime"
        :label="label"
        :disabled="disabled"
        readonly
        v-on="on"
        prepend-inner-icon="mdi-clock-outline"
        :error-messages="errorMessages"
      />
    </template>
    <v-time-picker
      ampm-in-title
      v-if="openDialog"
      scollable
      v-model="time"
      :min="min"
      :max="max"
      full-width
      @click:minute="$refs.menu.save(time)"
    />
  </v-menu>
</template>

<script>
import moment from 'moment'

export default {
  name: 'TimeInput',
  props: {
    value: { type: String, required: false, default: null },
    label: { type: String, required: false, default: null },
    min: { type: String, required: false, default: null },
    max: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
    errorMessages: { type: Array, required: false, default: () => [] }
  },
  data: () => ({
    openDialog: false,
    time: ''
  }),
  computed: {
    displaytime () {
      if (!this.time) return ''
      try {
        return moment(this.time, 'HH:mm').format('hh:mm a')
      } catch (err) {
        return ''
      }
    }
  },
  watch: {
    time () {
      this.$emit('input', this.time)
    }
  },
  mounted () {
    if (this.value) this.time = this.value
  }
}
</script>
