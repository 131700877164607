// https://axios-http.com/docs/cancellation

import Vue from 'vue'

let appRouteIgnoreList = [
  '/'
]
let requestUrlIgnoreList = [
  '/api/getcsrftoken/',
  '/api/users/current/'
]

const abortControllers = {}
let appRoute = ''

/**
 * This should be used to attach an request signal
 * @param requestUrl
 * @returns {*}
 */
export function getAbortController (requestUrl) {
  if (requestUrlIgnoreList.includes(requestUrl)) return
  if (!abortControllers[appRoute]) return
  Vue.prototype.$logger.debug(`AbortController:ASSIGN Route(${appRoute}) -> Request(${requestUrl})`)
  return abortControllers[appRoute]
}

/**
 * On route change pass the route to this method to flush and assign route aborts
 * @param route
 */
export function updateAndFlushAbortController (route) {
  if (route === appRoute) return
  if (abortControllers[appRoute]) {
    abortControllers[appRoute].abort()
    Vue.prototype.$logger.debug(`AbortController:FLUSH Route(${appRoute})`)
  } // clear the existing controller

  appRoute = route

  if (!appRouteIgnoreList.includes(route)) {
    abortControllers[appRoute] = new AbortController()
    Vue.prototype.$logger.debug(`AbortController:USING Route(${appRoute})`)
  } // create a new one
}

export function abortControllerInterceptor (config) {
  if (config.method === 'get') {
    const controller = getAbortController(config.url)
    if (controller) config.signal = controller.signal
  }
  return config
}
