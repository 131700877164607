import axios from 'axios'

import store from '@/store'

function simpleAllocation (allocation) {
  return {
    url: allocation.url,
    id: allocation.id,
    lot: allocation.lot.url,
    purchase: allocation.purchase.url,
    converter_type: allocation.converter_type
  }
}

const lotFields = [
  'url',
  'id',
  'name',
  'lot_type_display',
  'cut_plan_notes',
  'cut_plan_color',
  'cut_plan_order'
].join(',')

export default {
  namespaced: true,
  state: {
    purchaseFilters: [],
    lotCache: {},
    lotList: [],
    purchaseCache: {},
    purchaseList: [],
    allocations: [],
    colorSwatches: [
      { hex: ['#EAEEE9'], name: 'WHITE' },
      { hex: ['#ED7D31'], name: 'ORANGE' },
      { hex: ['#FF7F52'], name: 'CORAL' },
      { hex: ['#F94592'], name: 'PINK' },
      { hex: ['#5B9BD5'], name: 'LIGHT BLUE' },
      { hex: ['#0D47A1'], name: 'BLUE' },
      { hex: ['#FFC000'], name: 'YELLOW' },
      { hex: ['#1B5E20'], name: 'GREEN' },
      { hex: ['#7030A0'], name: 'PURPLE' },
      { hex: ['#FF0000'], name: 'RED' },
      { hex: ['#32CD32'], name: 'LIGHT GREEN' },
      { hex: ['#FF5F29'], name: 'ORANGE', inactive: true },
      { hex: ['#17234E'], name: 'DARKEST BLUE', inactive: true },
      { hex: ['#1F4E79'], name: 'DARK BLUE', inactive: true },
      { hex: ['#385723'], name: 'ARMY GREEN', inactive: true },
      { hex: ['#800020'], name: 'BURGUNDY' },
      { hex: ['#E694FF'], name: 'LIGHT PURPLE' },
      { hex: ['#808080'], name: 'GRAY' },
      { hex: ['#FFF59E'], name: 'LIGHT YELLOW' },
      { hex: ['#808000'], name: 'OLIVE' },
      { hex: ['#008080'], name: 'TEAL' },
      { hex: ['#D2B48C'], name: 'TAN' },
      { hex: ['#00FFFF'], name: 'AQUA' },
      { hex: ['#F5DADF'], name: 'LIGHT PINK' }
    ],
    ciLocation: 'All'
  },
  getters: {
    getPurchaseFilters (state) {
      return state.purchaseFilters
    },
    getAllAllocations (state) {
      return state.allocations
    },
    getColorSwatches (state) {
      return state.colorSwatches
    },
    getLotCache (state) {
      return state.lotCache
    },
    getPurchaseCache (state) {
      return state.purchaseCache
    },
    getLots (state) {
      // filtered by HE/Open and CU/Cutting
      let lots = state.lotList.map(url => state.lotCache[url])
        .filter(l => ['HE', 'CU'].includes(l.status))
        .sort((a, b) => (a.cut_plan_order - b.cut_plan_order))

      if (state.purchaseFilters.length) {
        //  filter for purchases
        const wlLots = new Set()
        for (const allocation of state.allocations) {
          if (state.purchaseFilters.includes(allocation.purchase)) {
            wlLots.add(allocation.lot)
          }
        }
        lots = lots.filter(lot => wlLots.has(lot.url))
      }

      return lots
    },
    getPurchases (state) {
      return state.purchaseList.map(url => state.purchaseCache[url])
    },
    getAllocations (state) {
      return state.allocations.reduce((c, allo) => {
        c[allo.url] = allo
        return c
      }, {})
    },
    // index with purchaseUrl:type
    getAllocationsIndexPurchaseType (state) {
      const allocations = state.allocations.reduce((c, allo) => {
        c[allo.url] = allo
        return c
      }, {})
      const index = {}
      for (const a of Object.values(allocations)) {
        index[`${a.purchase}:${a.converter_type}`] = a
      }
      return index
    },
    converterTypesIndex (state, getters, rootState, rootGetters) {
      return rootGetters.getConverterTypes.reduce((c, type) => {
        c[type.value] = type.text
        return c
      }, {})
    },
    getFilteredPurchases (state) {
      let purchases = state.purchaseList
        .map(url => state.purchaseCache[url]) // get the purchases from cash
        .filter(purchase => {
          // EXCLUDE CUT PURCHASES
          if (!purchase.is_uncut) return false
          // EXCLUDE NOT NOT FILTERED
          if (state.purchaseFilters.length && !state.purchaseFilters.includes(purchase.url)) return false
          return true
        })

      // Apply basic ID sorting
      purchases.sort((a, b) => {
        if (!a.check_ins_details[0] || !b.check_ins_details[0]) return 0
        return a.check_ins_details[0].id - b.check_ins_details[0].id
      })

      if (state.ciLocation === 'All') return purchases
      return purchases.reduce((accu, p) => {
        if (state.ciLocation && p.check_ins_details && p.check_ins_details.some(c => c.current_location === state.ciLocation)) {
          accu.push(p)
        }
        return accu
      }, [])
    }
  },
  mutations: {
    setPurchaseFilters (state, filters) {
      state.purchaseFilters = filters
    },
    addLot (state, lot) {
      const obj = Object.assign({}, state.lotCache)
      Object.freeze(lot)
      obj[lot.url] = lot
      state.lotCache = obj
    },
    addPurchase (state, purchase) {
      const obj = Object.assign({}, state.purchaseCache)
      Object.freeze(purchase)
      obj[purchase.url] = purchase
      state.purchaseCache = obj
    },
    setLotList (state, lots) {
      const obj = Object.assign({}, state.lotCache)
      state.lotList = lots.map(lot => {
        Object.freeze(lot)
        obj[lot.url] = lot
        return lot.url
      })
      state.lotCache = obj
    },
    setPurchaseList (state, purchases) {
      const obj = Object.assign({}, state.purchaseCache)
      state.purchaseList = purchases.map(purchase => {
        Object.freeze(purchase)
        obj[purchase.url] = purchase
        return purchase.url
      })
      state.purchaseCache = obj
    },
    setAllocations (state, allocations) {
      state.allocations = allocations.map(a => {
        Object.freeze(a)
        return a
      })
    },
    addAllocation (state, allocation) {
      Object.freeze(allocation)
      state.allocations.push(allocation)
    },
    removeAllocation (state, purchaseLotUrl) {
      state.allocations = state.allocations.map(a => a.url !== purchaseLotUrl)
    },
    setCiLocation (state, ciLocation) {
      state.ciLocation = ciLocation
    }
  },
  actions: {
    fetchLot ({ commit }, url) {
      const params = { fields: lotFields }
      axios.get(url, { params }).then(res => {
        if (res.status !== 200) return
        commit('addLot', res.data)
      })
    },
    fetchCutPlan ({ commit }) {
      return axios.get('/api/purchase_lots/cut_plan/').then(res => {
        if (res) {
          const data = res.data
          commit('setPurchaseList', data.purchases)
          commit('setLotList', data.lots)
          commit('setAllocations', data.allocations)
        }
      })
    },
    updateAllocation ({ commit }, { purchaseLotUrl, payload }) {
      return axios.patch(purchaseLotUrl, payload).then(response => {
        if (response.status !== 200) return
        commit('addAllocation', simpleAllocation(response.data))
      })
    },
    async dynamicAllocation ({ commit, dispatch }, changes) {
      await axios.post('/api/purchase_lots/dynamic/', changes)
      return dispatch('fetchCutPlan')
    },
    createAllocation ({ commit }, payload) {
      return axios.post('/api/purchase_lots/', payload).then(response => {
        if (response.status !== 201) return
        commit('addAllocation', simpleAllocation(response.data))
      })
    },
    removeAllocation ({ commit }, purchaseLotUrl) {
      return axios.delete(purchaseLotUrl).then(response => {
        if (response.status !== 204) return
        commit('removeAllocation', purchaseLotUrl)
      })
    },
    updateLot ({ commit }, { lotUrl, payload }) {
      return axios.patch(lotUrl, payload, { fields: lotFields }).then(response => {
        if (response.status !== 200) {
          store.commit('setSnackbarError', 'An error occurred while updating the lot.')
          return
        }
        commit('addLot', response.data)
      })
    }
  }
}
