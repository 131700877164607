import axios from 'axios'

export default {
  namespaced: true,
  state: {
    divisions: [],
    division: null
  },
  getters: {
    divisions: state => state.divisions,
    division: state => state.division
  },
  mutations: {
    setDivisions (state, divisions) {
      state.divisions = divisions
    },
    appendDivisions (state, divisions) {
      state.divisions = [...state.divisions, ...divisions]
    },
    setDivision (state, division) {
      state.division = division
    }
  },
  actions: {
    /**
     * Get all divisions in the database
     * @param {Object} commit - access to store setters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchDivisions ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/divisions/', { params })
        if (params && params.page && params.page > 1) commit('appendDivisions', response.data.results)
        else commit('setDivisions', response.data.results)
        return response.data.count
      } catch {
        // Errors caught by axios Error Interceptor
      }
    },
    async fetchDivision ({ commit }, url) {
      try {
        let div = await axios.get(url)
        commit('setDivision', div.data)
      } catch {
        // Errors caught by axios Error Interceptor
      }
    }
  }
}
