import Vue from 'vue'
import axios from 'axios'
import { onIdTokenChanged, signOut } from 'firebase/auth'
import { auth } from '../../firebase_provider'
import store from '@/store'

/** Define a default action to perform after authentication */
let instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const setup = () => {
  if (instance) return instance

  instance = new Vue({
    data: () => ({
      firebaseUser: null,
      user: null,
      // updated when user is updated or via the interceptor token refresh
      accessToken: ''
    }),
    computed: {
      isAuthenticated () {
        return !!this.user
      }
    },
    methods: {
      /**
       * Used to retrieve the user profile, effectively logging the user in
       */
      async fetchCurrentUser () {
        if (this.accessToken) {
          const res = await axios.get('/api/users/current/', {
            headers: {
              'Authorization': 'firebase ' + this.accessToken
            }
          })
          if (res.status === 200 && res.data) this.user = res.data
        }
      },
      logout () {
        signOut(auth).then(() => {
          // Sign-out successful.
          this.user = null
          this.firebaseUser = null
        }).catch(err => {
          const msg = 'Firebase logout failed'
          store.commit('setSnackbarError', msg)
          Vue.prototype.$logger.error(msg + ': ' + err.message)
        })
      }
    },
    created () {
      onIdTokenChanged(auth, (user) => {
        if (!user) return

        this.firebaseUser = user
        this.accessToken = user.accessToken

        // Update your app's state with the signed-in user information
        this.fetchCurrentUser().catch(err => {
          let msg = 'Fetching current user failed'
          if (err.response.data.detail) msg += ': ' + err.response.data.detail

          // log the error message
          Vue.prototype.$logger.error(msg)

          // user not found error special case handling
          if (msg.includes('Unable to authenticate user by email')) {
            store.commit('setSnackbarWarning', 'Your user account could not be found. Please contact support.')
            Vue.prototype.$logger.info('logging user back out after failed support')
            this.logout()
            return
          }
          store.commit('setSnackbarError', msg)
        })
      })
    }
  })
  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export default function (Vue, options) {
  Vue.prototype.$auth = setup(options)
}
