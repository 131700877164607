/* eslint-disable no-console */
import store from './store'
import { register } from 'register-service-worker'

console.log('sw-v1.0.0')

const notifyUserAboutUpdate = sw => {
  const handler = () => {
    store.commit('setAlertInfo', {
      message: 'New content is available. Refresh the page now?',
      callback: () => {
        console.log('called handler')
        sw.postMessage({ action: 'SKIP_WAITING' })
      }
    }, { root: true })
  }
  setTimeout(handler, 3000)
}

if ('serviceWorker' in navigator) {
  register('service-worker.js', {
    // ready () { console.log('App is being served from cache by a service worker.\n For more details, visit https://goo.gl/AFskqB') },
    // registered (registration) { console.log('Service worker has been registered.') },
    // cached () { console.log('Content has been cached for offline use.') },
    // updatefound (registration) { console.log('New content is downloading.') },
    // offline () { console.log('No internet connection found. App is running in offline mode') },
    updated (registration) {
      console.log('New content is available. Please refresh')
      notifyUserAboutUpdate(registration.waiting)
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })

  var refreshing = false
  navigator.serviceWorker.oncontrollerchange = function () {
    if (refreshing) return
    console.log('Reloading browser')
    refreshing = true
    window.location.reload()
  }
}
