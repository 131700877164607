<template>
  <v-dialog
    v-model="opened"
    max-width="600"
  >
    <ValidationObserver
      v-slot="{ invalid, reset }"
      ref="observer"
    >
      <v-form
        ref="form"
        :value="invalid && valid"
        @submit.prevent="submit"
        @reset.prevent="reset"
      >
        <v-card
          v-if="mode === 'reset'"
        >
          <v-card-title>
            Reset Password
          </v-card-title>
          <v-card-text
            style="min-height: 200px"
          >
            <rules-text
              validation-rules="required|email"
              v-model="username"
              autofocusd
              name="username"
              label="Email"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="mode = 'login'"
            >
              back
            </v-btn>
            <v-btn
              :disabled="invalid"
              color="primary"
              type="submit"
            >
              Reset
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-if="mode === 'login'"
        >
          <v-card-title>
            Login
          </v-card-title>
          <v-card-text
            style="min-height: 200px"
          >
            <rules-text
              validation-rules="required|email"
              v-model="username"
              autofocusd
              name="username"
              label="Email"
            />
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="password"
                name="password"
                label="Password"
                :type="show ? 'text' : 'password'"
                :error-messages="errors"
                :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                @click:append="show = !show"
              />
            </ValidationProvider>
            <p
              v-if="errors"
              class="red--text"
            >
              {{ errors }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              @click="mode = 'reset'"
            >
              forgot password?
            </v-btn>
            <v-spacer />
            <v-btn
              text
              @click="opened = false"
            >
              close
            </v-btn>
            <v-btn
              :disabled="invalid"
              color="primary"
              type="submit"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>

import RulesText from '@/components/ValidationFields/RulesText.vue'
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../firebase_provider'

export default {
  name: 'FirebaseLoginDialog',
  components: { RulesText },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    errors: '',
    username: '',
    password: '',
    show: false,
    valid: false,
    mode: 'login'
  }),
  watch: {
    value (opened) {
      if (opened) {
        this.mode = 'login'
        this.reset()
      }
    }
  },
  computed: {
    opened: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    submit () {
      switch (this.mode) {
        case 'login':
          this.submitLogin()
          break
        case 'reset':
          this.submitReset()
          break
      }
    },
    submitLogin () {
      if (this.username && this.password) {
        signInWithEmailAndPassword(auth, this.username, this.password)
          .then(() => {
            this.errors = ''
            this.$store.commit('setSnackbarSuccess', 'Login Successful!')
            this.opened = false
          }).catch(err => {
            this.errors = err.message
            this.$store.commit('setSnackbarError', `Authentication failed code: ${err.errorCode}`)
          })
      } else {
        this.$store.commit('setSnackbarWarning', 'Username and Password Required')
      }
    },
    submitReset () {
      sendPasswordResetEmail(auth, this.username)
        .then(() => {
          this.$store.commit('setSnackbarSuccess', 'Check your registered email to reset the password!')
          this.opened = false
        }).catch(() => {
          this.$store.commit('setSnackbarError', 'Password reset could not be sent')
        })
    }
  }
}
</script>
