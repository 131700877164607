import factories from '../generics/factories'
import axios from 'axios'

const { factory, createMixin, fetchMixin, patchMixin, deleteMixin } = factories

/**
 * Fetch Mixin for use with a factory
 * @param {Vuex.Store} store
 * @param {string} entityName
 * @param {function} urlTemplate
 */
function addBoxesMixin (store, { entityName, urlTemplate }) {
  store.actions.addBoxes = function ({ commit }, { id, payload }) {
    const prom = axios.patch(urlTemplate(id) + 'add_boxes/', payload)
    prom.then(response => {
      if (response.status === 200) {
        commit('setEntity', { id: id, item: response.data })
      }
    })
    return prom
  }
}

const store = factory(
  {
    entityName: 'InventoryManifest',
    urlTemplate: id => id ? `api/inventory_manifests/${id}/` : `api/inventory_manifests/`
  },
  addBoxesMixin,
  createMixin,
  fetchMixin,
  patchMixin,
  deleteMixin
)

export default store
