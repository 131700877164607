<template>
  <div>
    <v-tabs
      v-model="activeTab"
      class="mb-2 d-print-none"
      background-color="transparent"
      slider-color="primary"
      centered
      show-arrows
      fixed-tabs
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.id"
        :to="tab.route"
        :exact="exactTabs"
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'ParentTabs',
  props: {
    tabs: { type: Array, required: true },
    exactTabs: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    activeTab: ''
  })
}
</script>
