import axios from 'axios'
import brokeredSettlementEditStore from '@/store/modules/brokeredSettlementEditStore'

export default {
  namespaced: true,
  modules: {
    brokeredEdit: brokeredSettlementEditStore
  },
  state: {
    shipmentSummaries: [],
    shipmentSummary: null,
    shipmentSummaryLots: [],
    shipmentSummaryLot: {},
    brokeredSettlement: {},
    smelter: {},
    lots: [],
    inputType: null
  },
  getters: {
    getLots: state => state.lots,
    getSmelter: state => state.smelter,
    getInputType: state => state.inputType,
    shipmentSummaries: state => state.shipmentSummaries,
    shipmentSummary: state => state.shipmentSummary,
    shipmentSummaryLots: state => state.shipmentSummaryLots,
    shipmentSummaryLot: state => state.shipmentSummaryLot,
    brokeredSettlement: state => state.brokeredSettlement
  },
  mutations: {
    setLots (state, lots) {
      state.lots = lots
    },
    setSmelter (state, smelter) {
      state.smelter = smelter
    },
    setShipmentSummaries (state, shipmentSummaries) {
      state.shipmentSummaries = shipmentSummaries
    },
    appendShipmentSummaries (state, shipmentSummaries) {
      state.shipmentSummaries = [...state.shipmentSummaries, ...shipmentSummaries]
    },
    setShipmentSummary (state, shipmentSummary) {
      state.shipmentSummary = shipmentSummary
    },
    setShipmentSummaryLots (state, shipmentSummaryLots) {
      state.shipmentSummaryLots = shipmentSummaryLots
    },
    appendShipmentSummaryLots (state, shipmentSummaryLots) {
      state.shipmentSummaryLots = [...state.shipmentSummaryLots, ...shipmentSummaryLots]
    },
    setShipmentSummaryLot (state, shipmentSummaryLot) {
      state.shipmentSummaryLot = shipmentSummaryLot
    },
    setBrokeredSettlement (state, brokeredSettlement) {
      state.brokeredSettlement = brokeredSettlement
    },
    setInputType (state, inputType) {
      state.inputType = inputType
    }
  },
  actions: {
    /**
     * Get all Shipment Summaries or all Shipment Summaries matching filters
     * @param {Object} commit - to call store setters
     * @param {Object || Null} params - optional filters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchShipmentSummaries ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/shipment_summaries/', { params })
        if (params && params.page && params.page > 1) commit('appendShipmentSummaries', response.data.results)
        else commit('setShipmentSummaries', response.data.results)
        return response.data.count
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get Shipment Summaries', { root: true })
      }
    },
    /**
     * Get a Shipment Summary by its id
     * @param {Object} commit - to call store setters
     * @param {Number} id - id of the shipment summary to get
     */
    async fetchShipmentSummary ({ commit }, id) {
      try {
        const response = await axios.get(`/api/shipment_summaries/${id}/`)
        commit('setShipmentSummary', response.data)
      } catch (err) {
        commit(
          'setSnackbarError',
          `An error occurred while trying to get Shipment Summary with id: ${id}`,
          { root: true }
        )
      }
    },
    /**
     * Get all shipment summary lots
     * @param {Object} commit - to call store setters
     * @param {Object} params - filters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchShipmentSummaryLots ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/shipment_summary_lots/', { params })
        if (params && params.page && params.page > 1) commit('appendShipmentSummaryLots', response.data.results)
        else commit('setShipmentSummaryLots', response.data.results)
        return response.data.count
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get Shipment Summary Lots', { root: true })
      }
    },
    /**
     * Get all shipment summary lots
     * @param {CallableFunction} commit - to call store setters
     * @param {Object} params - filters
     */
    async fetchShipmentSummaryLotNoPaging ({ commit }, params) {
      try {
        params.page_size = 0
        const response = await axios.get('/api/shipment_summary_lots/', { params })
        commit('setShipmentSummaryLots', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get Shipment Summary Lots', { root: true })
      }
    },
    /**
     * Get a shipment summary lot by its id
     * @param {Object} commit - to call store setters
     * @param {Integer | String} id
     */
    async fetchShipmentSummaryLot ({ commit }, id) {
      try {
        const response = await axios.get(`/api/shipment_summary_lots/${id}/`)
        const ppmIds = response.data.ppm_calculations
        const ppms = []
        for (let i = 0; i < ppmIds.length; i++) {
          const id = ppmIds[i].id
          const nextPpm = await axios.get(`/api/ppm_calculations/${id}/`)
          ppms.push(nextPpm.data)
        }
        response.data.ppm_calculations = ppms
        commit('setShipmentSummaryLot', response.data)
        return response.data
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get Shipment Summary Lots', { root: true })
      }
    },
    /**
     * Creates the initial shipment summary
     * @param {*} commit - unused
     * @param {*} body - formData; docs & form page data
     */
    async createShipmentSummary ({ commit }, formData) {
      try {
        const response = await axios.post('/api/shipment_summaries/', formData)
        commit('setShipmentSummary', response.data)
        return response.data.id
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to create the Shipment Summary', { root: true })
      }
    },
    /**
     * Creates shipment summary lot, updates lot to append created ssl
     * @param {*} commit - unused
     * @param {*} params - all fields entered on ssl template and lot ids
     */
    async createSSL ({ commit }, body) {
      try {
        let res = await axios.post('/api/shipment_summary_lots/', body)
        let updatedLot = await axios.patch(`/api/lots/${body.lot_id}/`, { shipment_summary_lot_id: res.data.id })
        let ss = await axios.get(`/api/shipment_summaries/${body.shipment_summary_id}/`)
        commit('lotStore/setLots', updatedLot.data, { root: true })
        commit('setShipmentSummary', ss.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to create the Shipment Summary', { root: true })
        return err
      }
    },
    /**
     * updates shipment summary
     * @param {*} commit - sets updated shipment summary
     * @param {*} params - added docs& edited summary data
     */
    async updateShipmentSummary ({ commit }, summary) {
      try {
        const id = summary.id
        let res
        if (summary.formData) res = await axios.patch(`/api/shipment_summaries/${id}/`, summary.formData)
        else res = await axios.patch(`/api/shipment_summaries/${id}/`, summary)
        commit('setShipmentSummary', res.data)
        return res.data.id
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to update the Shipment Summary', { root: true })
      }
    },
    /**
     * Updates shipment summary lots and the attached lots, changes lot status to reconcile
     * STATUS IS COMMENTED OUT TO ALLOW FOR UPLOADING OLD SUMMARIES. COMMENT BACK IN TO AUTO UPDATE
     * STATUS WHEN SUMMARY IS ADDED
     * @param {*} commit - sets updated lots
     * @param {*} params - shipment summary lots
     */
    async updateShipmentSummaryLots ({ commit }, summaryLotSet) {
      try {
        const updated = []
        const lots = summaryLotSet
        for (let i = 0; i < lots.length; i++) {
          await axios.patch(`/api/shipment_summary_lots/${lots[i].id}/`, lots[i])
          const body = {
            // status: 'RC',
            shipment_summary_lot_id: lots[i].id
          }
          const updatedLots = await axios.patch(`/api/lots/${lots[i].lotId}/`, body)
          updated.push(updatedLots.data)
        }
        commit('lotStore/setLots', updated, { root: true })
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to update the Shipment Summary Lots', { root: true })
      }
    },
    /**
     * Update a shipment summary via Import; patch included lots
     * @param {Object} commit - to call store setters
     * @param {Object} body - contains id of shipment summary and the file or file text be uploaded
     */
    async shipmentSummaryUpload ({ commit }, { id, file, fileText, smelterId, lots }) {
      const payload = {}
      let response = null
      try {
        if (fileText) {
          payload.file_text = fileText
          response = await axios.patch(`/api/shipment_summaries/${id}/`, payload)
        } else if (file) {
          const formData = new FormData()
          formData.append('file', file[0])
          response = await axios.patch(`/api/shipment_summaries/${id}/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        }
        if (response) {
          for (let i = 0; i < lots.length; i++) {
            const sslId = response.data.shipmentsummarylot_set[0].id
            await axios.patch(`/api/lots/${lots[i].id}/`, {
              shipment_summary_lot_id: sslId
            })
          }
          commit('setShipmentSummary', response.data)
          commit('setShipmentSummaryLots', response.data.shipmentsummarylot_set)
        }
        return response.data.id
      } catch (err) {
        commit(
          'setSnackbarError',
          `An error occurred while trying to get Shipment Summary with id: ${id}`,
          { root: true }
        )
      }
    },
    // creates a settlement for a brokered lot. updates cooresponding shipment summary lot and lot
    async createBrokeredSettlement ({ commit }, body) {
      try {
        let res = await axios.post(`/api/brokered_settlements/`, body)
        let patchedSSL = await axios.patch(`/api/shipment_summary_lots/${body.ssl.id}/`, { brokeredsettlement: res.data.url })
        commit('setBrokeredSettlement', res.data)
        commit('setShipmentSummaryLot', patchedSSL.data)
        commit('lotStore/setLot', body.lot, { root: true })
        return res
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to create the settlement', { root: true })
      }
    },
    // updates a settlement for a brokered lot. updates cooresponding shipment summary lot and lot
    async updateBrokeredSettlement ({ commit }, body) {
      try {
        let res = await axios.patch(`/api/brokered_settlements/${body.id}/`, body)
        commit('setBrokeredSettlement', res.data)
        commit('setShipmentSummaryLot', body.ssl)
        commit('lotStore/setLot', body.lot, { root: true })
        return res
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to update the settlement', { root: true })
      }
    },
    // gets the settlement for a brokered lot
    async fetchBrokeredSettlement ({ commit }, url) {
      try {
        let res = await axios.get(url)
        commit('setBrokeredSettlement', res.data)
        return res.data
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get the settlement', { root: true })
      }
    },
    // Deletes the shipment summary and updates the pertaining lot with shipment summary lot as null
    async deleteShipmentSummary ({ commit }, body) {
      try {
        await axios.delete(body.url)
        if (body.shipmentsummarylot_set.length > 0) {
          let sslLotPromises = body.shipmentsummarylot_set.map(ssl => axios.patch(`/api/lots/${ssl.lot.id}/`, { shipment_summary_lot_id: null }))
          await Promise.all(sslLotPromises)
        }
        commit('setShipmentSummaryLots', [])
        commit('setShipmentSummary', null)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to delete the shipment summary', { root: true })
      }
    },
    // Deletes the shipment summary lots and updates the pertaining lot with shipment summary lot as null
    async deleteSSL ({ commit }, body) {
      try {
        let sslPromises = body.shipmentsummarylot_set.map(ssl => axios.delete(ssl.url))
        await Promise.all(sslPromises)
        let sslLotPromises = body.shipmentsummarylot_set.map(ssl => axios.patch(`/api/lots/${ssl.lot.id}/`, { shipment_summary_lot_id: null }))
        await Promise.all(sslLotPromises)
        let res = await axios.patch(body.url, { shipmentsummarylot_set: [] })
        commit('setShipmentSummary', res.data)
        commit('setShipmentSummaryLots', [])
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to delete the shipment summary lots', { root: true })
      }
    }
  }
}
