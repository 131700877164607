import axios from 'axios'

export default {
  namespaced: true,
  state: {
    converters: [],
    converter: {},
    converterGroups: []
  },
  getters: {
    converters: state => state.converters,
    converter: state => state.converter,
    converterGroups: state => state.converterGroups
  },
  mutations: {
    setConverter (state, converter) {
      state.converter = converter
    },
    setConverters (state, converters) {
      state.converters = converters
    },
    appendConverters (state, converters) {
      state.converters = [...state.converters, ...converters]
    },
    setConverterGroups (state, converterGroups) {
      state.converterGroups = converterGroups
    }
  },
  actions: {
    /**
     * Get all converters the meet a given set of params
     * @param {Object} commit sets converters
     * @param {Object} params
     */
    async fetchConverters ({ commit }, params) {
      try {
        const response = await axios.get('/api/converters/', { params })
        if (params && params.page && params.page > 1) {
          commit('appendConverters', response.data.results)
        } else {
          commit('setConverters', response.data.results)
        }
        return response.data.count
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get converters', { root: true })
      }
    },
    /**
     * Gets a photobooth converter by its id
     * @param {commit} commit sets converter objects to store
     * @param {Number} id - id of photobooth converter
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchConverter ({ commit }, id) {
      try {
        const response = await axios.get(`/api/converters/${id}/`)
        const converter = response.data
        const params = { converter_id: id, page: 1 }
        const assayResponse = await axios.get('/api/assays/', { params })
        let assays = assayResponse.data.results
        const numPages = Math.ceil(assayResponse.data.count / 20)
        if (numPages > 1) {
          const promises = []
          for (let i = 2; i <= numPages; i++) {
            params.page = i
            promises.push(axios.get('/api/assays/', { params }))
          }
          const promiseResponse = await Promise.all(promises)
          const moreAssays = promiseResponse.reduce((accu, res) => {
            accu.push(...res.data.results)
            return accu
          }, [])
          assays = [...assays, ...moreAssays]
        }
        let images = []
        if (converter.picture_set) {
          const params = { group: converter.picture_set.id, limit: 0 }
          const imageRes = await axios.get('/api/converter_images/', { params })
          images = imageRes.data.results
        }
        converter.images = images
        converter.assays = assays
        commit('setConverter', converter)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get the converter', { root: true })
      }
    },
    /**
     * Creating a converter on a catalog in catalog/create catalog
     * @param {Object} commit sets converters
     * @param {Object} params
     * @return {Object}
     */
    async createConverter ({ commit }, params) {
      try {
        const converter = params.converter
        const makeModels = params.makeModels
        const response = await axios.post('/api/converters/', converter)
        if (makeModels) {
          const promises = makeModels.map(makeModel => {
            return axios.post('/api/converter_make_models/', {
              make_model_id: makeModel,
              converter_id: response.data.id
            })
          })
          await Promise.all(promises)
        }
        commit('setConverter', response.data)
        if (!params.silent) commit('setSnackbarSuccess', 'Converter successfully added', { root: true })
        return response.data
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to add converter', { root: true })
      }
    },
    fetchConverterGroups ({ commit }) {
      return axios.get('/api/converter_groups/configure/').then(response => {
        commit('setConverterGroups', response.data)
      })
    }
  }
}
