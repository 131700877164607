<template>
  <v-combobox
    :value="value"
    :items="allItems"
    :loading="loading || loadingData"
    :search-input.sync="search"
    :item-text="itemText"
    :item-value="itemValue"
    :return-object="returnObject || multiple"
    :label="label"
    :disabled="disabled"
    :error-messages="errorMessages"
    @click:clear="clearSearch"
    @input="updateValue"
    no-filter
    clearable
    color="black"
    prepend-inner-icon="mdi-magnify"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title v-if="loading || loadingData">
          Searching ...
        </v-list-item-title>
        <v-list-item-title v-else-if="!search">
          Search or Create <strong>{{ namePlural }}</strong>
        </v-list-item-title>
        <v-list-item-title v-else>
          A New {{ nameSingular }} Will Be Created
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:append-item>
      <infinite-loading
        v-if="entities.length >= itemsPerPage"
        @infinite="loadMore"
        class="mt-4"
        ref="InfiniteLoading"
        spinner="waveDots"
        :distance="distance"
      >
        <template v-slot:no-more>
          No more {{ namePlural }} found
        </template>
        <template v-slot:no-results>
          A New {{ nameSingular }} Will Be Created
        </template>
      </infinite-loading>
    </template>
  </v-combobox>
</template>

<script>
import { VCombobox } from 'vuetify/lib'
import PaginatedEntityMixin from '@/mixins/PaginatedEntityMixin'

export default {
  name: 'PaginatedCombobox',
  extends: VCombobox,
  mixins: [PaginatedEntityMixin],
  async mounted () {
    await this.loadInitialData('', false)
  }
}
</script>
