import axios from 'axios'

export default {
  namespaced: true,
  state: {
    hedges: [],
    lots: [],
    allocations: [],
    allocationTotals: []
  },
  getters: {
    allocations: state => state.allocations,
    allocationTotals: state => state.allocationTotals
  },
  mutations: {
    setAllocations (state, allocations) {
      state.allocations = allocations
    },
    setAllocationTotals (state, totals) {
      state.allocationTotals = totals
    }
  },
  actions: {
    /**
     * Fetch all allocations (filterable)
     * @param {Object} commit - to call store setters
     * @param {Object} params - may be left out
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchAllocations ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/allocations/', { params })
        commit('setAllocations', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get allocations', { root: true })
      }
    },
    /**
     * Fetch all allocations (filterable)
     * @param {Object} commit - to call store setters
     * @param {Object} params - may be left out
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchAllocationTotals ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/allocations/overview_totals/', { params })
        commit('setAllocationTotals', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get allocation totals', { root: true })
      }
    },
    /**
     * Create a new allocation
     * @param {Object} commit - to call store setters
     * @param {Object} body
     */
    async addAllocation ({ commit }, body) {
      try {
        await axios.post('/api/allocations/', body)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to create the allocation', { root: true })
      }
    },
    /**
     * Edit an existing allocation
     * @param {Object} commit - to call store setters
     * @param {Object} updates
     */
    async editAllocation ({ commit }, updates) {
      try {
        await axios.patch(`/api/allocations/${updates.id}/`, updates)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to update the allocation', { root: true })
      }
    },
    /**
     * Delete an allocation
     * @param {Object} commit - to call store setters
     * @param {Object} id
     */
    async deleteAllocation ({ commit }, id) {
      try {
        await axios.delete(`/api/allocations/${id}`)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to create the allocation', { root: true })
      }
    }
  }
}
