<style scoped>
.roboto-thin {
  font-family: 'Roboto', serif;
  font-weight: 400;
}
</style>

<template>
  <div>
    <h1 class="title roboto-thin">
      {{ title }}
      <slot />
    </h1>
    <h2
      v-if="$slots['subtitle']"
      class="subtitle-1"
    >
      <slot name="subtitle" />
    </h2>
  </div>
</template>

<script>
export default {
  name: 'BaseTitle',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
