import axios from 'axios'
import mergeConverterStore from '@/store/modules/mergeConverterStore'

export default {
  namespaced: true,
  state: {
    catalogs: [],
    catalog: {},
    catalogTypes: [
      { text: 'Converter Dust', value: 'DT' },
      { text: 'Generic', value: 'GN' },
      { text: 'Converter', value: 'CV' }
    ],
    vehicles: [],
    makeModels: [],
    assays: [],
    converterImages: [],
    converterPrice: null
  },
  getters: {
    catalogs: state => state.catalogs,
    catalog: state => state.catalog,
    catalogTypes: state => state.catalogTypes,
    makeModels: state => state.makeModels,
    assays: state => state.assays,
    converterImages: state => state.converterImages,
    converterPrice: state => state.converterPrice,
    vehicles: state => state.vehicles
  },
  mutations: {
    setCatalogs (state, catalogs) {
      state.catalogs = catalogs
    },
    setCatalog (state, catalog) {
      state.assays = []
      state.converterImages = []
      state.converterPrice = null
      state.catalog = catalog
    },
    appendCatalogs (state, catalogs) {
      state.catalogs = [...state.catalogs, ...catalogs]
    },
    setMakeModels (state, makeModels) {
      state.makeModels = makeModels
    },
    appendMakeModels (state, makeModels) {
      state.makeModels = [...state.makeModels, ...makeModels]
    },
    setAssays (state, assays) {
      state.assays = assays
    },
    setConverterImages (state, converterImages) {
      state.converterImages = converterImages
    },
    setConverterPrice (state, price) {
      state.converterPrice = price
    },
    setVehicles (state, vehicles) {
      state.vehicles = vehicles
    }
  },
  actions: {
    /**
     * Fetch all catalogs
     * @param {Object} commit - to call store setters
     * @param {Object || Null} params
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchCatalogs ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/catalogs/', { params })
        if (params && params.page && params.page > 1) commit('appendCatalogs', response.data.results)
        else commit('setCatalogs', response.data.results)
        return response.data.count
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get catalogs', { root: true })
      }
    },
    /**
     * Fetching catalog by its id
     * @param {Object} commit - setting intakes to vuex
     * @param {Number} id - id of the catalog to get
     */
    async fetchCatalog ({ commit }, id) {
      try {
        const response = await axios.get(`/api/catalogs/${id}/`)
        commit('setCatalog', response.data)
        return response.data.converter
      } catch (err) {
        commit('setSnackbarError', `An error occurred while trying to get catalog with id: ${id}`, { root: true })
      }
    },
    async updateCatalog ({ commit }, { id, payload }) {
      try {
        const response = await axios.patch(`/api/catalogs/${id}/`, payload)
        commit('setCatalog', response.data)
        return response.data.converter
      } catch (err) {
        commit('setSnackbarError', `An error occurred while trying to get catalog with id: ${id}`, { root: true })
      }
    },
    /**
     * Creating a catalog from catalog/create-catalog
     * @param {Object} commit - setting catalog, catalogid(used for adding converter), and snackbar
     * @param {Object} body name, desc, type, UOM, price of catalog
     */
    async createCatalog ({ commit }, body) {
      try {
        const response = await axios.post('/api/catalogs/', body)
        commit('setCatalog', response.data)
        if (!body.silent) commit('setSnackbarSuccess', 'The Catalog Item was successfully created', { root: true })
        return response.data
      } catch (err) {
        commit('setSnackbarError', 'An error occurred creating this catalog', { root: true })
      }
    },
    /**
     * Fetching make/models for use in adding a converter to a catalog
     * @param {Object} commit sets make/model to vuex
     * @param {Object} params
     */
    async fetchMakeModels ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/make_models/', { params })
        if (params && params.page && params.page > 1) commit('appendMakeModels', response.data.results)
        else commit('setMakeModels', response.data.results)
        return response.data.count
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get make/models', { root: true })
      }
    },
    /**
     * Fetch all assays
     * @param {Object} commit - to call store setters
     * @param {Object || Null} params
     */
    async fetchAssays ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/assays/', { params })
        commit('setAssays', response.data.results)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get assays', { root: true })
      }
    },
    /**
     * Used to add an assay to a catalog-converter
     * @param {Object} commit sets assays to store
     * @param {Object} body converterID, assay body
     */
    async addAssay ({ commit }, body) {
      try {
        await axios.post('/api/assays/', body)
        commit('setSnackbarSuccess', 'Assay successfully added', { root: true })
      } catch (err) {
        commit('setSnackbarError', 'An error occurred', { root: true })
      }
    },
    /**
     * Used to add multiple assays to a catalog-converter
     * @param {Object} commit sets assays to store
     * @param {Array} assays converterID, assay
     */
    async addAssays ({ commit }, assays) {
      try {
        const promises = assays.map(assay => axios.post('/api/assays/', assay))
        const response = await Promise.all(promises)
        commit('setAssays', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred', { root: true })
      }
    },
    /**
     * Fetching all converter images for a given converter
     * @param {Object} commit sets make/model to vuex
     * @param {Object || Null} params
     */
    async fetchConverterImages ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/converter_images/', { params })
        commit('setConverterImages', response.data.results)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get converter images', { root: true })
      }
    },
    /**
     * Delete the given array of images
     * @param {Object} commit sets make/model to vuex
     * @param {Array} images
     */
    async deleteConverterImages ({ commit }, images) {
      try {
        const promises = images.map(image => axios.delete(image.url))
        await Promise.all(promises)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to delete converter images', { root: true })
      }
    },
    fetchConverterPrice ({ commit, state }) {
      if (!state.catalog) return
      axios.get(state.catalog.converter.url + 'price/').then(r => {
        commit('setConverterPrice', r.data)
      })
    },
    fetchVehicles ({ commit, state }) {
      if (!state.catalog) return
      axios.get(state.catalog.converter.url + 'vehicles/').then(r => {
        commit('setVehicles', r.data)
      })
    },
    async updateConverter ({ commit, state }, { id, payload }) {
      try {
        const response = await axios.patch(`/api/converters/${id}/`, payload)
        let catalog = Object.assign({}, state.catalog)
        catalog.converter = response.data
        commit('setCatalog', catalog)
        return response
      } catch (err) {
        commit('setSnackbarError', `An error occurred while trying to get catalog with id: ${id}`, { root: true })
      }
    }
  },
  modules: {
    merge: mergeConverterStore
  }
}
