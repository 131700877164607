  <!--
Simple and easy to use confirm dialog
<base-dialog
  v-model="deleteDialog"
  headline-text="Delete this allocation?"
  message-text="Please confirm that you would like to delete the allocation."
  decline-text="decline"
  accept-text="accept"
  @accept="deleteAllocation(allocation)"
/>
-->
<template>
  <v-dialog
    :value="value"
    @input="$emit('input')"
    max-width="400"
  >
    <v-card>
      <v-card-title class="headline">
        {{ headlineText }}
      </v-card-title>
      <v-card-text>
        {{ messageText }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="declineText"
          color="accent"
          text
          @click="$emit('input', false)"
        >
          {{ declineText }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="accept"
        >
          {{ acceptText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    headlineText: {
      type: String,
      required: true
    },
    messageText: {
      type: String,
      required: true
    },
    declineText: {
      type: String,
      required: true
    },
    acceptText: {
      type: String,
      required: true
    }
  },
  methods: {
    accept () {
      this.$emit('accept', true)
      this.$emit('input', false)
    }
  }
}
</script>
