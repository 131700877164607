import axios from 'axios'

export default {
  namespaced: true,
  state: {
    brokeredSettlement: null,
    lot: null,
    shipmentSummaryLot: null,
    lotName: '',
    yard: null,
    shipDate: '',
    convertersProcessed: 0,
    receivedLbs: 0,
    ptReturnRate: 93,
    pdReturnRate: 93,
    rhReturnRate: 90,
    treatmentChargePerLb: 1,
    smallLotFee: 0,
    financeCharge: 0,
    freightCharge: 0,
    advance: 0,
    amountDue: 0,
    oemOnly: false,
    notes: '',
    overrideConverterAverage: null,
    overrideProcessingFee: null,
    // Should be representative of whats in the database
    metals: [],
    purchasedOutright: [
      { material_type: 'IF', quantity: 0, unit_price: 0, total_price: 0, paid: true },
      { material_type: 'DF', quantity: 0, unit_price: 0, total_price: 0, paid: true },
      { material_type: 'AM', quantity: 0, unit_price: 0, total_price: 0, paid: true },
      { material_type: 'DPF', quantity: 0, unit_price: 0, total_price: 0, paid: true },
      { material_type: 'D3', quantity: 0, unit_price: 0, total_price: 0, paid: true }
    ]
  },
  getters: {
    /**
     * Converts database formatting into a local metals format
     */
    getFormattedMetals (state) {
      return state.metals.map(metal => {
        return {
          id: metal.id ? metal.id : null,
          url: metal.url ? metal.url : null,
          // returnRates set to yard.brokerage_term.rates if they exist
          // Default is current market rate
          platinum: {
            returnable: metal.pt_returnable,
            marketRate: metal.pt_mk,
            returnRate: state.yard.brokerageterm?.pt_rate ?? metal.pt_return_rate,
            value: metal.pt_value
          },
          palladium: {
            returnable: metal.pd_returnable,
            marketRate: metal.pd_mk,
            returnRate: state.yard.brokerageterm?.pd_rate ?? metal.pd_return_rate,
            value: metal.pd_value
          },
          rhodium: {
            returnable: metal.rh_returnable,
            marketRate: metal.rh_mk,
            returnRate: state.yard.brokerageterm?.rh_rate ?? metal.rh_return_rate,
            value: metal.rh_value
          }
        }
      })
    },
    getPurchasedOutright (state) {
      return state.purchasedOutright
    },
    getUnpaidValue (state) {
      // WAS only IF DF DPF before, changed to paid only
      let total = 0
      for (const line of state.purchasedOutright) {
        if (!line.paid) total += parseFloat(line.total_price)
      }
      return total.toFixed(2)
    },
    getProcessingFee (state) {
      if (state.overrideProcessingFee !== null) return state.overrideProcessingFee
      const treatmentCharge = +state.yard.brokerageterm.treatment_charge
      if (treatmentCharge && state.receivedLbs) return (treatmentCharge * +state.receivedLbs).toFixed(2)
      return 0
    },
    // Default to the yard's small_lot/handling fee if lot received lbs < 2000
    getSmallLotFee (state) {
      if (+state.lot.shipment_summary_lot.net_wet_lbs < 2000.00) {
        return state.yard.brokerageterm.handling_fee
      }
      return '0.00'
    },
    getFinanceCharge (state) {
      return (state.financeCharge) ? state.financeCharge : '0.00'
    },
    getFreightCharge (state) {
      return (state.freightCharge) ? state.freightCharge : '0.00'
    },
    getConverterAverage (state, getters) {
      if (state.overrideConverterAverage !== null) return state.overrideConverterAverage
      if (!getters.getNetValue || !state.convertersProcessed) return 0
      return (parseFloat(getters.getNetValue) / parseFloat(state.convertersProcessed)).toFixed(2)
    },
    getAveragePerLb (state, getters) {
      if (!getters.getNetValue || !state.receivedLbs) return 0
      return (parseFloat(getters.getNetValue) / parseFloat(state.receivedLbs)).toFixed(2)
    },
    getAmountDue (state, getters) {
      return [
        +getters.getNetValue,
        +getters.getUnpaidValue,
        -state.advance,
        -state.financeCharge,
        -state.freightCharge
      ].reduce((x, y) => x + y).toFixed(2)
    },
    getGrossValue (state) {
      if (!state.metals.length) return 0
      return state.metals.reduce((total, metal) => {
        return (total + Number(metal.pt_value || 0) + Number(metal.pd_value || 0) + Number(metal.rh_value || 0))
      }, 0).toFixed(2)
    },
    getNetValue (state, getters) {
      let val = parseFloat(getters.getGrossValue || 0) - (parseFloat(getters.getProcessingFee || 0) + parseFloat(state.smallLotFee || 0))
      return val.toFixed(2)
    },
    getPayloads (state, getters) {
      if (!state.lot || !state.shipmentSummaryLot) return {}
      return {
        brokeredSettlement: {
          shipment_summary_lot: state.shipmentSummaryLot.url,
          shipped_date: state.shipDate,
          converters_processed: state.convertersProcessed,
          average_per_lb: getters.getAveragePerLb,
          converter_average_price: getters.getConverterAverage,
          received_lbs_total: state.receivedLbs,
          gross_value: getters.getGrossValue,
          treatment_fee: getters.getProcessingFee,
          small_lot_fee: state.smallLotFee,
          finance_charge: state.financeCharge,
          freight_charge: state.freightCharge,
          net_value: getters.getNetValue,
          unpaid_units: getters.getUnpaidValue,
          advances: state.advance,
          amount_due: getters.getAmountDue,
          notes: state.notes,
          yard_id: state.yard.id,
          lot_name: state.lotName,
          oem_only: state.oemOnly
        },
        metals: state.metals,
        purchasedOutright: state.purchasedOutright.filter(line => line.total_price > 0)
      }
    }
  },
  mutations: {
    /**
     * Set using the detailed objects from the API
     * @param state
     * @param value
     */
    setPurchasedOutright (state, value) {
      state.purchasedOutright = value
    },
    setShipmentSummaryLot (state, value) {
      state.shipmentSummaryLot = value
      state.ptReturnable = value.returnable_pt_toz || 0
      state.pdReturnable = value.returnable_pd_toz || 0
      state.rhReturnable = value.returnable_rh_toz || 0
    },
    setYard (state, value) {
      state.yard = value
      if (value && value.brokerageterm) {
        const bt = value.brokerageterm
        state.ptReturnRate = bt?.pt_rate ?? 93
        state.pdReturnRate = bt?.pd_rate ?? 93
        state.rhReturnRate = bt?.rh_rate ?? 90
        state.treatmentChargePerLb = bt?.treatment_charge ?? 1.3
      }
    },
    setOverrideConverterAverage (state, value) {
      state.overrideConverterAverage = value
    },
    setOverrideProcessingFee (state, value) {
      state.overrideProcessingFee = value
    },
    setConvertersProcessed (state, value) {
      state.convertersProcessed = value
    },
    setReceivedLbs (state, value) {
      state.receivedLbs = value
    },
    setLot (state, value) {
      state.lot = value
    },
    setLotName (state, value) {
      state.lotName = value
    },
    setShipDate (state, value) {
      state.shipDate = value
    },
    setSmallLotFee (state, value) {
      state.smallLotFee = value
    },
    setFinanceCharge (state, value) {
      state.financeCharge = value
    },
    setFreightCharge (state, value) {
      state.freightCharge = value
    },
    setAdvance (state, value) {
      state.advance = value
    },
    setAmountDue (state, value) {
      state.amountDue = value
    },
    setOemOnly (state, value) {
      state.oemOnly = value
    },
    setNotes (state, value) {
      state.notes = value
    },
    setBrokeredSettlement (state, value) {
      state.brokeredSettlement = value
    },
    // set the metals value directly bypassing the formatted input
    setMetalsRaw (state, value) {
      state.metals = value
    },
    /**
     * Mutates the local formatting to something for the database
     */
    setMetals (state, metals) {
      state.metals = metals.map(metal => ({
        id: metal.id ? metal.id : null,
        url: metal.url ? metal.url : null,

        pt_returnable: metal.platinum.returnable,
        pt_mk: metal.platinum.marketRate,
        pt_return_rate: metal.platinum.returnRate,
        pt_value: metal.platinum.value,

        pd_returnable: metal.palladium.returnable,
        pd_mk: metal.palladium.marketRate,
        pd_return_rate: metal.palladium.returnRate,
        pd_value: metal.palladium.value,

        rh_returnable: metal.rhodium.returnable,
        rh_mk: metal.rhodium.marketRate,
        rh_return_rate: metal.rhodium.returnRate,
        rh_value: metal.rhodium.value
      }))
    }
  },
  actions: {
    loadStateFromSettlement ({ commit }, settlement) {
      commit('setBrokeredSettlement', settlement)
      commit('setPurchasedOutright', settlement.purchased_outright)
      commit('setShipmentSummaryLot', settlement.shipment_summary_lot)
      commit('setYard', settlement.yard)
      commit('setOverrideConverterAverage', settlement.converter_average_price)
      commit('setOverrideProcessingFee', settlement.treatment_fee)
      commit('setConvertersProcessed', settlement.converters_processed)
      commit('setReceivedLbs', settlement.received_lbs_total)
      commit('setLot', settlement.shipment_summary_lot)
      commit('setLotName', settlement.lot_name)
      commit('setShipDate', settlement.shipped_date)
      commit('setSmallLotFee', settlement.small_lot_fee)
      commit('setFreightCharge', settlement.freight_charge)
      commit('setFinanceCharge', settlement.finance_charge)
      commit('setAdvance', settlement.advances)
      commit('setAmountDue', settlement.amount_due)
      commit('setOemOnly', settlement.oem_only)
      commit('setNotes', settlement.notes)
      commit('setMetalsRaw', settlement.metals_details)
    },
    /**
     * Creates a brokered settlement using the store values from getPayloads
     * @param dispatch
     * @param commit
     * @param getters
     * @return {Promise<void>}
     */
    async createSettlement ({ dispatch, commit, getters }) {
      if (!getters.getPayloads) {
        commit('setSnackbarWarning', 'Unable to create, payload not available', { root: true })
        return
      }
      const { brokeredSettlement, metals, purchasedOutright } = getters.getPayloads
      // weird nuance of the api this is required
      brokeredSettlement.metals = []
      const res = await axios.post('/api/brokered_settlements/', brokeredSettlement)
      if (res.status !== 201) {
        commit('setSnackbarError', 'Unable to create Brokered Settlement', { root: true })
        return
      }
      const settlementId = res.data.id
      // assign this settlement to the SSL
      let promises = [
        // hotfix for the ONE TO ONE relationship [wont save going from the broker]
        axios.patch(brokeredSettlement.shipment_summary_lot, { brokeredsettlement: res.data.url })
      ]
      // Create Purchased Outright Lines
      purchasedOutright.forEach(line => {
        line.settlement_id = settlementId
        promises.push(axios.post('/api/purchased_outright/', line))
      })
      // Create Metals Lines
      metals.forEach(metal => {
        metal.brokered_settlement = res.data.url
        promises.push(axios.post('/api/brokered_settlement_metals/', metal))
      })
      await Promise.all(promises)
      commit('setSnackbarSuccess', `Brokered Settlement #${settlementId} Created`, { root: true })
      this.settlementDialog = false
      return res
    },
    async updateAndFinalizeSettlement ({ state, commit, getters }) {
      if (state.brokeredSettlement === null || !state.brokeredSettlement.url) {
        commit('setSnackbarError', 'Unable to update settlement, data not found', { root: true })
        return
      }
      if (!getters.getPayloads) {
        commit('setSnackbarWarning', 'Unable to create, payload not available', { root: true })
        return
      }
      const { brokeredSettlement, metals, purchasedOutright } = getters.getPayloads
      brokeredSettlement.status = 'FI'
      try {
        const res = await axios.patch(state.brokeredSettlement.url, brokeredSettlement)

        let promises = []
        promises.push()
        // Update Purchased Outright Lines
        purchasedOutright.forEach(line => {
          promises.push(axios.patch(`/api/purchased_outright/${line.id}/`, line))
        })
        // Update Metals Lines
        metals.forEach(metal => {
          promises.push(axios.patch(`/api/brokered_settlement_metals/${metal.id}/`, metal))
        })
        await Promise.all(promises)
        commit('setSnackbarSuccess', 'Brokered Settlement updated successfully', { root: true })
        return res
      } catch (err) {
        commit('setSnackbarError', 'Failed to update Brokered Settlement', { root: true })
        return false
      }
    }
  }
}
