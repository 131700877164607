import axios from 'axios'

export default {
  namespaced: true,
  state: {
    inventory: {},
    inventories: [],
    invLineSetups: [],
    inventoryLines: [],
    containerTypes: [
      { value: 'BOX', text: 'Box' },
      { value: 'BAG', text: 'Bag' },
      { value: 'BAR', text: 'Barrel' }
    ]
  },
  getters: {
    inventory: state => state.inventory,
    containerTypes: state => state.containerTypes,
    invLineSetups: state => state.invLineSetups
  },
  mutations: {
    setInventory (state, inventory) {
      state.inventory = inventory
    },
    setInventories (state, inventories) {
      state.inventories = inventories
    },
    setInventoryLineSetups (state, invLineSetups) {
      state.invLineSetups = invLineSetups
    },
    setInventoryLines (state, inventoryLines) {
      state.inventoryLines = inventoryLines
    }
  },
  actions: {
    /**
     * Get an inventory by its id
     * @param {Object} commit - to call store setters
     * @param {Number} id - id of the inventory to get
     */
    async fetchInventory ({ commit }, id) {
      try {
        const response = await axios.get(`/api/inventories/${id}/`)
        commit('setInventory', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get inventories', { root: true })
      }
    },
    /**
     * Get all inventories with or without filters
     * @param {Object} commit - access to store setters
     * @param {Object} params - optional filters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchInventories ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/inventories/', { params })
        commit('setInventories', response.data.results)
        return response.data.count
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to fetch all inventories', { root: true })
      }
    },
    /**
     * Get all inventories with or without filters
     * @param {Object} commit - access to store setters
     * @param {Object} params - optional filters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchInventoryLines ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/inventory_lines/', { params })
        let lines = response.data
        if (!params || params.page_size !== 0) lines = lines.results
        commit('setInventoryLines', lines)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to fetch all inventories', { root: true })
      }
    },
    /**
     * Create a new Cut Plan
     * @param {Object} commit - access to store setters
     * @param {Object} body - the inventory line to be added
     * @return {Promise<Number>}
     */
    async createCutPlan ({ commit }, body) {
      try {
        const cutRes = await axios.post('/api/cut_plans/', { notes: body.notes })
        const cpId = cutRes.data.id
        const lotPromises = body.lots.map(lot => {
          const params = { lot_id: lot.id, cut_plan_id: cpId }
          if (lot.cutPlanNotes) params.notes = lot.cutPlanNotes
          return axios.post('/api/cut_plan_lots/', { params })
        })
        await Promise.all(lotPromises)
        return cpId
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while creating cut plans', { root: true })
      }
    },
    /**
     * Fetching inventory line setup with predetermined catalog types
     * @param {Array} commit - setting inventory line setups to vuex
     */
    async fetchInventoryLineSetups ({ commit }) {
      try {
        const response = await axios.get('/api/inventory_line_setups/?page_size=0')
        commit('setInventoryLineSetups', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while getting intake setup', { root: true })
      }
    }
  }
}
