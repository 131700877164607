import axios from 'axios'

export default {
  namespaced: true,
  state: {
    // adding this for fun and to make the calender smoother
    eventCache: {},
    mapKeys: {},
    cacheKey: ''
  },
  getters: {
    // gets events from the event cache
    events: state => {
      if (!state.eventCache[state.cacheKey]) return []
      return state.eventCache[state.cacheKey]
    },
    keys: state => state.mapKeys
  },
  mutations: {
    setMapKeys (state, keys) {
      state.mapKeys = keys
    },
    clearCache (state) {
      state.eventCache = {}
    },
    addEventsToCache (state, { key, events }) {
      // written to ensure state is updated properly
      let cache = Object.assign({}, state.eventCache)
      cache[key] = events
      state.eventCache = cache
    },
    setCacheKey (state, key) {
      state.cacheKey = key
    }
  },
  actions: {
    async fetchEvents ({ commit, state }, { startDate, endDate, scope }) {
      // set the cache key
      const key = `${startDate}-${endDate}-${scope}`
      commit('setCacheKey', key)

      // Short circuit if already loaded
      if (state.eventCache[key]) return Promise.resolve()

      // fetch a cache update
      let params = { start: startDate, end: endDate }
      if (scope) params.scope = scope
      const r = await axios.get('/api/calendar/', { params })

      // Write the changes and report errors
      if (r.status === 200) {
        const events = r.data.events.map(event => {
          if (event.type === 'trip') {
            event.to = { name: 'trip', params: { id: event.id } }
          }
          return event
        })
        commit('addEventsToCache', { key, events })
        commit('setMapKeys', r.data.keys)
      } else {
        commit('setSnackbarError', 'Unable to fetch events', { root: true })
      }
      return r
    }
  }
}
