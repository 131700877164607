import factories from '../generics/factories'
const { factory, createMixin, fetchMixin, patchMixin, deleteMixin } = factories

const saleStatusOptions = [
  { text: 'Provisional', value: 'PR' },
  { text: 'Bid', value: 'BI' },
  { text: 'Confirmed', value: 'CO' }
]

const weekDayChoices = [
  { text: 'Monday', value: 'MO' },
  { text: 'Tuesday', value: 'TU' },
  { text: 'Wednesday', value: 'WE' },
  { text: 'Thursday', value: 'TH' },
  { text: 'Friday', value: 'FR' }
]

const store = factory(
  {
    entityName: 'TripSale',
    urlTemplate: id => id ? `api/trip_sales/${id}/` : `api/trip_sales/`,
    state: {
      saleStatusOptions,
      weekDayChoices
    }
  },
  createMixin,
  fetchMixin,
  patchMixin,
  deleteMixin
)

export default store
