/**
 * All routes that should be considered publicly accessible are located here
 */
const routes = [
  {
    path: '/',
    name: 'landing',
    component: () => import(/* webpackChunkName: "public-routes" */ '@/views/Landing')
  },
  {
    path: '/forbidden',
    name: '403',
    component: () => import(/* webpackChunkName: "public-routes" */ '@/views/Forbidden403')
  },
  {
    path: '/404',
    name: 'page-not-found',
    component: () => import(/* webpackChunkName: "public-routes" */ '@/views/PageNotFound')
  }
]

export default routes.map(route => {
  return { ...route, meta: { public: true } }
})
