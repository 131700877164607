import factories from '../generics/factories'

const { factory, fetchMixin, patchMixin, sendEmailMixin } = factories

const store = factory(
  {
    entityName: 'SpendingReport',
    urlTemplate: id => id ? `/api/spending_plans/${id}/` : `/api/spending_plans/`
  },
  fetchMixin,
  patchMixin,
  sendEmailMixin
)

export default store
