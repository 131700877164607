export default [
  {
    path: '/blueprints/:id/',
    name: 'blueprint',
    component: () => import(/* webpackChunkName: "expense-routes" */ '@/views/Trips/TripBlueprint'),
    meta: { permission: 'view-blueprint' },
    reverse: 'trip_blueprints-single'
  },
  {
    path: '/blueprints/',
    name: 'blueprints-list',
    component: () => import(/* webpackChunkName: "expense-routes" */ '@/views/Trips/BlueprintList'),
    meta: { permission: 'view-trips' }
  },
  {
    path: '/trips/',
    name: 'trip-list',
    component: () => import(/* webpackChunkName: "expense-routes" */ '@/views/Trips/TripList'),
    meta: { permission: 'view-trips' }
  },
  {
    path: '/trips/:id/',
    name: 'trip',
    component: () => import(/* webpackChunkName: "expense-routes" */ '@/views/Trips/TripView'),
    meta: { permission: 'view-trips' },
    reverse: 'trips-single'
  },
  {
    path: '/spending-plan-reports/',
    name: 'spending-plan-report-list',
    component: () => import(/* webpackChunkName: "expense-routes" */ '@/views/SpendingReport/SpendingReportList'),
    meta: { permission: 'view-spending-plan-reports' }
  },
  {
    path: '/spending-plan-reports/:id/',
    name: 'spending-plan-report',
    component: () => import(/* webpackChunkName: "expense-routes" */ '@/views/SpendingReport/SpendingReportView'),
    meta: { permission: 'view-spending-plan-reports' },
    reverse: 'spending_plans-single'
  }
]
