import axios from 'axios'

export default {
  namespaced: true,
  state: {
    intakes: [],
    intake: {},
    intakeSetups: [],
    receivedPurchases: []
  },
  getters: {
    receivedPurchases: state => state.receivedPurchases,
    intakes: state => state.intakes,
    intake: state => state.intake,
    intakeSetups: state => state.intakeSetups
  },
  mutations: {
    setReceivedPurchases (state, data) {
      state.receivedPurchases = data
    },
    setIntakeSetups (state, intakeSetups) {
      state.intakeSetups = intakeSetups
    }
  },
  actions: {
    // Fetches RECEIVED purchase that have COMPLETED check ins
    async fetchReceivedPurchases ({ commit }) {
      const r = await axios.get('/api/purchases/', {
        params: {
          status: 'RE',
          page_size: 0,
          check_ins__status: 'CH'
        }
      })
      commit('setReceivedPurchases', r.data)
    },
    /**
     * Fetching intake setup to populate intake creation
     * @param {Object} commit - setting intakes to vuex
     */
    async fetchIntakeSetups ({ commit }) {
      try {
        const response = await axios.get('/api/intake_setups/?page_size=0')
        commit('setIntakeSetups', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while getting intake setup', { root: true })
      }
    },
    /**
     * Create a new Intake
     * @param {Object} commit setting intakes to vuex
     * @return {Integer}
     */
    async createIntake ({ commit }, body) {
      try {
        const lines = body.lines
        const reference = body.reference
        const notes = body.notes
        // I know this is garbage but I don't want to refactor the whole section atm, RIP author
        let params = {}
        if (reference) params['reference'] = reference
        if (notes) params['notes'] = notes

        const response = await axios.post('/api/intakes/', params)
        const intakeId = response.data.id

        // setting the intake for attached purchases
        let promises = body.purchases.map(purchase =>
          axios.patch(`/api/purchases/${purchase.id}/`, { intake_id: intakeId, status: 'IN' }))

        for (let i = 0; i < lines.length; i++) {
          const payload = {
            catalog_id: lines[i].id,
            intake_id: intakeId,
            count: lines[i].value,
            name: lines[i].name,
            unit_of_measure: lines[i].units
          }
          promises.push(axios.post('/api/intake_lines/', payload))
        }
        await Promise.all(promises)
        return intakeId
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to create the intake', { root: true })
      }
    },
    // Sends the internal piece count emails
    sendEmails ({ commit }, id) {
      axios.post(`/api/intakes/${id}/send_emails/`).then(response => {
        if (response.status === 201) {
          commit('setSnackbarInfo', 'Intake emails have been sent', { root: true })
        } else {
          commit('setSnackbarError', 'Failed to send send emails', { root: true })
        }
      })
    }
  }
}
