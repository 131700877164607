<template>
  <v-autocomplete
    :value="value"
    :items="allItems"
    :loading="loading || loadingData"
    :search-input.sync="search"
    :item-text="itemText"
    :item-value="itemValue"
    :return-object="returnObject || multiple"
    :label="label"
    :error-messages="errorMessages"
    :disabled="disabled"
    :multiple="multiple"
    :prepend-inner-icon="prependInnerIcon || 'mdi-magnify'"
    :append-icon="appendIcon"
    :menu-props="menuProps"
    :hide-selected="hideSelected"
    :hide-details="hideDetails"
    :solo="solo"
    :dense="dense"
    flat
    clearable
    @click:clear="clearSearch"
    @change="$emit('change')"
    @input="updateValue"
  >
    <template v-slot:selection="data">
      <v-chip v-if="multiple">
        {{ getText(data) }}
      </v-chip>
      <div v-else>
        {{ getText(data) }}
      </div>
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title v-if="loading || loadingData">
          Searching ...
        </v-list-item-title>
        <v-list-item-title v-else-if="entities.length < 1">
          No <strong> {{ namePlural }} </strong> Found
        </v-list-item-title>
        <v-list-item-title v-else>
          Search <strong>{{ namePlural }}</strong>
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:append-item>
      <infinite-loading
        v-if="entities.length >= itemsPerPage"
        @infinite="loadMore"
        class="mt-4"
        ref="InfiniteLoading"
        spinner="waveDots"
        :distance="distance"
      >
        <template v-slot:no-more>
          No more {{ namePlural }} found
        </template>
        <template v-slot:no-results>
          No {{ namePlural }} found
        </template>
      </infinite-loading>
    </template>
    <template v-slot:append-outer>
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script>
import { VAutocomplete } from 'vuetify/lib'
import PaginatedEntityMixin from '@/mixins/PaginatedEntityMixin'

export default {
  name: 'PaginatedAutocomplete',
  extends: VAutocomplete,
  mixins: [PaginatedEntityMixin],
  props: {
    fetchImmediately: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    /**
     * Add additional text if the user desires additional text and their is text in the item
     * @param {Object} data
     */
    getText (data) {
      let str, moreText
      if (!data) return
      if (data.hasOwnProperty('item')) {
        if (this.itemText instanceof Function) {
          str = this.itemText(data.item)
        } else {
          str = data.item[this.itemText]
        }
        moreText = data.item[this.addedText]
        if (this.addedText && moreText && moreText.trim() !== '') str += ' - ' + moreText
      } else {
        if (this.itemText instanceof Function) {
          str = this.itemText(data)
        } else {
          str = data[this.itemText]
        }
        moreText = data[this.addedText]
        if (this.addedText && moreText && moreText.trim() !== '') str += ' - ' + moreText
      }
      return str
    }
  },
  async mounted () {
    if (this.fetchImmediately) await this.loadInitialData('', false)
    const val = this.value
    if ((val && val !== '' && val !== undefined && !Array.isArray(val)) || (Array.isArray(val) && val.length > 0)) {
      this.allItems.unshift(val)
    }
  }
}
</script>
