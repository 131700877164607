import axios from 'axios'

export default {
  namespaced: true,
  state: {
    checkInSkids: []
  },
  getters: {
    checkInSkids: state => {
      return state.checkInSkids.sort((a, b) => (a.id > b.id) ? 1 : -1)
    }
  },
  mutations: {
    setCheckInSkids (state, checkInSkids) {
      state.checkInSkids = checkInSkids
    }
  },
  actions: {
    /**
     * Fetch all checkInSkids with detailed serializer given a set of params
     * @param {Object} commit setting intakes to vuex
     * @param {Object || Null} params - params to search by or null for all check ins
     */
    async fetchCheckInSkidsDetailed ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/check_in_skids/detailed_list/', { params })
        commit('setCheckInSkids', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get check ins', { root: true })
      }
    },
    /**
     * Fetch all checkInSkids given a set of params
     * @param {Object} commit setting intakes to vuex
     * @param {Object || Null} params - params to search by or null for all check ins
     */
    async fetchCheckInSkids ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/check_in_skids/', { params })
        commit('setCheckInSkids', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get check ins', { root: true })
      }
    },
    /**
     * Fetch all checkInSkids given a set of params
     * @param {Object} commit setting intakes to vuex
     * @param {Array} skids - array of check in skids to be created
     */
    async createCheckInSkids ({ commit }, skids) {
      try {
        const promises = skids.map((skid, index) => {
          const checkInWords = skid.check_in.split('/').filter(v => v && v.trim !== '')
          const checkInId = checkInWords[checkInWords.length - 1]
          const count = (index + 1) + ''
          const name = `${checkInId}-${count.padStart(3, '0')}`
          return axios.post('/api/check_in_skids/', { ...skid, name })
        })
        await Promise.all(promises)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred creating check in skids', { root: true })
      }
    },
    /**
     * Generate the check in skids based on the total pieces and check in
     * @param {Object} commit setting intakes to vuex
     * @param {Object} - total pieces and check in
     */
    async generateCheckInSkid ({ commit }, { checkIn, totalPieces }) {
      try {
        const res = await axios.get('/api/check_in_skids/', { params: { check_in_id: checkIn.id, page_size: 0 } })
        const skids = res.data

        const totalUsedPieces = skids.reduce((accu, skid) => accu + skid.expected_pieces, 0)
        const expectedPieces = totalPieces - totalUsedPieces

        // const skidNum = (skids.length + 1) + ''
        // const name = `${checkIn.id}-${skidNum.padStart(3, '0')}`
        const expectedWeight = (expectedPieces * 10) + 80 // ~10 lbs per cat and 80 lbs tare (skid weight)
        await axios.post('/api/check_in_skids/', {
          check_in: checkIn.url,
          expected_weight: expectedWeight,
          expected_pieces: expectedPieces,
          name: ''
        })
      } catch (err) {
        commit('setSnackbarError', 'An error occurred generating check in skids', { root: true })
      }
    },
    /**
     * Create check in skids when receiving (need both inbound and check in skids)
     * @param {Object} commit setting intakes to vuex
     * @param {Object} body - skids to be created and id of transit
     */
    // async createSkidsReceiving ({ commit }, body) {
    //   try {
    //     const existingSkids = body.existingSkids
    //     let nextSkidNum = existingSkids.reduce((accu, skid) => {
    //       if (skid.name && skid.name !== undefined) accu = parseInt(skid.name.split('-')[1])
    //       return accu
    //     }, 0) + 1
    //     const newSkids = body.newSkids
    //     const transitId = body.id
    //     const promises = newSkids.map(skid => {
    //       const checkInId = skid.checkIn.id
    //       const name = `${checkInId}-${(nextSkidNum + '').padStart(3, '0')}`
    //       nextSkidNum += 1
    //       const params = {
    //         expected_weight: skid.weight,
    //         weight: skid.weight,
    //         origin_location_id: skid.origin,
    //         location_id: skid.origin,
    //         check_in: skid.checkIn.url,
    //         name
    //       }
    //       return axios.post('/api/check_in_skids/', params)
    //     })
    //     const responses = await Promise.all(promises)
    //     const inboundSkidPromises = responses.map(res => {
    //       const checkInSkidId = res.data.id
    //       const params = {
    //         check_in_skid_id: checkInSkidId,
    //         inbound_transit_id: transitId,
    //         received_weight: res.data.weight,
    //         is_received: true
    //       }
    //       return axios.post('/api/inbound_skids/', params)
    //     })
    //     await Promise.all(inboundSkidPromises)
    //   } catch (err) {
    //     commit('setSnackbarError', 'An error occurred creating check in skids', { root: true })
    //   }
    // },
    /**
     * Fetch all checkInSkids given a set of params
     * @param {Object} commit setting intakes to vuex
     * @param {Object} skid
     */
    async updateCheckInSkid ({ commit }, skid) {
      try {
        await axios.patch(skid.url, skid)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred deleting the check in skid', { root: true })
      }
    },
    /**
     * Fetch all checkInSkids given a set of params
     * @param {Object} commit setting intakes to vuex
     * @param {Array} skids
     */
    // async updateCheckInSkids ({ commit }, skids) {
    //   try {
    //     const promises = skids.map(skid => axios.patch(skid.url, { gps_number: skid.gps_number }))
    //     await Promise.all(promises)
    //   } catch (err) {
    //     commit('setSnackbarError', 'An error occurred deleting the check in skid', { root: true })
    //   }
    // },
    /**
     * Fetch all checkInSkids given a set of params
     * @param {Object} commit setting intakes to vuex
     * @param {Number} id - id of the skid to delete
     */
    async deleteCheckInSkid ({ commit }, id) {
      try {
        await axios.delete(`/api/check_in_skids/${id}/`)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred deleting the check in skid', { root: true })
      }
    }
  }
}
