import factories from '../generics/factories'
const { factory, createMixin, fetchMixin, patchMixin, deleteMixin } = factories

const store = factory(
  {
    entityName: 'ImageGroup',
    urlTemplate: id => id ? `api/converter_image_groups/${id}/` : `api/converter_image_groups/`
  },
  createMixin,
  fetchMixin,
  patchMixin,
  deleteMixin
)

export default store
