export default [
  {
    path: '/catalog/:id',
    name: 'catalog',
    component: () => import(/* webpackChunkName: "catalog-routes" */ '@/views/Catalog/Catalog'),
    reverse: 'catalogs-single'
  },
  {
    path: '/photo-group/:id',
    name: 'photo-group',
    component: () => import(/* webpackChunkName: "catalog-routes" */ '@/views/PhotoGroup/PhotoGroup'),
    meta: { permission: 'view-photo-groups' },
    reverse: 'converter_image_groups-single'
  },
  {
    path: '/catalog-list',
    component: () => import(/* webpackChunkName: "catalog-routes" */ '@/views/Catalog/CatalogParent'),
    children: [
      {
        path: '/create-catalog',
        name: 'create-catalog',
        component: () => import(/* webpackChunkName: "catalog-routes" */ '@/views/Catalog/CreateCatalog'),
        meta: { permission: 'create-catalogs' }
      },
      {
        path: '/catalog-vehicle-list',
        name: 'catalog-vehicle-list',
        component: () => import(/* webpackChunkName: "catalog-routes" */ '@/views/CatalogVehicle/CatalogVehicleList'),
        meta: { permission: 'catalog-vehicle-list' }
      },
      {
        path: '/photo-group-list',
        name: 'photo-group-list',
        component: () => import(/* webpackChunkName: "catalog-routes" */ '@/views/PhotoGroup/PhotoGroupList'),
        meta: { permission: 'photo-group-list' }
      },
      {
        path: '/catalog-pricing',
        name: 'catalog-pricing',
        component: () => import(/* webpackChunkName: "catalog-routes" */ '@/views/Catalog/CatalogPricing'),
        meta: { permission: 'view-pricing' }
      },
      {
        path: '',
        name: 'catalog-list',
        component: () => import(/* webpackChunkName: "catalog-routes" */ '@/views/Catalog/CatalogList'),
        meta: { permission: 'view-catalogs' }
      }
    ]
  },
  {
    path: '/ppm-calculation/:id',
    name: 'ppm-calculation',
    component: () => import(/* webpackChunkName: "catalog-routes" */ '@/views/PpmCalculation/PpmCalc'),
    reverse: 'ppm_calculations-single'
  },
  {
    path: '/ppm-calculation-list',
    component: () => import(/* webpackChunkName: "catalog-routes" */ '@/views/PpmCalculation/PpmCalcParent'),
    children: [
      {
        path: '',
        name: 'ppm-calculation-list',
        component: () => import(/* webpackChunkName: "catalog-routes" */ '@/views/PpmCalculation/PpmCalculationList'),
        meta: { permission: 'view-ppm' }
      },
      {
        path: '/create-ppm-calculation',
        name: 'create-ppm-calculation',
        component: () => import(/* webpackChunkName: "catalog-routes" */ '@/views/PpmCalculation/CreatePpmCalculation'),
        meta: { permission: 'create-ppm' }
      }
    ]
  }
]
