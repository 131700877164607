import actions from './auth/actions'
import Vue from 'vue'

/**
 * Leverages a cache so that permissions are only checked once per app session
 * Improves performance by dramatically reducing permission checks
 * @param action
 * @param args
 * @returns {*}
 */
function tryAction (action, args) {
  if (action in actions) return actions[action](...args)

  let permissions = []
  // set up the scope and permissions
  if (Vue.prototype.$auth.user?.['client_permissions']) {
    permissions = Vue.prototype.$auth.user['client_permissions']
  }
  return permissions.includes(action)
}

// Actions methods work as getters. They can be provided as an array or singleton. Permission granted if ANY return true
export default {
  getters: {
    // returns a function that can resolve a action name
    can: (...args) => (...actionSet) => {
      // Loop though each action
      for (const action of actionSet) {
        if (tryAction(action, args)) {
          Vue.prototype.$logger.info(`PERMISSION Pass > ${actionSet.join(', ')}`)
          return true
        }
      }
      Vue.prototype.$logger.info(`PERMISSION Fail > ${actionSet.join(', ')}`)
      return false
    }
  }
}
