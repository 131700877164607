import axios from 'axios'

export default {
  namespaced: true,
  state: {
    yards: [],
    yard: {},
    brokerageTerms: {},
    yardDays: [
      { value: 'MO', text: 'Monday', order: 0 },
      { value: 'TU', text: 'Tuesday', order: 1 },
      { value: 'WE', text: 'Wednesday', order: 2 },
      { value: 'TH', text: 'Thursday', order: 3 },
      { value: 'FR', text: 'Friday', order: 4 },
      { value: 'SA', text: 'Saturday', order: 5 },
      { value: 'SU', text: 'Sunday', order: 6 }
    ],
    sellingMethods: [
      { text: 'By Piece', value: 'PC' },
      { text: 'By Assay', value: 'AS' },
      { text: 'Average', value: 'AV' },
      { text: 'Straight Through', value: 'ST' }
    ],
    purchasingMethods: [
      { text: 'No', value: 'NO' },
      { text: 'Yes, by Grade', value: 'GR' },
      { text: 'Yes, by Assay', value: 'AS' }
    ],
    averageCarTypes: [
      { text: 'Mostly Trucks', value: 'TR' },
      { text: 'Mostly Domestic', value: 'DO' },
      { text: 'Mostly Foreign', value: 'FR' }
    ],
    yardMonths: [
      { text: 'January', value: 1 },
      { text: 'February', value: 2 },
      { text: 'March', value: 3 },
      { text: 'April', value: 4 },
      { text: 'May', value: 5 },
      { text: 'June', value: 6 },
      { text: 'July', value: 7 },
      { text: 'August', value: 8 },
      { text: 'September', value: 9 },
      { text: 'October', value: 10 },
      { text: 'November', value: 11 },
      { text: 'December', value: 12 }
    ],
    monthQuarters: [
      { text: 'Week One', value: '1' },
      { text: 'Week Two', value: '2' },
      { text: 'Week Three', value: '3' },
      { text: 'Week Four', value: '4' }
    ]
  },
  getters: {
    yards: state => state.yards,
    yard: state => state.yard,
    yardDays: state => state.yardDays,
    brokerageTerms: state => state.brokerageTerms
  },
  mutations: {
    setYards (state, yards) {
      state.yards = yards
    },
    appendYards (state, yards) {
      state.yards = [...state.yards, ...yards]
    },
    setYard (state, yard) {
      state.yard = yard
    },
    setTerms (state, brokerageTerms) {
      state.brokerageTerms = brokerageTerms
    }
  },
  actions: {
    /**
     * Get all yards
     * @param {Object} commit - to call store setters
     * @param {Object} params - optional filters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchYards ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/yards/', { params })
        if (params && params.page && params.page > 1) commit('appendYards', response.data.results)
        else commit('setYards', response.data.results)
        return response.data.count
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get yards', { root: true })
      }
    },
    /**
     * Get a yard by its id
     * @param {Object} commit - to call store setters
     * @param {Number} id - id of the yard to get
     */
    async fetchYard ({ commit }, id) {
      try {
        const response = await axios.get(`/api/yards/${id}/`)
        commit('setYard', response.data)
      } catch (err) {
        commit('setSnackbarError', `An error occurred while trying to get yard with id: ${id}`, { root: true })
      }
    },
    async fetchYardByUrl ({ commit }, url) {
      try {
        const response = await axios.get(url)
        commit('setYard', response.data)
        return response.data
      } catch (err) {
        commit('setSnackbarError', 'An error occurred loading the terms', { root: true })
      }
    },
    /**
     * Create a new yard given an object
     * @param {Object} dispatch - to call other store actions
     * @param {String} name
     */
    async createYard ({ dispatch, commit }, body) {
      try {
        if (body.region_id && typeof body.region_id === 'object') {
          const res = await axios.get('/api/regions/', { params: { name: body.region_id.name } })
          if (res.data.results.length < 1) delete body.region_id
          else body.region_id = res.data.results[0].id
        }
        const res = await axios.post('/api/yards/', body)
        return res.data
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to create the yard', { root: true })
      }
    },
    /**
     * Updates yard
     * @param {Object} store - dispatch: store actions, commit: store setters
     * @param {Object} body - id of the yard and params to be updated
     */
    async updateYard ({ dispatch, commit }, body) {
      try {
        const res = await axios.patch(`/api/yards/${body.id}/`, body.params)
        return res.data.id
      } catch (err) {
        commit('setSnackbarError', `An error occurred trying to update yard ${body.id}`, { root: true })
      }
    },
    /**
     * @param {Object} commit - access to store setters
     * @param {Object} body - id of the yard and an array of hours to be created
     */
    async addYardHours ({ commit }, body) {
      try {
        if (body.ww) {
          await axios.post('/api/yard_hours/', {
            open_time: body.open_time,
            close_time: body.close_time,
            is_shipping: true,
            is_closed: false,
            yard_id: body.yardId
          })
        } else {
          const promises = body.week.map(day => {
            if (day.is_closed) {
              day.open_time = null
              day.close_time = null
            }
            const params = {
              yard_id: body.yardId,
              day: day.day,
              open_time: day.open_time,
              close_time: day.close_time,
              is_shipping: day.is_shipping,
              is_closed: day.is_closed
            }
            return axios.post('/api/yard_hours/', params)
          })
          await Promise.all(promises)
        }
      } catch (err) {
        commit('setSnackbarError', `An error occurred adding yard hours to yard ${body.yardId}`, { root: true })
      }
    },
    /**
     * Get a brokerage term by its url
     * @param {Object} commit - access to store setters
     * @param {String} url
     */
    async fetchBrokerageTerms ({ commit }, url) {
      try {
        const response = await axios.get(url)
        commit('setTerms', response.data)
        return response.data
      } catch (err) {
        commit('setSnackbarError', 'An error occurred loading the terms', { root: true })
      }
    },
    /**
     * Add brokerage terms to a yard
     * @param {Object} commit - access to store setters
     * @param {Object} params
     */
    async addBrokerageTerms ({ commit }, params) {
      try {
        const res = await axios.post('/api/brokerage_terms/', params)
        const updatedYard = await axios.patch(res.data.yard, { brokerageterm: res.data.url })
        commit('setTerms', res.data)
        commit('setYard', updatedYard.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred adding yard terms', { root: true })
      }
    },
    /**
     * Update a brokerage term for a given yard
     * @param {Object} commit - access to store setters
     * @param {Object} params
     */
    async updateBrokerageTerms ({ commit }, params) {
      try {
        const res = await axios.patch(`/api/brokerage_terms/${params.id}/`, params)
        const updatedYard = await axios.patch(res.data.yard, { brokerageterm: res.data.url })
        commit('setTerms', res.data)
        commit('setYard', updatedYard.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred adding yard terms', { root: true })
      }
    },
    /**
     * Determines how to update the hours based on what currently exists and the user input
     * @param {Object} commit - access to store setters
     * @param {Object} dispatch - access to store actions
     * @param {Object} body - array of yard hours or a work week
     */
    async updateYardHours ({ commit, dispatch }, body) {
      try {
        const existingRes = await axios.get('/api/yard_hours/', { params: { yard_id: body.yardId } })
        const existingHours = existingRes.data.results.map(hour => hour.id)
        const wwId = body.wwId
        const week = body.week
        const changedFromWWToWeek = body.hasOwnProperty('wwId') && existingHours.length > 1
        const changedFromWeekToWW = body.hasOwnProperty('week') && existingHours.length !== 7
        // User has changed from individual hours to a workweek entry or visa versa
        if (changedFromWWToWeek || changedFromWeekToWW) {
          await dispatch('removeYardHours', existingHours)
          await dispatch('addYardHours', body)
          return
        }
        // User wants to update the existing hours
        if (wwId) {
          await axios.patch(`/api/yard_hours/${wwId}/`, { open_time: body.open_time, close_time: body.close_time })
        } else {
          const promises = week.map(day => {
            if (day.is_closed) {
              day.open_time = null
              day.close_time = null
            }
            const params = {
              day: day.day,
              open_time: day.open_time,
              close_time: day.close_time,
              is_shipping: day.is_shipping,
              is_closed: day.is_closed
            }
            return axios.patch(`/api/yard_hours/${day.id}/`, params)
          })
          await Promise.all(promises)
        }
      } catch (err) {
        commit('setSnackbarError', 'Failed to update yard hours', { root: true })
      }
    },
    /**
     * Remove yard hours
     * @param {Object} commit - access to store setters
     * @param {ARraay} yardIds
     */
    async removeYardHours ({ commit }, yardIds) {
      try {
        if (!yardIds || !Array.isArray(yardIds)) return
        const promises = yardIds.map(id => axios.delete(`/api/yard_hours/${id}`))
        await Promise.all(promises)
      } catch (err) {
        commit('setSnackbarError', 'Failed to remove yard hours', { root: true })
      }
    }
  }
}
