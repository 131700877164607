import axios from 'axios'

export default {
  namespaced: true,
  state: {
    converters: [],
    converterCount: 0,
    converter: {},
    converterDetails: [],
    converterAssays: []
  },
  getters: {
    converters: state => state.converters,
    converter: state => state.converter,
    converterCount: state => state.converterCount,
    converterDetails: state => state.converterDetails,
    converterAssays: state => state.converterAssays
  },
  mutations: {
    setConverters (state, converters) {
      state.converters = converters
    },
    setConverter (state, converter) {
      state.converter = converter
    },
    setConverterCount (state, converterCount) {
      state.converterCount = converterCount
    },
    setConverterDetails (state, converterDetails) {
      state.converterDetails = converterDetails
    },
    setConverterAssays (state, converterAssays) {
      state.converterAssays = converterAssays
    },
    appendConverterAssays (state, converterAssays) {
      state.converterAssays = [...state.converterAssays, converterAssays]
    }
  },
  actions: {
    /**
     * Gets all photobooth converters for a given set of params
     * @param {commit} commit sets converter objects to store
     * @param {Object} params - optional filters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchConverters ({ commit }, params = null) {
      try {
        let response
        if (params) response = await axios.get('/api/photobooth_converters/', { params })
        else response = await axios.get('/api/photobooth_converters/')
        commit('setConverters', response.data.results)
        commit('setConverterCount', response.data.count)
        return response.data.count
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get the Photobooth Converters', { root: true })
      }
    },
    /**
     * Gets a photobooth converter by its id
     * @param {commit} commit sets converter objects to store
     * @param {Number} id - id of photobooth converter
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchConverter ({ commit }, id) {
      try {
        const response = await axios.get(`/api/photobooth_converters/${id}/`)
        const imageSet = response.data.image_set
        response.data.images = []
        if (imageSet) {
          const params = { group: imageSet.id, limit: 0 }
          const imageRes = await axios.get('/api/converter_images/', { params })
          response.data.images = imageRes.data.results
        }
        commit('setConverter', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get the Photobooth Converters', { root: true })
      }
    },
    /**
     * Gets all details for a given converter
     * @param {commit} commit sets converter objects to store
     * @param {Number} id - id of photobooth converter
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchConverterDetails ({ commit }, id) {
      try {
        const params = { photobooth_converter__id: id }
        const response = await axios.get('/api/photobooth_details/', { params })
        commit('setConverterDetails', response.data.results)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get the Photobooth Details', { root: true })
      }
    },
    /**
     * Gets all converter assays for a given converter
     * @param {commit} commit sets converter objects to store
     * @param {Number} id - id of photobooth converter
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchConverterAssays ({ commit }, id) {
      try {
        const params = { photobooth_converter__id: id }
        const response = await axios.get('/api/photobooth_assays/', { params })
        const assays = response.data.results.map(assay => {
          assay.include = true
          return assay
        })
        commit('setConverterAssays', assays)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get the Photobooth Details', { root: true })
      }
    },
    /**
     * Add an assay to the photobooth assay model
     * @param {commit} commit sets converter objects to store
     * @param {Object} assay
     */
    async addAssay ({ commit }, assay) {
      try {
        const response = await axios.post('/api/photobooth_assays/', assay)
        commit('appendConverterAssays', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get the Photobooth Details', { root: true })
      }
    },
    /**
     * Delete the given assay
     * @param {commit} commit sets converter objects to store
     * @param {Number} id
     */
    async deleteAssay ({ commit }, id) {
      try {
        await axios.delete(`/api/photobooth_assays/${id}/`)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to delete the assay', { root: true })
      }
    },
    /**
     * Export to track kat
     * @param {commit} commit sets converter objects to store
     * @param {dispatch} dispatch call to other store actions
     * @param {Object} params
     */
    async exportToTrackkat ({ commit, dispatch }, params) {
      try {
        let converter = params.converter
        let catalog
        if (params.createNew) {
          catalog = await dispatch('catalogStore/createCatalog', params.catalog, { root: true })
          params.converter.catalog = catalog.url
          converter = await dispatch('converterStore/createConverter', params, { root: true })
        } else {
          const response = await axios.get('/api/catalogs/', { converter: converter.id })
          catalog = response.data.results[0]
        }
        const assays = params.assays.map(assay => {
          assay.converter_id = converter.id
          return assay
        })
        await dispatch('catalogStore/addAssays', assays, { root: true })
        await dispatch('updateConverter', params.photobooth_converter)
        return catalog.id
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to export the photobooth entry', { root: true })
      }
    },
    /**
     * Update the given assay
     * @param {commit} commit sets converter objects to store
     * @param {Object} assay
     */
    async updateAssay ({ commit }, assay) {
      try {
        await axios.patch(`/api/photobooth_assays/${assay.id}/`, assay)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to upate the assay', { root: true })
      }
    },
    /**
     * Update the given converter
     * @param {commit} commit sets converter objects to store
     * @param {Object} converter
     */
    async updateConverter ({ commit }, converter) {
      try {
        await axios.patch(`/api/photobooth_converters/${converter.id}/`, converter)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to upate the photobooth converter', { root: true })
      }
    }
  }
}
