/**
 * Factories can be used to quickly create new generic vuex stores.
 * These stores are namespaced by default and can be easily lazy loaded into a component.
 * Allowing the the shrinkage of the vuex store and improvement in implementation
 *
 * provide factory with a config and additional mixins.
 * const store = factory(
 * {
 *  entityName: 'report', // this will be used as the base to create methods like getReport or fetchReport
 *  urlTemplate: id => `api/lots/${id}/finance/`// This call back allows for flexible URL configurations
 * },
 *  fetchMixin
 * )
 *
 * Further you can add additional methods to the store
 */

import Vue from 'vue'
import axios from 'axios'

/**
 * Create a capitalized string to use for production methods
 * @param {string} name
 * @return {string}
 */
function capitalizeName (name) {
  return name.charAt(0).toUpperCase() + name.slice(1)
}

/**
 * Entry point for creating a new store using generics
 * @param {object} config
 * @param {string} config.entityName
 * @param {string} config.urlTemplate
 * @param {?object} config.state allows the addition of new state elements
 * @param {function} mixins
 * @return {Vuex.Store}
 */
function factory (config, ...mixins) {
  const store = {
    namespaced: true,
    state: () => Object.assign({
      items: {}
    }, config.state),
    getters: {},
    mutations: {
      setEntity (state, { id, item }) {
        Vue.set(state.items, id, item)
      },
      removeEntity (state, id) {
        Vue.delete(state.items, id)
      }
    },
    actions: {}
  }

  store.getters['get' + capitalizeName(config.entityName)] = function (state) {
    return (key) => {
      if (state.items.hasOwnProperty(key)) {
        return state.items[key]
      } else {
        return null
      }
    }
  }
  mixins.forEach(mixin => mixin(store, config))
  return store
}

/**
 * Fetch Mixin for use with a factory
 * @param {Vuex.Store} store
 * @param {string} entityName
 * @param {function} urlTemplate
 */
function createMixin (store, { entityName, urlTemplate }) {
  store.actions['create' + capitalizeName(entityName)] = function ({ commit }, payload) {
    const prom = axios.post(urlTemplate(), payload)
    prom.then(response => {
      if (response.status === 201) {
        commit('setEntity', { id: response.data.id, item: response.data })
      }
    }).catch(() => {
      commit('setSnackbarError', 'Create Failed', { root: true })
    })
    return prom
  }
}

/**
 * Fetch Mixin for use with a factory
 * @param {Vuex.Store} store
 * @param {string} entityName
 * @param {function} urlTemplate
 */
function fetchMixin (store, { entityName, urlTemplate }) {
  store.actions['fetch' + capitalizeName(entityName)] = function ({ commit }, values) {
    let id, config
    if (typeof values === 'object') {
      if (values.id) id = values.id
      if (values.config) config = values.config
    } else {
      id = values
    }
    const prom = axios.get(urlTemplate(id), config)
    prom.then(response => {
      if (response.status === 200) {
        commit('setEntity', { id: id, item: response.data })
      }
    }).catch(() => {
      commit('setSnackbarError', 'Fetch Failed', { root: true })
    })
    return prom
  }
}

/**
 * Fetch Mixin for use with a factory
 * @param {Vuex.Store} store
 * @param {string} entityName
 * @param {function} urlTemplate
 */
function patchMixin (store, { entityName, urlTemplate }) {
  store.actions['patch' + capitalizeName(entityName)] = function ({ commit }, { id, payload }) {
    const prom = axios.patch(urlTemplate(id), payload)
    prom.then(response => {
      if (response.status === 200) {
        commit('setEntity', { id: id, item: response.data })
      }
    }).catch(() => {
      commit('setSnackbarError', 'Update Failed', { root: true })
    })
    return prom
  }
}

/**
 * Delete an entry from a list of entries
 * @param store
 * @param entityName
 * @param urlTemplate
 */
function deleteMixin (store, { entityName, urlTemplate }) {
  store.actions['delete' + capitalizeName(entityName)] = function ({ commit }, id) {
    const prom = axios.delete(urlTemplate(id))
    prom.then(response => {
      if (response.status === 204) {
        commit('removeEntity', id)
      }
    }).catch(() => {
      commit('setSnackbarError', 'Delete Failed', { root: true })
    })
    return prom
  }
}

/**
 * Adds dispatchEmail(id)
 * @param store
 * @param entityName
 * @param urlTemplate
 */
function sendEmailMixin (store, { entityName, urlTemplate }) {
  store.actions['dispatch' + capitalizeName(entityName) + 'Email'] = function ({ commit }, id) {
    return axios.post(urlTemplate(id) + 'send_emails/').catch(() => {
      commit('setSnackbarError', 'Email Send Failed', { root: true })
    })
  }
}

export default {
  capitalizeName,
  factory,
  createMixin,
  fetchMixin,
  patchMixin,
  deleteMixin,
  sendEmailMixin
}

/*
LAZY LOADING STORES IN VUE COMPONENTS
  // other component logic
  mounted () {
    this.$store.registerModule('admin', adminModule)
  },
  beforeDestroy () {
   this.$store.unregisterModule('admin')
  }
 */
