<!--
Simple component that provides an external link
You can use the slot to wrap anything you would like in the link
accepts an optional LABEL
-->
<template>
  <span @click="openInNew">
    <slot>
      <v-btn
        v-if="label"
        text
        small
      >
        {{ label }}
        <v-icon small>
          mdi-open-in-new
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        icon
      >
        <v-icon>
          {{ icon }}
        </v-icon>
      </v-btn>
    </slot>
  </span>
</template>

<script>
export default {
  name: 'ExternalLink',
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'mdi-open-in-new'
    }
  },
  methods: {
    openInNew () {
      window.open(this.value, '_blank')
    }
  }
}
</script>
