<template>
  <div>
    <slot
      name="message"
      v-if="!authorized"
    >
      <h3 class="text-center font-italic">
        {{ message || 'Not Authorized' }}
      </h3>
      <br>
      <h4
        class="text-center"
        v-if="displayRouteDashboard"
      >
        <router-link :to="route || { name: 'dashboard' }">
          {{ routeMessage || 'Click here to go to the Dashboard' }}
        </router-link>
      </h4>
    </slot>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: 'UnauthorizedMessage',
  props: {
    message: { type: String, required: false, default: null },
    authorized: { type: Boolean, required: true },
    displayRouteDashboard: { type: Boolean, required: false, default: true },
    route: { type: Object, required: false, default: null },
    routeMessage: { type: String, required: false, default: null }
  }
}
</script>
