import axios from 'axios'

function getLocationStatus (item) {
  if (!['Houston', 'Atlanta', 'Phoenix'].includes(item.current_location.name) && item.transit_destination) {
    if (item.transit_destination.name !== 'Phoenix') {
      return item.transit_destination.name
    } else {
      return 'In Transit PHX'
    }
  } else {
    if (['Houston', 'Atlanta'].includes(item.current_location.name)) {
      return item.current_location.name
    } else {
      return item.purchase.intake ? 'PHX/Intook' : item.current_location.name
    }
  }
}

export default {
  namespaced: true,
  state: {
    lotType: { text: 'All Types', value: null },
    purchases: [],
    currentLocations: [],
    checkIns: [],
    purchaseStatus: { text: 'All Statuses', value: null },
    rowClasses: [
      { text: 'Phoenix', value: 'style-1', itemClass: 'background-color: #B4C6E7; color: #000000;' },
      { text: 'PHX/Intook', value: 'style-2', itemClass: 'background-color: #A2B2D0; color: #000000;' },
      { text: 'Houston', value: 'style-3', itemClass: 'background-color: #A5A5A5; color: #000000;' },
      { text: 'Atlanta', value: 'style-4', itemClass: 'background-color: #F8CBAD; color: #000000;' },
      { text: 'In Transit PHX', value: 'style-5', itemClass: 'background-color: #FFFF00; color: #000000;' },
      { text: 'FTL', value: 'style-6', itemClass: 'background-color: #70AD47; color: #000000;' }
    ]
  },
  getters: {
    getLotType: state => state.lotType,
    getPurchases: state => state.purchases,
    checkIns: state => {
      let checkIns = state.checkIns
      if (state.lotType && state.lotType.value) {
        checkIns = checkIns.filter(ci => ci.purchase.designation === state.lotType.value)
      }
      if (state.division && state.division.id) {
        checkIns = checkIns.filter(ci => ci.purchase.division.id === state.division.id)
      }
      if (state.location && state.location.id) {
        checkIns = checkIns.filter(ci => ci.yard.id === state.location.id)
      }
      if (state.purchaseStatus && state.purchaseStatus.value) {
        checkIns = checkIns.filter(ci => ci.purchase.status === state.purchaseStatus.value)
      }
      return checkIns
    },
    // Returns array of yards for filtering
    getCurrLocation: state => {
      let currentLocations = []
      if (state.checkIns) {
        currentLocations = state.checkIns.reduce((accu, ci) => {
          if (ci.yard && !(accu.includes(ci.yard.name))) accu.push({ text: ci.yard.name, id: ci.yard.id })
          return accu
        }, [])
      }
      return currentLocations
    }
  },
  mutations: {
    setPurchases (state, purchases) {
      state.purchases = purchases
    },
    selectLotType (state, lotType) {
      state.lotType = lotType
    },
    setCheckIns (state, checkIns) {
      state.checkIns = checkIns
    },
    selectStatus (state, purchaseStatus) {
      state.purchaseStatus = purchaseStatus
    }
  },
  actions: {
    /**
     * Get all checkins that don't either don't have a purchase lot or the lots status is Open, cut, cutting
     * Adds only unique checkins to the checkin array to be displayed in piece counts table
     * @param {commit} commit sets purchase objects to store
     */
    async fetchPieceCount ({ commit }, params) {
      if (!params) params = {}
      try {
        let response = await axios.get('/api/check_ins/piece_count/', { params })
        let purchaseCheckIns = []
        const map = new Map()
        for (let item of response.data) {
          item['locationStatus'] = getLocationStatus(item)
          if (item && !map.has(item.id)) {
            map.set(item.id, true)
            purchaseCheckIns.push(item)
          }
        }
        commit('setCheckIns', purchaseCheckIns)
      } catch (err) {
        // commit('setSnackbarError', 'An error occurred trying to get the purchase list', { root: true })
      }
    }
  }
}
