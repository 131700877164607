import moment from 'moment'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    // PROPERTIES
    isLocked: true,
    lotName: 'N/A',
    lotId: null,
    yardId: null,
    shipDate: null,
    convertersProcessed: 0,
    mixedCatalystWeight: 0,
    clientValueDate: null,
    purchasedOutright: [
      // { materialType: 'AM', quantity: 2, price: 100, totalPrice: 200, paid: true },
      // { materialType: 'IF', quantity: 2, price: 100, totalPrice: 200, paid: true },
      // { materialType: 'DF', quantity: 2, price: 100, totalPrice: 200, paid: true },
      // { materialType: 'DP', quantity: 2, price: 100, totalPrice: 200, paid: true },
      // { materialType: 'D3', quantity: 2, price: 100, totalPrice: 200, paid: true }
    ],
    allocations: [],
    // Ounces available for the lot to allocate ( Total lot yield )
    availablePt: 0,
    availablePd: 0,
    availableRh: 0,
    // FEES
    assayFee: 0,
    shreddingFee: 0,
    brokerageFee: 0,
    refiningFee: 0,
    processingFee: 0,
    handlingFee: 0,
    freightCharge: 0,
    netOtherFees: 0,
    consolidateFees: false,
    // Finance / Amount Due
    expectedPaymentDate: null,
    interestPercent: 0,
    depositPercent: 0,

    // ---NON Advanced MetaData---
    // Hedges Designated
    designatedHedges: [
      // {
      //   id: 1,
      //   ptRate: 1000,
      //   ptAvailableToz: 20,
      //   pdRate: 1000,
      //   pdAvailableToz: 10,
      //   rhRate: 2000,
      //   rhAvailableToz: 15
      // }
    ],
    yardChoices: [
      // {
      //   'id': 1,
      //   'name': 'Placeholder Name',
      //   'city': 'Sample City',
      //   'address': '123 Placeholder Street',
      //   'state': 'XX'
      // }
    ],
    advanceCalculatorHistory: [],
    historyVersion: null,
    pcToWeightConversionRate: 0
  },
  getters: {
    getIsLocked: (state) => state.isLocked,
    isModified: (state) => true,
    getLotName: (state) => state.lotName,
    getLotId: (state) => state.lotId,
    getYardId: (state) => state.yardId,
    getYardChoices: (state) => state.yardChoices,
    getShipDate: (state) => state.shipDate,
    getConvertersProcessed: (state) => state.convertersProcessed,
    getMixedCatalystWeight: (state) => state.mixedCatalystWeight,
    getClientValueDate: (state) => state.clientValueDate,
    getPurchasedOutright: (state) => state.purchasedOutright,
    getGrossOutrightUnpaidValue (state) {
      return state.purchasedOutright.reduce((total, po) => po.paid ? total : total + +po.totalPrice, 0).toFixed(2)
    },
    getAllocations: (state) => {
      return state.allocations.map(line => {
        line.ptValue = (Number(line.ptMarketRate) * line.ptRtoz).toFixed(2)
        line.pdValue = (Number(line.pdMarketRate) * line.pdRtoz).toFixed(2)
        line.rhValue = (Number(line.rhMarketRate) * line.rhRtoz).toFixed(2)
        return line
      })
    },
    getGrossMetalValue: (_, getters) => getters.getAllocations.reduce((v, a) => {
      return v + +a.ptValue + +a.pdValue + +a.rhValue
    }, 0).toFixed(3),
    getAvailablePt: (state) => state.availablePt,
    getAvailablePd: (state) => state.availablePd,
    getAvailableRh: (state) => state.availableRh,
    getAllocatedPt (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.ptRtoz, 0).toFixed(3)
    },
    getAllocatedPd (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.pdRtoz, 0).toFixed(3)
    },
    getAllocatedRh (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.rhRtoz, 0).toFixed(3)
    },
    getUnallocatedPt (state, getters) {
      return (+getters.getAvailablePt - getters.getAllocatedPt).toFixed(3)
    },
    getUnallocatedPd (state, getters) {
      return (+getters.getAvailablePd - getters.getAllocatedPd).toFixed(3)
    },
    getUnallocatedRh (state, getters) {
      return (+getters.getAvailableRh - getters.getAllocatedRh).toFixed(3)
    },
    getAllocatedReturnablePt (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.ptRtoz, 0).toFixed(3)
    },
    getAllocatedReturnablePd (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.pdRtoz, 0).toFixed(3)
    },
    getAllocatedReturnableRh (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.rhRtoz, 0).toFixed(3)
    },
    getAllocatedReturnablePtValue (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.ptValue, 0).toFixed(3)
    },
    getAllocatedReturnablePdValue (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.pdValue, 0).toFixed(3)
    },
    getAllocatedReturnableRhValue (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.rhValue, 0).toFixed(3)
    },
    getAssayFee: (state) => state.assayFee,
    getShreddingFee: (state) => state.shreddingFee,
    getBrokerageFee: (state) => state.brokerageFee,
    getRefiningFee: (state) => state.refiningFee,
    getProcessingFee: (state) => state.processingFee,
    getHandlingFee: (state) => state.handlingFee,
    getFreightCharge: (state) => state.freightCharge,
    getNetOtherFees: (state) => state.netOtherFees,
    getTotalFees (state) {
      return (+state.refiningFee + +state.processingFee + +state.handlingFee + +state.freightCharge +
        +state.assayFee + +state.shreddingFee + +state.brokerageFee + +state.netOtherFees).toFixed(2)
    },
    getNetValue (_, getters) {
      return (+getters.getGrossOutrightUnpaidValue + +getters.getGrossMetalValue - +getters.getTotalFees).toFixed(2)
    },
    getConsolidateFees: (state) => state.consolidateFees,
    getExpectedPaymentDate: (state) => state.expectedPaymentDate,
    getDaysOfInterest: (state) => {
      // client value date - expected payment date
      if (state.clientValueDate && state.expectedPaymentDate) {
        try {
          const momentDate1 = moment(state.clientValueDate, 'YYYY-MM-DD')
          const momentDate2 = moment(state.expectedPaymentDate, 'YYYY-MM-DD')
          const days = momentDate1.diff(momentDate2, 'days')
          return (days < 0) ? 0 : days
        } catch (e) {
          console.error('Unable to parse dates for days of interest')
          console.error(e)
        }
      }
      return 0
    },
    getInterestPercent: (state) => state.interestPercent,
    getFinanceCharge: (state, getters) => {
      // net_value x interest_rate / 360 * days
      const days = getters.getDaysOfInterest
      if (days === 0) return 0
      return (getters.getNetValue * (getters.getInterestPercent / 100) / 360 * getters.getDaysOfInterest).toFixed(2)
    },
    getDepositPercent: (state) => state.depositPercent,
    getSettlementAmountDue: (state, getters) => {
      return (+getters.getNetValue + +getters.getFinanceCharge).toFixed(2)
    },
    getDepositTotal: (state, getters) => {
      if (getters.getDepositPercent === 0) return 0
      return (getters.getSettlementAmountDue * (getters.getDepositPercent / 100)).toFixed(2)
    },
    getUnallocatedHedges: (state) => {
      // return only hedges that are not already allocated
      const hedgeIds = state.allocations.map(a => a.hedgeId)
      return state.designatedHedges.filter(dh => !hedgeIds.includes(dh.id))
    },
    getAdvanceCalculatorHistory: (state) => state.advanceCalculatorHistory,
    getHistoryVersion: (state) => state.historyVersion,
    getStateSnapshot: (state) => {
      return {
        lot: state.lotId,
        data: {
          lotName: state.lotName,
          lotId: state.lotId,
          yardId: state.yardId,
          shipDate: state.shipDate,
          convertersProcessed: state.convertersProcessed,
          mixedCatalystWeight: state.mixedCatalystWeight,
          clientValueDate: state.clientValueDate,
          purchasedOutright: state.purchasedOutright,
          allocations: state.allocations,
          availablePt: state.availablePt,
          availablePd: state.availablePd,
          availableRh: state.availableRh,
          assayFee: state.assayFee,
          shreddingFee: state.shreddingFee,
          brokerageFee: state.brokerageFee,
          refiningFee: state.refiningFee,
          processingFee: state.processingFee,
          handlingFee: state.handlingFee,
          freightCharge: state.freightCharge,
          netOtherFees: state.netOtherFees,
          consolidateFees: state.consolidateFees,
          expectedPaymentDate: state.expectedPaymentDate,
          interestPercent: state.interestPercent,
          depositPercent: state.depositPercent,
          yardChoices: state.yardChoices,
          pcToWeightConversionRate: state.pcToWeightConversionRate
        }
      }
    },
    getPcToWeightConversionRate: (state) => {
      return Number(state.pcToWeightConversionRate).toFixed(2)
    },
    getEstimatedWeightFromPieces: (state) => {
      return (Number(state.pcToWeightConversionRate) * Number(state.convertersProcessed)).toFixed(2)
    }
  },
  mutations: {
    setIsLocked (state, value) {
      state.isLocked = value
    },
    clearState (state) {
      state.lotName = 'N/A'
      state.lotId = null
      state.yardId = null
      state.shipDate = null
      state.convertersProcessed = 0
      state.mixedCatalystWeight = 0
      state.clientValueDate = null
      state.purchasedOutright = []
      state.allocations = []
      state.containedPt = 0
      state.containedPd = 0
      state.containedRh = 0
      state.brokeredRatePt = 0
      state.brokeredRatePd = 0
      state.brokeredRateRh = 0
      // state.availablePt = 0
      // state.availablePd = 0
      // state.availableRh = 0
      state.assayFee = 0
      state.shreddingFee = 0
      state.brokerageFee = 0
      state.processingFee = 0
      state.handlingFee = 0
      state.freightCharge = 0
      state.netOtherFees = 0
      state.consolidateFees = false
      state.expectedPaymentDate = null
      state.interestPercent = 0
      state.depositPercent = 0
      state.designatedHedges = []
      state.yardChoices = []
      state.advanceCalculatorHistory = []
      state.historyVersion = null
    },
    setLotName (state, value) {
      state.lotName = value
    },
    setYardId (state, value) {
      state.yardId = value
    },
    setYardChoices (state, value) {
      state.yardChoices = value
    },
    setShipDate (state, value) {
      state.shipDate = value
    },
    setConvertersProcessed (state, value) {
      state.convertersProcessed = value
    },
    setMixedCatalystWeight (state, value) {
      state.mixedCatalystWeight = value
    },
    setClientValueDate (state, value) {
      state.clientValueDate = value
    },
    setPurchasedOutright (state, lines) {
      state.purchasedOutright = lines.map(line => {
        line.totalPrice = (Number(line.price) * Number(line.quantity)).toFixed(2)
        return line
      })
    },
    setAllocations (state, value) {
      state.allocations = value
    },
    setAvailablePt (state, value) {
      state.availablePt = value
    },
    setAvailablePd (state, value) {
      state.availablePd = value
    },
    setAvailableRh (state, value) {
      state.availableRh = value
    },
    setAssayFee (state, value) {
      state.assayFee = value
    },
    setShreddingFee (state, value) {
      state.shreddingFee = value
    },
    setBrokerageFee (state, value) {
      state.brokerageFee = value
    },
    setRefiningFee (state, value) {
      state.refiningFee = value
    },
    setProcessingFee (state, value) {
      state.processingFee = value
    },
    setHandlingFee (state, value) {
      state.handlingFee = value
    },
    setFreightCharge (state, value) {
      state.freightCharge = value
    },
    setNetOtherFees (state, value) {
      state.netOtherFees = value
    },
    setConsolidateFees (state, value) {
      state.consolidateFees = value
    },
    setExpectedPaymentDate (state, value) {
      state.expectedPaymentDate = value
    },
    setInterestPercent (state, value) {
      state.interestPercent = value
    },
    setDepositPercent (state, value) {
      state.depositPercent = value
    },
    addHedgeToAllocations (state, line) {
      state.allocations = [...state.allocations, line]
    },
    removeAllocation (state, hedgeId) {
      state.allocations = state.allocations.filter(allocation => allocation.hedgeId !== hedgeId)
    },
    setAdvanceCalculatorHistory (state, calculatorHistoryList) {
      state.advanceCalculatorHistory = calculatorHistoryList
    },
    // sets the local variables based on a history entry
    setContextFromHistory (state, value) {
      if ('data_fx' in value) {
        state.historyVersion = value.id
        // set the state environments
        const props = [
          'yardChoices',
          'lotName',
          'lotId',
          'yardId',
          'shipDate',
          'convertersProcessed',
          'mixedCatalystWeight',
          'clientValueDate',
          'purchasedOutright',
          'allocations',
          'availablePt',
          'availablePd',
          'availableRh',
          'assayFee',
          'shreddingFee',
          'brokerageFee',
          'refiningFee',
          'processingFee',
          'handlingFee',
          'freightCharge',
          'netOtherFees',
          'consolidateFees',
          'expectedPaymentDate',
          'interestPercent',
          'depositPercent'
        ]
        for (const prop of props) {
          if (prop in value.data_fx) state[prop] = value.data_fx[prop]
        }
      }
    },
    setLotId (state, value) {
      state.lotId = value
    },
    setHistoryVersionId (state, value) {
      state.historyVersion = value
    },
    setDesignatedHedges: (state, hedges) => {
      state.designatedHedges = hedges.map(hedge => {
        hedge.ptRate.toFixed(3)
        hedge.pdRate.toFixed(3)
        hedge.rhRate.toFixed(3)
        return hedge
      })
    },
    setPcToWeightConversionRate: (state, value) => {
      state.pcToWeightConversionRate = value
    }
  },
  actions: {
    // Gets the current advance calculator context and saves a new entry
    async saveAdvanceCalculator ({ commit, state, getters }) {
      const payload = getters.getStateSnapshot
      const response = await axios.post('/api/internal_proformas/', payload)
      if (response.data) {
        commit('setAdvanceCalculatorHistory', [response.data, ...state.advanceCalculatorHistory])
        commit('setHistoryVersionId', response.data.id)
      }
    },
    async fetchAdvanceCalculators ({ commit, state }) {
      const response = await axios.get('/api/internal_proformas/', { params: { lot: state.lotId } })
      commit('setAdvanceCalculatorHistory', response.data.results)
    },
    async fetchDesignatedHedges ({ commit, state }) {
      const response = await axios.get(`/api/lots/${state.lotId}/linked_hedges/`)
      commit('setDesignatedHedges', response.data.map(hedge => ({
        id: hedge.id,
        ptRate: hedge.pt_rate_per_toz_fx,
        ptAvailableToz: hedge.pt_available_toz,
        pdRate: hedge.pd_rate_per_toz_fx,
        pdAvailableToz: hedge.pd_available_toz,
        rhRate: hedge.rh_rate_per_toz_fx,
        rhAvailableToz: hedge.rh_available_toz
      })))
    },
    async refreshDataFromLotData ({ commit, state, dispatch }) {
      // Fetch lot seed data to populate the report fields
      // LOT ID should already be set
      const response = await axios.get(`/api/lots/${state.lotId}/internal_proforma_seed/`)
      if (response.data) {
        const rd = response.data
        if ('yardChoices' in rd) commit('setYardChoices', rd.yardChoices)

        if ('lotName' in rd) commit('setLotName', rd.lotName)
        if ('yardId' in rd) commit('setYardId', rd.yardId)
        if ('shipDate' in rd) commit('setShipDate', rd.shipDate)
        if ('convertersProcessed' in rd) commit('setConvertersProcessed', rd.convertersProcessed)
        if ('mixedCatalystWeight' in rd) commit('setMixedCatalystWeight', rd.mixedCatalystWeight)
        if ('clientValueDate' in rd) commit('setClientValueDate', rd.clientValueDate)
        if ('purchasedOutright' in rd) commit('setPurchasedOutright', rd.purchasedOutright)
        // if ('allocations' in rd) commit('setAllocations', rd.allocations)
        if ('availablePt' in rd) commit('setAvailablePt', rd.availablePt)
        if ('availablePd' in rd) commit('setAvailablePd', rd.availablePd)
        if ('availableRh' in rd) commit('setAvailableRh', rd.availableRh)
        if ('assayFee' in rd) commit('setAssayFee', rd.assayFee)
        if ('shreddingFee' in rd) commit('setShreddingFee', rd.shreddingFee)
        if ('brokerageFee' in rd) commit('setBrokerageFee', rd.brokerageFee)
        if ('refiningFee' in rd) commit('setRefiningFee', rd.refiningFee)
        if ('processingFee' in rd) commit('setProcessingFee', rd.processingFee)
        if ('handlingFee' in rd) commit('setHandlingFee', rd.handlingFee)
        if ('freightCharge' in rd) commit('setFreightCharge', rd.freightCharge)
        if ('netOtherFees' in rd) commit('setNetOtherFees', rd.netOtherFees)
        if ('consolidateFees' in rd) commit('setConsolidateFees', rd.consolidateFees)
        if ('expectedPaymentDate' in rd) commit('setExpectedPaymentDate', rd.expectedPaymentDate)
        if ('interestPercent' in rd) commit('setInterestPercent', rd.interestPercent)
        if ('depositPercent' in rd) commit('setDepositPercent', rd.depositPercent)
        if ('pcToWeightConversionRate' in rd) commit('setPcToWeightConversionRate', rd.pcToWeightConversionRate)

        // Non History Maintenance ( advances do not change )
        commit('setHistoryVersionId', null)
        // These are normally automatically fetched when a yardId is changed in the view - no change no fetch
        dispatch('fetchYardTerms')
      }
    }
  }
}
