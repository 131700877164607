<template>
  <v-navigation-drawer
    width="220"
    clipped
    app
    fixed
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-list
      dense
    >
      <v-list-item
        v-for="(item, i) in filterMenuItems(top)"
        :key="i"
        :to="item.route"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="py-0">
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="item.subtitle"
            v-text="item.subtitle"
          />
        </v-list-item-content>
        <v-icon
          v-if="item.addedDate && !weekHasPassed(item.addedDate)"
          color="accent"
        >
          mdi-new-box
        </v-icon>
      </v-list-item>
      <v-divider />
      <template
        v-for="(item, index) in filterMenuItems(menuItems)"
      >
        <!--Normal Menu Handling-->
        <v-divider
          v-if="item.divider"
          :key="index + '-divider'"
          color="white"
        />
        <v-subheader
          v-if="item.subheader"
          :key="item.subheader"
        >
          {{ item.subheader }}
        </v-subheader>
        <v-list-item
          v-if="!item.subheader && !item.divider"
          :key="item.title"
          :to="item.route"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }} {{ item.icon }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            v-if="item.canCreate"
            class="my-0"
          >
            <router-link
              :to="item.routeCreate"
              class="caption"
            >
              <v-btn
                x-small
                icon
              >
                <v-icon x-small>
                  mdi-plus-thick
                </v-icon>
              </v-btn>
            </router-link>
          </v-list-item-action>
          <v-list-item-icon
            v-if="item.addedDate && !weekHasPassed(item.addedDate)"
          >
            <v-icon color="accent">
              mdi-new-box
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <v-list
        class="pa-0"
        dense
      >
        <v-list-item
          :to="{ name: 'patch-notes' }"
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-fade-transition>
                <div v-if="!$store.getters['patchNotes/newPatchNote']">
                  <span>{{ hostName }} v{{ getAppVersion }}&nbsp;</span>
                </div>
                <div v-else>
                  <span>{{ hostName }} v{{ getAppVersion }}&nbsp;</span>
                  <v-icon
                    small
                    color="accent lighten-1"
                  >
                    mdi-alert-octagram
                  </v-icon>
                  <v-divider />
                  <small
                    class="accent--text"
                  >
                    please read the new patch notes
                  </small>
                </div>
              </v-fade-transition>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/helpers/utils'
import config from '@/../izzi_config'

export default {
  name: 'AppNavigation',
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    hostName: config.name
  }),
  computed: {
    ...mapGetters({
      can: 'can',
      user: 'currentUser'
    }),
    ...mapGetters(['getAppVersion']),
    menuItems () {
      return [
        {
          title: 'Check-Ins',
          route: { name: 'checkin-list' },
          can: this.can('view-purchase')
        },
        {
          title: 'Purchases',
          route: { name: 'purchase-list' },
          can: this.can('view-purchase'),
          routeCreate: { name: 'create-purchase' },
          canCreate: this.can('create-purchases')
        },
        {
          title: 'Intakes',
          route: { name: 'intake-list' },
          can: this.can('view-intakes') || this.can('create-intakes'),
          routeCreate: { name: 'create-intake' },
          canCreate: this.can('create-intakes')
        },
        {
          divider: true,
          can: this.can('view-purchase')
        },
        {
          title: 'Lots',
          route: { name: 'lot-list' },
          can: this.can('view-partial-lots'),
          routeCreate: { name: 'create-lot' },
          canCreate: this.can('create-lots')
        },
        {
          title: 'Shipment Summaries',
          route: { name: 'shipment-summary-list' },
          can: this.can('view-lots'),
          routeCreate: { name: 'create-shipment-summary' },
          canCreate: this.can('create-lots')
        },
        {
          title: 'Settlements',
          route: { name: 'lot-settlement-list' },
          can: this.can('view-brokered-settlements'),
          addedDate: '2024/7/19'
        },
        {
          title: 'Advances',
          route: { name: 'advance-list' },
          can: this.can('view-advances'),
          addedDate: '2024/8/2'
        },
        {
          title: 'PPM Calculators',
          route: { name: 'ppm-calculation-list' },
          can: this.can('view-ppm'),
          routeCreate: { name: 'create-ppm-calculation' },
          canCreate: this.can('create-ppm')
        },
        {
          title: 'Smelters',
          route: { name: 'smelter-list' },
          can: this.can('view-smelters')
        },
        {
          title: 'Hedges',
          route: { name: 'hedge-list' },
          can: this.can('view-lots'),
          routeCreate: { name: 'create-hedge' },
          canCreate: this.can('create-lots')
        },
        {
          subheader: 'Yards & Trip Planning',
          can: this.can('view-blueprint') || this.can('view-expenses') || this.can('view-trips') || this.can('view-yards')
        },
        {
          title: 'Blueprints',
          route: { name: 'blueprints-list' },
          can: this.can('view-blueprint')
        },
        {
          title: 'Trips',
          route: { name: 'trip-list' },
          can: this.can('view-trips')
        },
        {
          title: 'Yards',
          route: { name: 'yard-list' },
          can: this.can('view-yards'),
          routeCreate: { name: 'create-yard' },
          canCreate: this.can('create-yards')
        },
        {
          subheader: 'Catalog/Inventory',
          can: this.can('create-catalogs') || this.can('view-catalogs' || this.can('view-inventory'))
        },
        {
          title: 'Inventory/Cut-Plans',
          route: { name: 'inventory-list' },
          can: this.can('view-inventory')
        },
        {
          title: 'Catalog',
          route: { name: 'catalog-list' },
          can: this.can('view-catalogs'),
          routeCreate: { name: 'create-catalog' },
          canCreate: this.can('create-catalogs')
        },
        {
          subheader: 'Transit',
          can: this.can('view-transit')
        },
        {
          title: 'Inbound',
          route: { name: 'transit-list' },
          can: this.can('view-transit')
        },
        {
          title: 'Outbound',
          route: { name: 'outbound-list' },
          can: this.can('view-transit')
        },
        {
          title: 'Inventory Manifests',
          route: { name: 'manifest-list' },
          can: this.can('view-manifests')
        },
        {
          title: 'Lot Manifests',
          route: { name: 'lot-manifest-list' },
          can: this.can('view-lot-manifests')
        },
        {
          title: 'Brokers',
          route: { name: 'broker-list' },
          can: this.can('view-broker')
        },
        {
          title: 'Carriers',
          route: { name: 'carrier-list' },
          can: this.can('view-carrier')
        }
      ]
    },
    top () {
      return [
        { title: 'Dashboard', icon: 'mdi-view-dashboard-outline', route: { name: 'dashboard' }, can: true },
        {
          title: 'Allocations',
          icon: 'mdi-format-columns',
          route: { name: 'current-allocations' },
          can: this.can('create-lots')
        },
        { title: 'Calendar', icon: 'mdi-calendar', route: { name: 'calendar' }, can: true },
        {
          title: 'Cut Plan',
          icon: 'mdi-order-numeric-descending',
          route: { name: 'cut-plan-list' },
          can: this.can('view-cut-plans')
        },
        {
          title: 'Current Purchases',
          icon: 'mdi-table',
          route: { name: 'current-purchases' },
          can: this.can('view-current-purchases')
        },
        {
          title: 'Reporting',
          icon: 'mdi-clipboard-text-outline',
          route: { name: 'reports' },
          can: this.can('view-reports')
        },
        {
          title: 'Transit',
          icon: 'mdi-truck-delivery-outline',
          route: { name: 'transit-dashboard' },
          can: this.can('view-transit')
        },
        {
          title: 'Inventory On Hand',
          subtitle: this.$store.getters.getLastInventoryOnHandLocation,
          icon: 'mdi-transfer',
          route: {
            name: 'inventory-on-hand',
            query: {
              location_name: this.$store.getters.getLastInventoryOnHandLocation
            }
          },
          can: this.can('view-inventory-on-hand'),
          addedDate: '2021/6/2'
        },
        {
          title: 'Hedge Pieces',
          icon: 'mdi-counter',
          route: { name: 'hedge-manager-report' },
          can: true,
          addedDate: '2023/8/23'
        }
      ]
    }
  },
  methods: {
    weekHasPassed (date) {
      return utils.timeHasPassed(date, 1, 'w')
    },
    /**
     * Determine what links the user can view
     * @param {Array} menuItems
     */
    filterMenuItems (menuItems) {
      return menuItems.filter(i => i.can)
    }
  },
  mounted () {
    this.$emit('input', this.$vuetify.breakpoint.mdAndUp)
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.v-list-item--dense, .v-list--dense .v-list-item {
  min-height: 36px;
}
</style>
