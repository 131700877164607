<template>
  <div class="d-flex justify-center">
    <div class="align-self-center mt-16">
      <v-card
        flat
        max-width="400px"
      >
        <v-img
          alt="Trackkat"
          :src="image"
        />
        <div v-if="$auth.firebaseUser && !$auth.user">
          <v-card-text class="delay-show">
            We were able to authenticate you, but no profile was found. Please contact
            <a href="mailto:support@pgm-corp.com">support@pgm-corp.com</a> for assistance.

            If you've reached this screen by mistake you can also <a
              @click="$auth.logout()"
              class="text-decoration-underline"
            >logout</a> and try again.
          </v-card-text>
        </div>
        <div v-else>
          <v-card-text class="text-center">
            Sign in using your account. If you have not been issued an account please contact support.
          </v-card-text>
          <div class="text-center pb-4">
            <msft-auth-button
              width="200px"
            >
              Microsoft
              <v-icon
                small
                class="ml-1"
              >
                mdi-microsoft-windows
              </v-icon>
            </msft-auth-button>
            <!-- Disabling this authentication method until needed.-->
            <br>
            - or -
            <br>
            <v-btn
              width="200px"
              @click="firebase = true"
            >
              Email
              <v-icon
                small
                class="ml-1"
              >
                mdi-at
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>
      <firebase-login-dialog
        v-model="firebase"
      />
    </div>
    <v-footer
      class="text-center"
      fixed
    >
      <v-spacer />
      <small>
        2019 - {{ date }} &copy; Phoenix Group Metals. All Rights Reserved.
        <a
          class="text-decoration-underline"
          @click="showTermsOfUse = !showTermsOfUse"
        >
          Terms of Use
        </a>
        <terms-of-use
          v-model="showTermsOfUse"
        />
      </small>
    </v-footer>
  </div>
</template>

<script>
import image from '@/assets/trackkat-full-logo.svg'
import FirebaseLoginDialog from '@/views/FirebaseLoginDialog.vue'
import TermsOfUse from '@/views/TermsOfUse.vue'
import MsftAuthButton from '@/auth/MsftAuthButton.vue'

export default {
  name: 'Landing',
  components: { MsftAuthButton, TermsOfUse, FirebaseLoginDialog },
  data: () => ({
    showTermsOfUse: false,
    firebase: false,
    image,
    date: new Date().getFullYear()
  }),
  watch: {
    '$auth.isAuthenticated': {
      immediate: true,
      handler (v) {
        if (v) this.$router.push({ name: 'dashboard' })
      }
    }
  }
}
</script>

<style scoped>
.delay-show {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
</style>
