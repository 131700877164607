import { fetchCatalogOptions } from '../../requests/inventoryBoxes'

export default {
  namespaced: true,
  state: {
    newBoxId: 1,
    // List of boxes to include, this includes "New" boxes, each has a name
    location: null,
    locations: [],
    inputBoxes: [],
    outputBoxes: [],
    catalogOptions: []
  },
  getters: {
    boxChanges (state) {
      const toDelete = {}
      const toUpdate = {}
      const toCreate = {}
      state.inputBoxes.forEach(box => {
        toDelete[box.name] = box
      })
      state.outputBoxes.forEach(box => {
        if (toDelete[box.name]) {
          toUpdate[box.name] = box
          delete toDelete[box.name]
        } else {
          toCreate[box.name] = box
        }
      })
      return {
        toDelete,
        toUpdate,
        toCreate
      }
    },
    containsInput (state) {
      const m = new Map()
      state.inputBoxes.forEach(box => box.check_ins_data.forEach(ciData => {
        m.set(ciData.id, ciData)
      }))
      return Array.from(m.values())
    },
    catalogOptions (state) {
      let options = state.catalogOptions
      state.outputBoxes.forEach(box => {
        if (box.catalog && box.catalogName && !options.find(o => o.url === box.catalog)) {
          options.push({ url: box.catalog, name: box.catalogName })
        }
      })
      return options
    },
    totalWeightInput (state) {
      return state.inputBoxes.reduce((accum, box) => accum + Number(box.weight), 0)
    },
    totalPiecesInput (state) {
      return state.inputBoxes.reduce((accum, box) => accum + Number(box.pieces), 0)
    },
    totalBoxes (state) {
      return state.outputBoxes.length
    },
    totalWeight (state) {
      return state.outputBoxes.reduce((accum, box) => accum + Number(box.weight), 0)
    },
    totalTare (state) {
      return state.outputBoxes.reduce((accum, box) => accum + Number(box.tare), 0)
    },
    totalNet (state) {
      return state.outputBoxes.reduce((accum, box) => accum + Number(box.net), 0)
    },
    totalPieces (state) {
      return state.outputBoxes.reduce((accum, box) => accum + Number(box.pieces), 0)
    },
    step (state) {
      if (state.outputBoxes.length) return 'save-intake'
      if (state.inputBoxes.length) return 'add-boxes'
      return 'select-inventory'
    }
  },
  mutations: {
    setCatalogOptions (state, options) {
      state.catalogOptions = options
    },
    setInputBoxes (state, boxes) {
      // Clear selected on boxes change
      state.inputBoxes = boxes
    },
    addOutputBox (state, inputBox) {
      if (inputBox && state.outputBoxes.find(ob => ob.id === inputBox.id)) return
      let box = {
        id: null, // is a new box
        name: '',
        weight: 0,
        net: 0,
        tare: 0,
        pieces: 0,
        catalog: null,
        catalogName: '',
        container: 'BOX',
        catalogLocked: false,
        isNew: true
      }
      if (inputBox) {
        box.id = inputBox.id
        box.name = inputBox.name
        box.weight = inputBox.weight
        box.net = inputBox.net
        box.tare = inputBox.tare
        box.pieces = inputBox.pieces
        box.catalog = inputBox.catalog
        box.catalogName = inputBox.catalog_name
        box.container = inputBox.container
        box.catalogLocked = true
        box.isNew = false
      } else {
        box.id = '::' + state.newBoxId
        box.name = 'New Box #' + state.newBoxId
        state.newBoxId = state.newBoxId + 1
      }
      state.outputBoxes.push(box)
    },
    removeOutputBox (state, outputBox) {
      const boxes = state.outputBoxes.filter(box => box.id !== outputBox.id)
      state.outputBoxes = boxes
    },
    updateBox (state, box) {
      box.net = (+box.weight - +box.tare).toFixed(2)

      state.outputBoxes = state.outputBoxes.map(b => {
        if (b.id === box.id) return Object(b, box)
        return b
      })
    },
    clearStore (state) {
      state.newBoxId = 1
      state.inputBoxes = []
      state.outputBoxes = []
    },
    setLocation (state, location) {
      state.location = location
    },
    setLocations (state, locations) {
      state.locations = locations
    }
  },
  actions: {
    addAllInputBoxes ({ state, commit }) {
      state.inputBoxes.forEach(box => commit('addOutputBox', box))
    },
    async fetchCatalogOptions ({ commit }) {
      const response = await fetchCatalogOptions()
      if (response.status === 200) {
        commit('setCatalogOptions', response.data)
      }
    }
  }
}
