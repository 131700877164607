export default [
  {
    path: '/cut-plan/edit',
    name: 'cut-plan-edit',
    component: () => import(/* webpackChunkName: "index-routes" */ '@/views/Inventory/CutPlans/CutPlanEdit'),
    meta: 'view-cut-plans'
  },
  {
    path: '/inventory-list',
    component: () => import(/* webpackChunkName: "index-routes" */ '@/views/Inventory/InventoryParent'),
    children: [
      {
        path: '',
        name: 'inventory-list',
        component: () => import(/* webpackChunkName: "index-routes" */ '@/views/Inventory/InventoryList'),
        meta: { permission: 'view-inventory' }
      },
      {
        path: '/cut-plan-list',
        name: 'cut-plan-list',
        component: () => import(/* webpackChunkName: "index-routes" */ '@/views/Inventory/CutPlans/CutPlanList'),
        meta: 'view-cut-plans'
      },
      {
        path: '/cut-plan/:id',
        name: 'cut-plan-view',
        component: () => import(/* webpackChunkName: "index-routes" */ '@/views/Inventory/CutPlans/CutPlanView'),
        meta: 'view-cut-view'
      },
      {
        path: '/shredding-operations',
        name: 'Shredding Operations',
        component: () => import(/* webpackChunkName: "index-routes" */ '@/views/Inventory/ShreddingOperations/ShreddingOperationsView'),
        meta: 'view-cut-view'
      }
    ]
  },
  {
    path: '/inventory/:id',
    name: 'inventory',
    component: () => import(/* webpackChunkName: "index-routes" */ '@/views/Inventory/Inventory'),
    reverse: 'inventories-single'
  }
]
