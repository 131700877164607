import factories from '../generics/factories'

const { sendEmailMixin, factory, createMixin, fetchMixin, patchMixin, deleteMixin } = factories

const store = factory(
  {
    entityName: 'Transit',
    urlTemplate: id => id ? `logistics/transits/${id}/` : `logistics/transits/`
  },
  createMixin,
  fetchMixin,
  patchMixin,
  deleteMixin,
  sendEmailMixin
)

export default store
