import axios from 'axios'

export default {
  namespaced: true,
  state: {
    entry: null,
    converter: null,
    converterGroup: null,
    pubicConverterGroup: null,
    pictureSetType: 'spinner',
    selectedStaticPictureSetOption: 'lab',
    labEntry: null,
    pictureSet: null,
    pictureSetOptions: [],
    code: null,
    catName: null,
    notes: null,
    makeModels: [], // Should be make/model objects
    vehicles: [], // Should be vehicle objects
    o2Sensor: null,
    height: null,
    width: null,
    catalystWeight: null,
    debrisWeight: null,
    emptyShellWeight: null,
    wholeWeight: null,
    assayOptions: [],
    includedAssays: [],
    vehicle: null
  },
  getters: {
    selectedStaticPictureSetOption: state => state.selectedStaticPictureSetOption
  },
  mutations: {
    setConverterGroup (state, data) { state.converterGroup = data },
    setPubicConverterGroup (state, data) { state.pubicConverterGroup = data },
    setPictureSetType (state, data) { state.pictureSetType = data },
    setSelectedStaticPictureSetOption (state, data) {
      state.selectedStaticPictureSetOption = data
    },
    setLabEntry (state, data) { state.labEntry = data },
    setPictureSet (state, data) { state.pictureSet = data },
    setCode (state, data) { state.code = data },
    setCatName (state, data) { state.catName = data },
    setNotes (state, data) { state.notes = data },
    addPictureSetOption (state, data) {
      if (state.pictureSetOptions.find(el => el.url === data.url)) return
      state.pictureSetOptions = [...state.pictureSetOptions, data]
    },
    addAssayOptions (state, data) {
      let options = new Set(state.assayOptions)
      options.add(data.url)
      state.includedAssays = [...options]
      if (state.assayOptions.find(el => el.url === data.url)) return
      state.assayOptions = [...state.assayOptions, data]
    },
    setIncludedAssays (state, data) { state.includedAssays = data },
    addMakeModel (state, data) {
      if (state.makeModels.find(el => el.url === data.url)) return
      state.makeModels = [...state.makeModels, data]
    },
    addVehicle (state, data) {
      if (state.vehicles.find(el => el.url === data.url)) return
      state.vehicles = [...state.vehicles, data]
    },
    setO2Sensor (state, data) { state.o2Sensor = data },
    setHeight (state, data) { state.height = data },
    setWidth (state, data) { state.width = data },
    setCatalystWeight (state, data) { state.catalystWeight = data },
    setDebrisWeight (state, data) { state.debrisWeight = data },
    setEmptyShellWeight (state, data) { state.emptyShellWeight = data },
    setWholeWeight (state, data) { state.wholeWeight = data },
    setEntry (state, data) { state.entry = data },
    setConverter (state, data) { state.converter = data },
    setVehicle (state, data) { state.vehicle = data }
  },
  actions: {
    // this endpoint automatically creates make/model/year/cylinders/engine_size_liters and returns the object
    async resolveVehicle ({ state, commit }, params) {
      const response = await axios.patch('/api/vehicles/resolve/', params)
      if (response.data) commit('setVehicle', response.data)
      return response.data
    },
    async createConverter ({ state }) {
      const assays = state.assayOptions.filter(ia => state.includedAssays.includes(ia.url))
      const catalog = {
        name: state.code,
        description: state.notes,
        material_type: 'CV',
        unit_of_measure: 'PCS'
      }
      const catalogResponse = await axios.post('/api/catalogs/', catalog)
      let converter = {
        catalog: catalogResponse.data.url, // from catalog creation
        code: state.code,
        cat_name: state.catName,
        notes: state.notes,
        picture_set_type: state.pictureSetType,
        converter_group: state.converterGroup,
        public_converter_group: state.pubicConverterGroup,
        o2_sensor: state.o2Sensor ? 'Yes' : '',
        height: state.height,
        width: state.width,
        catalyst_weight: state.catalystWeight,
        debris_weight: state.debrisWeight,
        empty_shell_weight: state.emptyShellWeight,
        whole_weight: state.wholeWeight,
        vehicle_set: []
      }
      // attaches a vehicle if it is present
      if (state.vehicle && state.vehicle.url) {
        converter.vehicle = state.vehicle.url
      }
      if (state.pictureSet) converter.picture_set_id = state.pictureSet.id
      const converterResponse = await axios.post('/api/converters/', converter)
      // perform a separate patch to add the make-models and converters
      const makeModelVehicles = {
        make_models: state.makeModels.map(mm => mm.id),
        vehicles: state.vehicles.map(v => v.id)
      }
      await axios.patch(converterResponse.data.url, makeModelVehicles)
      // assays
      const assayPosts = assays.map(a => axios.post('/api/assays/', {
        converter_id: converterResponse.data.id,
        pt_ppm: Number(a.pt_ppm).toFixed(3),
        pd_ppm: Number(a.pd_ppm).toFixed(3),
        rh_ppm: Number(a.rh_ppm).toFixed(3),
        yield_lbs: Number(a.yield_lbs).toFixed(2)
      }))
      await Promise.all(assayPosts)
      await axios.patch(state.entry.url, { is_finalized: true })
      return converterResponse
    },
    async updateConverter ({ state }) {
      const assays = state.assayOptions.filter(ia => state.includedAssays.includes(ia.url))
      const catalog = {
        name: state.code,
        description: state.notes,
        material_type: 'CV',
        unit_of_measure: 'PCS'
      }
      const catalogResponse = await axios.patch(state.converter.catalog, catalog)
      const converter = {
        catalog: catalogResponse.data.url, // from catalog creation
        make_models: state.makeModels.map(mm => mm.id),
        vehicles: state.vehicles.map(v => v.id),
        code: state.code,
        cat_name: state.catName,
        notes: state.notes,
        picture_set_type: state.pictureSetType,
        converter_group: state.converterGroup,
        public_converter_group: state.pubicConverterGroup,
        o2_sensor: state.o2Sensor ? 'Yes' : '',
        height: state.height,
        width: state.width,
        catalyst_weight: state.catalystWeight,
        debris_weight: state.debrisWeight,
        empty_shell_weight: state.emptyShellWeight,
        whole_weight: state.wholeWeight
      }
      if (state.pictureSet) converter.picture_set_id = state.pictureSet.id
      const converterResponse = await axios.patch(state.converter.url, converter)
      // assays
      const assayPosts = assays.map(a => {
        if (a.converter) {
          return axios.patch(a.url, {
            converter_id: converterResponse.data.id
          })
        } else {
          return axios.post('/api/assays/', {
            converter_id: converterResponse.data.id,
            pt_ppm: Number(a.pt_ppm).toFixed(3),
            pd_ppm: Number(a.pd_ppm).toFixed(3),
            rh_ppm: Number(a.rh_ppm).toFixed(3),
            yield_lbs: Number(a.yield_lbs).toFixed(2)
          })
        }
      })
      await Promise.all(assayPosts)
      await axios.patch(state.entry.url, { is_finalized: true })
      return converterResponse
    }
  }
}
/**
// this could be automatic
CATALOG FIELDS ( so they can be hidden )
name = code
description = notes
material_type = CONVERTER
unit_of_measure = PIECES

CONVERTER FIELDS
catalog = @Catalog

(selection)
converter_group
public_converter_group

(default)
picture_set_type = 'spinner'

(one to one)
lab_entry
picture_set
code
notes
make_models
o2_sensor
height
width
catalyst_weight
debris_weight
empty_shell_weight
whole_weight
*/
