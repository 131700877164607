<!--
Provides a simple help dialog that can be passed markdown
Can be used with an activator to wrap a custom button

            <template v-slot:activator="{ on }">
              <v-btn @click="on">
                Help (external)
              </v-btn>
            </template>

In can accept markdown directly or be provided with the default slot
-->
<template>
  <div>
    <slot
      name="activator"
      :on="() => dialog = !dialog"
      :loading="videoUploading"
    >
      <v-btn
        icon
        @click="dialog = !dialog"
      >
        <v-icon>
          {{ icon }}
        </v-icon>
      </v-btn>
    </slot>
    <edit-dialog
      v-model="dialog"
      :headline-text="headlineText"
      :show-decline="false"
      accept-text="Done"
    >
      <slot>
        <vue-markdown>
          {{ markdown }}
        </vue-markdown>
      </slot>
    </edit-dialog>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: 'HelpDialog',
  components: { VueMarkdown },
  props: {
    headlineText: {
      type: String,
      default: 'Help',
      required: false
    },
    markdown: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'mdi-help-circle-outline'
    }
  },
  data: () => ({
    dialog: false,
    info: 'info',
    videoUpload: null,
    videoUploading: false
  }),
  watch: {
    dialog (opened) {
      this.$emit('opened', opened)
    }
  }
}
</script>
