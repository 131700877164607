import factories from '../generics/factories'
import axios from 'axios'

/**
 * @Mixin
 * Sets the state expenseGroups
 * @param state
 * @param groups
 */
function setGroups (state, groups) {
  state.expenseGroups = groups
}

/**
 * @Mixin
 * Fetches all of the expense groups
 * Will only fetch when there are no entries
 * @param commit
 * @param state
 * @return {Promise}
 */
function fetchExpenseGroups ({ commit, state }) {
  if (state.expenseGroups.length > 0) return Promise.resolve()
  return axios.get('/api/expense_groups/', { params: { page_size: 0 } }).then(r => {
    if (r.status === 200) commit('setGroups', r.data)
  })
}

async function addExpenseImage ({ commit }, params) {
  const urlResponse = await axios.get(params.url + 'upload_url/')
  if (urlResponse.status !== 200) {
    commit('setSnackbarError', 'Unable to generate upload url', { root: true })
    return
  }

  const url = urlResponse.data.upload_url

  // image upload with headers
  const putResponse = await fetch(url, {
    method: 'PUT',
    headers: {
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': '*',
      'x-amz-acl': 'private',
      'Content-Type': 'image/jpeg'
    },
    body: params.image
  })

  if (putResponse.status !== 200) {
    commit('setSnackbarError', 'Unable to upload expense picture', { root: true })
  }

  // Special Handler for setting the existing file
  const patchResponse = await axios.patch(params.url, {
    upload_reference: urlResponse.data.base_url
  })
  if (patchResponse.status !== 200) {
    commit('setSnackbarError', 'Unable to attach upload to expense', { root: true })
  }
}

const { factory, createMixin, fetchMixin, patchMixin, deleteMixin } = factories

const store = factory(
  {
    entityName: 'Expense',
    urlTemplate: id => id ? `api/expenses/${id}/` : `api/expenses/`,
    state: {
      expenseGroups: []
    }
  },
  (store) => {
    store.mutations.setGroups = setGroups
    store.actions.fetchExpenseGroup = fetchExpenseGroups
    store.actions.addExpenseImage = addExpenseImage
  },
  createMixin,
  fetchMixin,
  patchMixin,
  deleteMixin
)

export default store
