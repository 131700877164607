export default [
  {
    path: '/smelter/:id',
    name: 'smelter',
    component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Smelter/Smelter'),
    reverse: 'smelters-single',
    meta: { permission: 'view-smelters' }
  },
  {
    path: '/smelter/:id/edit',
    name: 'edit-smelter',
    component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Smelter/EditSmelter'),
    meta: { permission: 'edit-smelters' }
  },
  {
    path: '/smelter-list',
    name: 'smelter-list',
    component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Smelter/SmelterList'),
    meta: { permission: 'view-smelters' }
  },
  {
    path: '/hedge/:id',
    name: 'hedge',
    component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Hedges/Hedge'),
    reverse: 'hedges-single'
  },
  {
    path: '/hedge-manager-report/',
    name: 'hedge-manager-report',
    component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Hedges/HedgeManagerReport')
  },
  {
    path: '/hedge-manager-report/:id',
    name: 'hedge-manager-report-user',
    component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Hedges/HedgeManagerReport')
  },
  {
    path: '/hedge-list',
    component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Hedges/HedgeParent'),
    children: [
      {
        path: '',
        name: 'hedge-list',
        component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Hedges/HedgeList'),
        meta: { permission: 'view-lots' }
      },
      {
        path: '/create-hedge',
        name: 'create-hedge',
        component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Hedges/CreateHedge'),
        meta: { permission: 'create-lots' }
      },
      {
        path: '/current-allocations',
        name: 'current-allocations',
        component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Hedges/Allocations/Allocations'),
        meta: { permission: 'create-lots' }
      },
      // {
      //   path: '/check-in-allocations',
      //   name: 'check-in-allocations',
      //   component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Hedges/Allocations/AllocateCI'),
      //   meta: { permission: 'create-lots' }
      // },
      {
        path: '/current-purchases',
        name: 'current-purchases',
        component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Hedges/components/CurrentPurchases/CurrentPurchasesSection'),
        meta: { permission: 'view-current-purchases' }
      }
    ]
  },
  {
    path: '/reports/hedge/dump',
    name: 'hedge-dump-report',
    component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Reports/HedgeDumpReport'),
    meta: { permission: 'view-hedge-dump-report' }
  },
  // {
  //   path: '/reports/check-in-hedge/',
  //   name: 'check-in-hedge-report',
  //   component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Reports/CheckInHedgeReport'),
  //   meta: { permission: 'view-check-in-hedge-report' }
  // },
  {
    path: '/reports/hedge-report/',
    name: 'hedge-report',
    component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Reports/HedgeReport'),
    meta: { permission: 'view-reports' }
  }
  // {
  //   path: '/reports/hedge-managers-report/',
  //   name: 'hedge-managers-report',
  //   component: () => import(/* webpackChunkName: "hedge-routes" */ '@/views/Reports/HedgeReport'),
  //   meta: { permission: 'view-reports' }
  // }
]
