export default [
  {
    path: '/yard/:id',
    name: 'yard',
    component: () => import(/* webpackChunkName: "yard-routes" */ '@/views/Yards/components/Yard'),
    reverse: 'yards-single'
  },
  {
    path: '/yard-list',
    component: () => import(/* webpackChunkName: "yard-routes" */ '@/views/Yards/YardParent'),
    children: [
      {
        path: '',
        name: 'yard-list',
        component: () => import(/* webpackChunkName: "yard-routes" */ '@/views/Yards/YardList'),
        meta: { permission: 'view-yards' }
      },
      {
        path: '/create-yard',
        name: 'create-yard',
        component: () => import(/* webpackChunkName: "yard-routes" */ '@/views/Yards/components/CreateYard'),
        meta: { permission: 'create-yards' }
      }
    ]
  }
]
