<template>
  <v-snackbar
    v-model="snackbar"
    :color="snackbarContent.color"
  >
    <div class="font-weight-bold">
      {{ snackbarContent.message }}
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Snackbar',
  data () {
    return {
      snackbar: false
    }
  },
  computed: {
    ...mapGetters(['snackbarContent'])
  },
  watch: {
    snackbarContent: {
      handler () {
        if (this.snackbarContent.message !== null) {
          this.snackbar = true
        }
      }
    }
  }
}
</script>
