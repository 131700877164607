import { getAuth, OAuthProvider } from 'firebase/auth'
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: 'trackkat-84ac4.firebaseapp.com',
  projectId: 'trackkat-84ac4',
  storageBucket: 'trackkat-84ac4.appspot.com',
  messagingSenderId: '1079387129716',
  appId: '1:1079387129716:web:9d51f534de2a99d0d24e21',
  measurementId: 'G-VFWEH5L7XG'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

const provider = new OAuthProvider('microsoft.com')
provider.setCustomParameters({
  login_hint: 'first.last@pgm-corp.com'
})

export {
  app,
  auth,
  provider
}
