import axios from 'axios'

export default {
  namespaced: true,
  state: {
    searchResults: []
  },
  getters: {
    searchResults: state => state.searchResults
  },
  mutations: {
    setSearchResults (state, search) {
      state.searchResults = search
    },
    appendSearchResults (state, search) {
      state.searchResults = [...state.searchResults, ...search]
    }
  },
  actions: {
    /**
     * Performs a app_search and sets the search results
     * empty results if not set
     * @param commit
     * @param body
     * @return {Promise<Integer>} - total number of entities
     */
    async appSearch ({ commit }, body) {
      try {
        const params = body.params
        const isNew = body.isNew
        const response = await axios.get('/api/app_search/', { params })
        if (isNew) commit('setSearchResults', response.data.results)
        else commit('appendSearchResults', response.data.results)
        return response.data.count
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get search results', { root: true })
      }
    }
  }
}
