import axios from 'axios'

export default {
  namespaced: true,
  state: {
    // Report Page
    allocations: [],
    allocationCount: 0,
    allocationPage: 1,
    reportFilters: [
      { label: 'Smelter', path: '/api/smelters/', text: 'smelterName', query: 'hedge__smelter__name__in', itemValue: 'Stillwater', clearable: false, index: 1 },
      { label: 'Yard', path: '/api/yards/', text: 'yardName', query: 'check_in__yard__name__icontains', itemValue: null, clearable: true, index: 2 },
      { label: 'Lot', path: '/api/lots/', text: 'lotName', query: 'check_in__purchase__purchase_lots__lot__name__in', itemValue: null, clearable: true, index: 3 },
      { label: 'Lot-Shipment Summary Date From', text: 'sslStartDate', query: 'check_in__purchase__purchase_lots__lot__shipment_summary_lot__created_at__gte', itemValue: null, index: 4 },
      { label: 'Lot-Shipment Summary Date To', text: 'sslEndDate', query: 'check_in__purchase__purchase_lots__lot__shipment_summary_lot__created_at__lte', itemValue: null, index: 5 }
    ],
    totalOz: { t_pt: 0, t_pd: 0, t_rh: 0 },
    // Allocation Page
    checkIns: [],
    hedges: [],
    checkInCount: 0,
    hedgeCount: 0,
    defaultPPM: null,
    // Allocation Page Filters
    ciAllocationStatus: true,
    ciStartDate: '',
    ciEndDate: '',
    ciIdSet: [],
    purchaseSet: [],
    yardSet: [],
    createdBySet: [],
    designationSet: [],
    statusSet: [],
    hedgeStartDate: '',
    hedgeEndDate: '',
    hedgeIdSet: [],
    smelterSet: [],
    checkInPage: 1,
    hedgePage: 1
  },
  getters: {
    // Report Page
    getAllocations: state => state.allocations,
    getAllocationCount: state => state.allocationCount,
    getAllocationPage: state => state.allocationPage,
    getReportFilters: state => state.reportFilters,
    getTotalOz: state => state.totalOz,
    // Allocation Page
    getCheckIns: state => state.checkIns,
    getCICount: state => state.checkInCount,
    getHedges: state => state.hedges,
    getHedgeCount: state => state.hedgeCount,
    getDefaultPPM: state => state.defaultPPM,
    // FilterDialog
    getCheckInPage: state => state.checkInPage,
    getCiAllocationStatus: state => state.ciAllocationStatus,
    getCiStartDate: state => state.ciStartDate,
    getCiEndDate: state => state.ciEndDate,
    getCiIdSet: state => state.ciIdSet,
    getPurchaseSet: state => state.purchaseSet,
    getYardSet: state => state.yardSet,
    getCreatedBySet: state => state.createdBySet,
    getDesignationSet: state => state.designationSet,
    getStatusSet: state => state.statusSet,
    getHedgePage: state => state.hedgePage,
    getHedgeStartDate: state => state.hedgeStartDate,
    getHedgeEndDate: state => state.hedgeEndDate,
    getHedgeIdSet: state => state.hedgeIdSet,
    getSmelterSet: state => state.smelterSet
  },
  mutations: {
    // Report Page
    setAllocations (state, allocations) {
      state.allocations = allocations
    },
    setAllocationCount (state, allocationCount) {
      state.allocationCount = allocationCount
    },
    setAllocationPage (state, allocationPage) {
      state.allocationPage = allocationPage
    },
    setReportFilters (state, reportFilters) {
      state.reportFilters = reportFilters
    },
    setTotalOz (state, totalOz) {
      state.totalOz = totalOz
    },
    // Allocation Page
    setCheckIns (state, checkIns) {
      state.checkIns = checkIns
    },
    setCICount (state, checkInCount) {
      state.checkInCount = checkInCount
    },
    setHedges (state, hedges) {
      state.hedges = hedges
    },
    setHedgeCount (state, hedgeCount) {
      state.hedgeCount = hedgeCount
    },
    setDefaultPPM (state, defaultPPM) {
      state.defaultPPM = defaultPPM
    },
    // Filters
    setCheckInPage (state, checkInPage) {
      state.checkInPage = checkInPage
    },
    setCiAllocationStatus (state, ciAllocationStatus) {
      state.ciAllocationStatus = ciAllocationStatus
    },
    setCiStartDate (state, ciStartDate) {
      state.ciStartDate = ciStartDate
    },
    setCiEndDate (state, ciEndDate) {
      state.ciEndDate = ciEndDate
    },
    setCiIdSet (state, ciIdSet) {
      state.ciIdSet = ciIdSet
    },
    setPurchaseSet (state, purchaseSet) {
      state.purchaseSet = purchaseSet
    },
    setYardSet (state, yardSet) {
      state.yardSet = yardSet
    },
    setCreatedBySet (state, createdBySet) {
      state.createdBySet = createdBySet
    },
    setDesignationSet (state, designationSet) {
      state.designationSet = designationSet
    },
    setStatusSet (state, statusSet) {
      state.statusSet = statusSet
    },
    setHedgePage (state, hedgePage) {
      state.hedgePage = hedgePage
    },
    setHedgeStartDate (state, hedgeStartDate) {
      state.hedgeStartDate = hedgeStartDate
    },
    setHedgeEndDate (state, hedgeEndDate) {
      state.hedgeEndDate = hedgeEndDate
    },
    setHedgeIdSet (state, hedgeIdSet) {
      state.hedgeIdSet = hedgeIdSet
    },
    setSmelterSet (state, smelterSet) {
      state.smelterSet = smelterSet
    }
  },
  actions: {
    // Gets paginated allocations based on filters
    async fetchCheckInAllocations ({ commit, state }) {
      try {
        let params = state.reportFilters.reduce((list, f) => {
          list[f.query] = f.itemValue
          return list
        }, {})
        params['page'] = state.allocationPage
        let response = await axios.get('/api/check_in_allocations/', { params })
        commit('setAllocations', response.data.results)
        commit('setAllocationCount', response.data.count)
        return response.data.results
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to fetch Allocations', { root: true })
      }
    },
    // Gets all allocations with filters to find total available oz for checkin hedge total oz
    async fetchAllAllocations ({ commit, state }) {
      try {
        let params = state.reportFilters.reduce((list, f) => {
          list[f.query] = f.itemValue
          return list
        }, {})
        params['page_size'] = 0
        await axios.get('/api/check_in_allocations/', { params }).then((res) => {
          let totals = res.data.reduce((accu, allocation) => {
            accu['t_pt'] += (allocation.pt - allocation.applied.pt)
            accu['t_pd'] += (allocation.pd - allocation.applied.pd)
            accu['t_rh'] += (allocation.rh - allocation.applied.rh)
            return accu
          }, { t_pt: 0, t_pd: 0, t_rh: 0 })
          commit('setTotalOz', totals)
        })
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to fetch Allocations', { root: true })
      }
    },
    gatherCiFilters ({ state }) {
      let filters = {
        'page': state.checkInPage,
        'checkinallocation__isnull': state.ciAllocationStatus,
        'id__in': state.ciIdSet.length > 1 ? state.ciIdSet.join(',') : state.ciIdSet.toString(),
        'created_at__gte': state.ciStartDate,
        'created_at__lte': state.ciEndDate,
        'purchase__id__in': state.purchaseSet.length > 1 ? state.purchaseSet.join(',') : state.purchaseSet.toString(),
        'yard__name__in': state.yardSet.length > 1 ? state.yardSet.join(',') : state.yardSet.toString(),
        'created_by__username__in': state.createdBySet.length > 1 ? state.createdBySet.join(',') : state.createdBySet.toString(),
        'purchase__designation__in': state.designationSet.length > 1 ? state.designationSet.join(',') : state.designationSet.toString(),
        'status__in': state.statusSet.length > 1 ? state.statusSet.join(',') : state.statusSet.toString()
      }
      return filters
    },
    gatherHedgeFilters ({ state }) {
      let filters = {
        'page': state.hedgePage,
        'created_at__gte': state.hedgeStartDate,
        'created_at__lte': state.hedgeEndDate,
        'id__in': state.hedgeIdSet.length > 1 ? state.hedgeIdSet.join(',') : state.hedgeIdSet.toString(),
        'smelter__name__in': state.smelterSet.length > 1 ? state.smelterSet.join(',') : state.smelterSet.toString()
      }
      return filters
    },
    async deleteAllocation ({ commit }, params) {
      try {
        const response = await axios.delete(params)
        return response.status
      } catch (err) {
        commit(`setSnackbarError', 'An error occurred trying to delete the allocation`, { root: true })
      }
    },
    async createAllocation ({ commit }, params) {
      try {
        await axios.post('/api/check_in_allocations/', params)
      } catch (err) {
        commit(`setSnackbarError', 'An error occurred trying to add Check-In ${params.check_in_id} to Hedge ${params.hedge_id}`, { root: true })
      }
    },
    async updateAllocation ({ commit }, params) {
      try {
        const response = await axios.patch(`/api/check_in_allocations/${params.id}/`, params)
        return response.status
      } catch (err) {
        commit(`setSnackbarError', 'An error occurred trying to update the allocation`, { root: true })
      }
    },
    async fetchCheckIns ({ commit, dispatch }) {
      try {
        const filters = await dispatch('gatherCiFilters')
        const response = await axios.get('/api/check_ins/', { params: filters })
        commit('setCheckIns', response.data.results)
        commit('setCICount', response.data.count)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get check ins', { root: true })
      }
    },
    async fetchHedges ({ commit, dispatch }) {
      try {
        const filters = await dispatch('gatherHedgeFilters')
        const response = await axios.get('/api/hedges/', { params: filters })
        commit('setHedges', response.data.results)
        commit('setHedgeCount', response.data.count)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get hedges', { root: true })
      }
    },
    async fetchDefaultPPM ({ commit }) {
      try {
        const response = await axios.get('/api/ppm_calculations/default/')
        commit('setDefaultPPM', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get hedges', { root: true })
      }
    }
  }
}
