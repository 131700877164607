<!--
Simple and easy to use edit dialog
  ?max-width=800
  v-model="dialog"
  ?headline-text
  ?show-decline
  ?decline-text
  ?accept-text
  ?inputs-valid
  $emit('accept')
/>
@click="$emit('input', false)" - close dialog
default slot and random action button slot (#actionBtn, actionBtnEvent)
-->
<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :draggable="true"
    :value="value"
    @input="$emit('input', $event)"
    :max-width="maxWidth"
    scrollable
  >
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          flat
          dense
          dark
        >
          <v-toolbar-title>
            {{ headlineText }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              @click="$emit('input', false)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pt-4">
        <slot />
      </v-card-text>
      <v-card-actions>
        <slot name="actionBtn" />
        <v-spacer />
        <v-btn
          v-if="showDecline"
          text
          @click="$emit('input', false)"
        >
          {{ declineText }}
        </v-btn>
        <v-btn
          :disabled="!inputsValid"
          color="primary"
          @click="accept"
        >
          {{ acceptText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EditDialog',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    maxWidth: {
      default: 800
    },
    value: {
      type: Boolean,
      default: false
    },
    headlineText: {
      type: String,
      default: 'Edit'
    },
    showDecline: {
      type: Boolean,
      default: true
    },
    declineText: {
      type: String,
      default: 'Cancel'
    },
    acceptText: {
      type: String,
      default: 'Save'
    },
    inputsValid: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    accept () {
      this.$emit('accept', true)
      this.$emit('input', false)
    }
  }
}
</script>
