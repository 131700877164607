import { honeycombLogoPng, pgmLogoPng } from '@/assets/imagesBase64Str'

const PGM = {
  name: 'PGM',
  logo: 'https://trackkat-static.s3.amazonaws.com/pgm-logo.png',
  address: '4857 W Van Buren St|Phoenix, AZ 85043',
  shipping: '3103 W Lincoln St|Phoenix, AZ 85009',
  phone: '(602) 258-6500',
  png: pgmLogoPng
}

const HC = {
  name: 'Honeycomb',
  logo: 'https://trackkat-static.s3.amazonaws.com/honeycomb-logo.png',
  address: '2185 Montee Masson|Laval, QC H7E 4P2',
  shipping: '2185 Montee Masson|Laval, QC H7E 4P2',
  phone: '(602) 258-6500',
  png: honeycombLogoPng
}
export const CONFIGS = {
  PGM,
  HC
}

const HOST_CONFIG = {
  'app.trackkat.com': Object.assign({}, PGM, {
    api: 'https://app.backend.trackkat.com',
    name: 'Trackkat'
  }),
  'honeycomb.trackkat.com': Object.assign({}, HC, {
    api: 'https://honeycomb-api.trackkat.com',
    name: 'Honeycomb'
  }),
  'sandbox.trackkat.com': Object.assign({}, PGM, {
    api: 'https://sandbox.backend.trackkat.com',
    name: 'Sandbox'
  }),
  'localhost': Object.assign({}, PGM, {
    api: 'http://localhost:8000',
    name: 'Development'
  })
}

let config = HOST_CONFIG['localhost']
let url = new URL(window.location.href)
if (HOST_CONFIG[url.hostname]) config = HOST_CONFIG[url.hostname]

export default config
