import axios from 'axios'

export default {
  namespaced: true,
  state: () => ({
    transit: null,
    boxes: [] // TransitBoxes{Inventory[]}
  }),
  getters: {
    // gets possible origins based on this transit
    getOriginOptions (state) {
      const m = new Map()
      state.transit.manifests.forEach(req => {
        if (req.current_location) m.set(req.current_location.url, req.current_location)
      })
      state.transit.check_ins.forEach(req => {
        if (req.current_location) m.set(req.current_location.url, req.current_location)
      })
      return Array.from(m.values())
    },
    // returns true if changes are prohibited to attachments
    attachmentsLocked (state) {
      if (!state.transit) return true
      const whitelist = ['NE', 'IT']
      return !whitelist.includes(state.transit.status)
    },
    // returns true if the skid count matches number of boxes
    skidCountsMatch (state, getters) {
      if (getters.transitProperties.skids === 0) return true
      return getters.transitProperties.skids === state.boxes.length
    },
    // returns true if total weight is within allowed variance
    withinAllowedWeightVariance (state, getters) {
      if (getters.transitProperties.weight === 0) return true
      const allowedVariance = 125 * getters.transitProperties.skids
      const variance = Math.abs(getters.transitProperties.weight - getters.totalReceivedWeight)
      return variance <= allowedVariance
    },
    transitProperties (state) {
      const props = { weight: 0, skids: 0 }
      const accum = function (x) {
        props.weight += Number(x.transit_properties.weight)
        props.skids += Number(x.transit_properties.skids)
      }
      state.transit.check_ins.forEach(accum)
      state.transit.manifests.forEach(accum)
      return props
    },
    // Generates a list of skids based on what isn't represented in boxes
    unassignedSkids (state) {
      return []
    },
    // cumulative weight of boxes
    totalReceivedWeight (state) {
      return state.boxes.reduce((total, box) => total + Number(box.weight), 0)
    },
    // Converters the this.boxes into table formatted rows
    boxRows (state) {
      return state.boxes.map((box, index) => {
        const checkinsData = box.inventory.check_ins_data
        const checkinIds = []
        const yardNames = []
        checkinsData.map((ci) => {
          checkinIds.push(ci.id)
          yardNames.push(ci.yard_name)
        })
        return {
          url: box.url,
          index,
          name: box.inventory.name,
          contains: box.inventory.contains,
          checkinIds: checkinIds.join(', '),
          yardNames: yardNames.join(', '),
          gpsNumber: box.inventory.gps_number,
          weight: box.weight,
          meta: box
        }
      })
    },
    transit (state) {
      return state.transit
    },
    boxes (state) {
      return state.boxes
    }
  },
  mutations: {
    addBox (state, box) {
      state.boxes.push(box)
    },
    setBoxes (state, boxes) {
      state.boxes = boxes
    },
    removeBox (state, boxUrl) {
      state.boxes = state.boxes.filter(b => b.url !== boxUrl)
    },
    setBox (state, box) {
      let index = state.boxes.findIndex(ob => ob.url === box.url)
      if (index === -1) {
        state.boxes.push(box)
      } else {
        state.boxes[index] = box
        state.boxes = [...state.boxes]
      }
    },
    setTransit (state, transit) {
      state.transit = transit
      state.boxes = []
    }
  },
  actions: {
    async refreshTransit ({ commit }, transitUrl) {
      const resTransit = await axios.get(transitUrl)
      if (resTransit.status === 200) {
        commit('setTransit', resTransit.data)
        commit('transitDashboardStore/addTransit', resTransit.data, { root: true })
      }
    },
    /**
     * Patches the transit to edit and then manages removing/adding transit requests as needed.
     * @param state
     * @param commit
     * @param payload
     * @return {Promise<AxiosResponse<any>>}
     */
    async patchTransit ({ state, commit }, payload) {
      const transit = state.transit
      if (!transit) {
        this.commit('setSnackbarError', 'unable to update transit (undefined)', { root: true })
        return
      }
      const r = await axios.patch(transit.url, payload)
      if (r.status !== 200) return r
      commit('setTransit', r.data)
      commit('transitDashboardStore/addTransit', r.data, { root: true })
      if (payload.attach_requests) {
        payload.attach_requests.forEach(request => {
          commit('transitDashboardStore/requests/removeRequestTransit', request, { root: true })
        })
      }
      if (payload.detach_requests) {
        payload.detach_requests.forEach(request => {
          commit('transitDashboardStore/requests/addTransitRequest', request, { root: true })
        })
      }
      return r
    },
    async updateBox ({ commit, state }, { box, weight }) {
      try {
        const res = await axios.patch(box.url, { weight: weight })
        if (res.status === 200) {
          commit('setBox', res.data)
        }
        return res
      } catch (err) {
        commit('setSnackbarError', 'failed to update box', { root: true })
      }
    },
    /**
     * Create boxes accepts a payload
     * @param commit
     * @param state
     * @param payload
     * @param payload.check_ins{[CheckIns]}
     * @param payload.batch{Number}
     * @return {Promise<void>}
     */
    async createBoxes ({ commit, state }, payload) {
      const res = await axios.post(state.transit.url + 'create_boxes/', payload)
      if (res.status === 201) {
        res.data.forEach(box => {
          commit('setBox', box)
        })
      }
      return res
    },
    /**
     * Performs a group merge box for TransitBox
     * @param commit
     * @param destinationBox
     * @param boxes
     * @return {Promise<void>}
     */
    async mergeBoxes ({ commit }, { destinationBox, boxes }) {
      const res = await axios.patch(destinationBox.url + 'merge_boxes/', {
        boxes: boxes.map(x => x.id).join(',')
      })
      if (res.status !== 400) {
        commit('setSnackbarSuccess', 'successfully merged boxes', { root: true })
        // remove merged boxes and update existing box
        boxes.forEach(box => {
          commit('removeBox', box.url)
        })
        commit('setBox', res.data)
      }
    },
    /**
     * Deletion of the TransitBox is primarily soft deletion of the connected inventory box
     * @param commit
     * @param state
     * @param transitBox
     * @return {Promise<AxiosResponse<any>>}
     */
    async deleteBox ({ commit, state }, transitBox) {
      const res = await axios.patch(transitBox.inventory.url, { status: 'DE' })
      if (res.status === 200) {
        commit('removeBox', transitBox.url)
      }
      return res
    },
    /**
     * Fetches boxes (using get)
     * @param commit
     * @param state
     * @return {Promise<void>}
     */
    async fetchBoxes ({ commit, state }) {
      const res = await axios.get(state.transit.url + 'populate_boxes/')
      if (res.data) commit('setBoxes', res.data)
    },
    /**
     * Calls the transit dashboard delete method which removes it from the dashboard
     * @param dispatch
     * @param state
     * @return {*}
     */
    deleteTransit ({ dispatch, commit, state }) {
      const r = dispatch('transitDashboardStore/deleteTransit', state.transit, { root: true })
      commit('setTransit', null)
      commit('setBoxes', [])
      return r
    },
    /**
     * Dispatches transitDashboard/receiveTransit
     * @param commit
     * @param state
     * @param dispatch
     * @return {Promise<void>}
     */
    async receiveTransit ({ commit, state, dispatch }) {
      const r = dispatch('transitDashboardStore/patchTransit', {
        transitUrl: state.transit.url,
        payload: { status: 'DE' }
      }, { root: true })
      commit('setTransit', null)
      commit('setBoxes', [])
      return r
    },
    async completeTransit ({ commit, state, dispatch }) {
      const r = dispatch('transitDashboardStore/patchTransit', {
        transitUrl: state.transit.url,
        payload: { status: 'CO' }
      }, { root: true })
      commit('setTransit', null)
      commit('setBoxes', [])
      return r
    }
  }
}
