import axios from 'axios'

export default {
  namespaced: true,
  state: {
    regions: []
  },
  getters: {
    regions: state => state.regions
  },
  mutations: {
    setRegions (state, regions) {
      state.regions = regions
    },
    appendRegions (state, regions) {
      state.regions = [...state.regions, ...regions]
    }
  },
  actions: {
    /**
     * Get all regions in the database
     * @param {commit} commit - access to store setters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchRegions ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/regions/', { params })
        if (params && params.page && params.page > 1) commit('appendRegions', response.data.results)
        else commit('setRegions', response.data.results)
        return response.data.count
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get regions', { root: true })
      }
    }
  }
}
