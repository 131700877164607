import axios from 'axios'
import checkInEditStore from './checkInEditStore'

export default {
  namespaced: true,
  state: {
    checkIns: [],
    checkIn: {},
    checkInLines: [],
    images: [],
    setup: []
  },
  getters: {
    getStatusesMethod: (state, getters, rootState, rootGetters) => {
      return function (checkIn) {
        return rootGetters['getCheckInStatuses'].find(s => s.value === checkIn.status)
      }
    },
    checkInSetup: state => state.setup,
    checkInLines: state => state.checkInLines,
    checkIns: state => state.checkIns,
    checkIn: state => state.checkIn,
    images: state => state.images,
    getStatuses: (state, getters, rootState, rootGetters) => rootGetters['getCheckInStatuses']
  },
  mutations: {
    setCheckIns (state, checkIns) {
      state.checkIns = checkIns
    },
    appendCheckIns (state, checkIns) {
      state.checkIns = [...state.checkIns, ...checkIns]
    },
    setCheckIn (state, checkIn) {
      state.checkIn = checkIn
    },
    setCheckInImages (state, images) {
      state.images = images
    },
    setCheckInLines (state, checkInLines) {
      state.checkInLines = checkInLines
    },
    __setSetup (state, setup) {
      state.setup = setup
    }
  },
  actions: {
    /**
     * Get the initial setup catalog items for a check in
     * @param {Object} commit - access to store setters
     */
    async fetchSetup ({ commit }) {
      try {
        const r = await axios.get('/api/check_in_setups/')
        commit('__setSetup', r.data.results)
      } catch {
        commit('setSnackbarError', 'Could not get check in setup', { root: true })
      }
    },
    /**
     * Get the converter prices for the current checkin setups
     * @param {Object} commit - access to store setters
     * @param {Object} body
     */
    async priceConverters ({ commit }, body) {
      try {
        const setups = body.setups
        const promises = setups.map(setup => {
          const params = {}
          if (body.yard) params.yard = body.yard
          return axios.get(setup.catalog.converter.url + 'price/', { params })
        })
        const prices = await Promise.all(promises)
        const result = setups.reduce((accu, setup, index) => {
          accu.push({ ...setup, price: prices[index].data.price })
          return accu
        }, [])
        commit('__setSetup', result)
      } catch {
        commit('setSnackbarError', 'Could not get check in setup', { root: true })
      }
    },
    /**
     * Fetching all checkIns given a set of params
     * @param {Object} commit setting intakes to vuex
     * @param {Object || Null} params - params to search by or null for all check ins
     */
    async fetchCheckIns ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/check_ins/', { params })
        if (params && params.page && params.page > 1) commit('appendCheckIns', response.data.results)
        else commit('setCheckIns', response.data.results)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get check ins', { root: true })
      }
    },
    /**
     * Fetching all checkIn lines given a set of params
     * @param {Object} commit setting intakes to vuex
     * @param {Object || Null} params - params to search by or null for all check ins
     */
    async fetchCheckInLines ({ rootState, commit }, params = null) {
      try {
        const response = await axios.get('/api/check_in_lines/', { params })
        let results = response.data.results
        const totalPages = response.data.count / rootState.baseStoreModule.apiPageSize
        const promises = []
        for (let page = 2; page <= totalPages; page++) {
          params.page = page
          promises.push(axios.get('/api/check_in_lines/', { params }))
        }
        const responses = await Promise.all(promises)
        const newResults = responses.map(res => res.data.results).flat(1)
        results = [...results, ...newResults]
        commit('setCheckInLines', results)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get check in lines', { root: true })
      }
    },
    /**
     * Fetching checkIn by its id
     * @param {Object} commit - setting intakes to vuex
     * @param {Number} id - id of the check in to get
     */
    async fetchCheckIn ({ commit }, id) {
      try {
        const response = await axios.get(`/api/check_ins/${id}/`)
        const params = { check_in_id: id }
        const photos = await axios.get('/api/check_in_images/', { params })
        response.data.photos = photos.data
        commit('setCheckIn', response.data)
      } catch (err) {
        commit('setSnackbarError', `An error occurred while trying to get check in with id: ${id}`, { root: true })
      }
    },
    /**
     * Update a checkin by its id
     * @param {Object} commit setting intakes to vuex
     * @param {Object} body
     * @return {Integer | Null} id
     */
    async createCheckIn ({ commit }, body) {
      try {
        const response = await axios.post('/api/check_ins/', body)
        commit('setCheckIn', response.data)
        return response.data.id
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to create check in', { root: true })
      }
    },
    /**
     * Update a checkin by its id
     * @param {Object} commit setting intakes to vuex
     * @param {Object} dispatch
     * @param {Object} body
     * @return {Boolean} - was the update successful
     */
    async updateCheckIn ({ commit, dispatch }, body) {
      try {
        const id = body.id
        const formData = body.formData
        if (body.checkInSkids) {
          await dispatch('checkInSkidStore/createCheckInSkids', body.checkInSkids, { root: true })
        }
        const response = await axios.patch(`/api/check_ins/${id}/`, formData)
        commit('setCheckIn', response.data)
        return true
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to update check in', { root: true })
        return false
      }
    },
    /**
     * Triggers the send emails action for a check in
     * @param _
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    sendCheckInEmail (_, { id }) {
      return axios.post(`/api/check_ins/${id}/send_emails/`)
    },
    async createCheckInImage ({ commit }, params) {
      try {
        const res = await axios.post('/api/check_in_images/', { check_in: params.ciUrl, check_in_id: params.ciId, name: params.imageName })
        return res.data
      } catch (err) {
        commit('setSnackbarError', `Unable to create upload destination for ${params.imageName}`, { root: true })
        return false
      }
    },
    async getImageUrl ({ commit }, ciImage) {
      try {
        const res2 = await axios.get(ciImage.url + 'upload_url/')
        return res2.data
      } catch (err) {
        commit('setSnackbarError', 'Unable to get upload url', { root: true })
        return false
      }
    },
    async putAwsFile ({ commit }, params) {
      try {
        // image upload with headers
        await fetch(params.ciImageUpload.upload_url, {
          method: 'PUT',
          headers: {
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Origin': '*',
            'x-amz-acl': 'private',
            'Content-Type': 'image/jpeg'
          },
          body: params.image
        }).then(async () => {
          await axios.patch(params.url, {
            upload_reference: params.ciImageUpload.base_url
          })
        })
      } catch (err) {
        commit('setSnackbarError', 'Unable to upload image', { root: true })
        return false
      }
    },
    /**
     * Fetch all check in images given a set of params
     * @param {Object} commit - access to store setters
     * @param {Object} params
     */
    async fetchCheckInImages ({ commit }, params) {
      try {
        const response = await axios.get('/api/check_in_images/', { params })
        commit('setCheckInImages', response.data.results)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred uploading photos', { root: true })
      }
    },
    /**
     * Create a new entry in navision given input from the user
     * @param {Object} commit
     * @param {Object} body
     */
    async exportToNavision ({ commit }, body) {
      try {
        await axios.post('/api/export_check_in_to_navision/', body)
        commit('setSnackbarSuccess', 'Successfully created invoice in Navision', { root: true })
      } catch (err) {
        commit('setSnackbarError', 'An error occurred uploading photos', { root: true })
      }
    },
    async deleteCheckIn ({ commit }, id) {
      try {
        let res = await axios.delete(`/api/check_ins/${id}/`)
        commit('setSnackbarSuccess', `Successfully deleted Check-In ${id}`, { root: true })
        return res
      } catch (err) {
        commit('setSnackbarError', 'An error occurred deleting the Check-In', { root: true })
      }
    }
  },
  modules: {
    edit: checkInEditStore
  }
}
