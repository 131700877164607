<!--
App Level Component
Once activated, warns the user of a pending batch promise if they try to
close or refresh their browser until deactivated

How to use:
  In the component handling the promise:
    to activate call -> this.$store.commit('setBatchInProgress', true)
    to deactivate call -> this.$store.commit('setBatchInProgress', false)
-->
<template>
  <v-overlay :value="true">
    <v-card>
      <v-card-text>
        <p>
          Trackkat is working in the background, please do not close or refresh your browser
        </p>
        <v-progress-linear
          indeterminate
          color="red accent-3"
        />
        <!-- progress bar mathematically tracking the completion of promises -->
        <!-- <v-progress-linear
          v-model="progressValue"
          color="red accent-3"
        /> -->
      </v-card-text>
    </v-card>
  </v-overlay>
</template>
<script>
export default {
  name: 'BatchProgressOverlay',
  // data: () => ({
  //   progressValue: 0 // move this value to the store
  // }),
  // Set up guards to prevent browser from closing or refreshing while file upload is in progress
  beforeMount () {
    window.addEventListener('beforeunload', this.preventNav)
  },
  // Remove guards to prevent browser from closing or refreshing while file upload is in progress
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.preventNav)
  },
  methods: {
    // guard that restricts closing or refreshing trackkat
    preventNav (e) {
      e.preventDefault()
      // Chrome requires returnValue to be set
      e.returnValue = ''
    }
  }
}
</script>
