import factories from '../generics/factories'
const { factory, createMixin, fetchMixin, patchMixin, deleteMixin } = factories

const store = factory(
  {
    entityName: 'Trip',
    urlTemplate: id => id ? `api/trips/${id}/` : `api/trips/`,
    state: {
      statuses: [
        { value: 'PE', text: 'pre', color: '' },
        { value: 'PO', text: 'proposed', color: '' },
        { value: 'AP', text: 'approved', color: '' },
        { value: 'CP', text: 'completion-proposed', color: '' },
        { value: 'CO', text: 'complete', color: '' },
        { value: 'DE', text: 'declined', color: '' }
      ]
    }
  },
  createMixin,
  fetchMixin,
  patchMixin,
  deleteMixin
)

export default store
