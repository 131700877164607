<template>
  <v-app>
    <app-navigation
      v-if="$auth.isAuthenticated"
      class="d-print-none"
      v-model="showNavigation"
    />
    <app-toolbar
      class="d-print-none"
    >
      <template v-slot:left>
        <v-app-bar-nav-icon
          v-if="$auth.isAuthenticated"
          @click="showNavigation = !showNavigation"
        />
      </template>
      <template v-slot>
        <v-fade-transition>
          <authenticated-toolbar-items v-if="$auth.isAuthenticated" />
        </v-fade-transition>
      </template>
    </app-toolbar>
    <batch-progress-overlay
      v-if="batchInProgress"
    />
    <v-main class="print-main">
      <v-container
        class="print-main"
        fluid
      >
        <app-loading-indicator v-if="$store.getters['loading']" />
        <app-alert />
        <router-view
          v-if="$auth.isAuthenticated"
          :key="$route.fullPath"
        />
        <landing v-else />
        <app-snackbar />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppToolbar from './base/components/AppToolbar'
import AppNavigation from './base/components/AppNavigation'
import AppAlert from './base/components/AppAlert'
import AppSnackbar from './base/components/AppSnackbar'
import AuthenticatedToolbarItems from '@/base/components/AuthenticatedToolbarItems'
import BatchProgressOverlay from '@/base/components/BatchProgressOverlay'
import { mapGetters } from 'vuex'
import { updateAndFlushAbortController } from '@/plugins/requestAbortController'
import Landing from '@/views/Landing.vue'

export default {
  name: 'App',
  components: {
    Landing,
    AuthenticatedToolbarItems,
    AppToolbar,
    AppAlert,
    AppNavigation,
    AppSnackbar,
    BatchProgressOverlay
  },
  data: () => ({
    dialog: false,
    showNavigation: true
  }),
  computed: {
    ...mapGetters(['batchInProgress'])
  },
  watch: {
    '$auth.user.dark_theme' (value) {
      this.$vuetify.theme.dark = value
    },
    '$route.fullPath': {
      handler (fullPath) {
        updateAndFlushAbortController(fullPath)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * Allows for the binding of app shortcuts
     * @param key
     */
    shortcutHandler (key) {
      if (key.keyCode === 192) {
        if (key.shiftKey) this.$store.commit('setDocumentSearchDialog', true)
      }
    },
    clearOldLoaderTokens (minutes) {
      this.$logger.debug(`Setting Interval checks every 15sec for clearLoadersOldTokens(${minutes}/min)`)
      setInterval(() => {
        this.$store.commit('clearLoadersOldTokens', minutes)
      }, 15000) // check every 15 seconds
    },
    /**
     * Redirects users to the current version of trackkat at app.trackkat if they landed on www.
     * Can be removed at a future date
     */
    redirectToAppIfW3 (config) {
      let theUrl = new URL(window.location.href)
      if (theUrl.hostname in config) {
        const r = config[theUrl.hostname]
        this.$logger.info(`Redirecting user to %${r} due to config redirect value`)
        theUrl.hostname = r
        // Optional: To automatically redirect, uncomment the following line
        window.location.replace(theUrl)
      }
    },
    /**
     * Gets the static application config file, this file is optional
     * @returns {Promise<unknown>}
     */
    async fetchConfig () {
      return new Promise((resolve) => {
        return fetch('https://trackkat-static.s3.amazonaws.com/static/config.json')
          .then(response => response.json()).then(resolve)
          .catch(error => {
            this.$logger.warning(error)
            resolve({})
          })
      })
    }
  },
  async created () {
    // Loading the application CONFIG file
    const config = await this.fetchConfig()
    if (config['redirects']) this.redirectToAppIfW3(config['redirects'])

    const component = this
    this.handler = function (e) {
      component.$emit('keydown', e)
    }
    window.addEventListener('keydown', this.shortcutHandler)
  },
  mounted () {
    this.$store.dispatch('fetchConfig')
    this.clearOldLoaderTokens(10)
    this.$store.commit('setLoading', false)
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.handler)
  }
}
</script>
<!--vgs gallery fixes lightbox problem where preview images appear at 100%-->
<style>
.vgs__gallery__container {
  width: auto !important;
}

.pt-color {
  font-weight: bold;
  color: #0068b9;
}

.pd-color {
  font-weight: bold;
  color: #d00e00;
}

.rh-color {
  font-weight: bold;
  color: #006104;
}

.theme--dark .pt-color {
  color: #6db0ff;
}

.theme--dark .pd-color {
  color: #ff8f90;
}

.theme--dark .rh-color {
  color: #1fffb5;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #FF1744;
  /*border: 2px solid #555555;*/
}

.dense-filled .v-input__slot {
  background: rgba(0, 0, 0, 0.06);
  padding-left: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.theme--dark .dense-filled .v-input__slot {
  background: rgba(255, 255, 255, 0.08);
}

@media print {
  .print-main {
    padding: 0 !important;
    margin: 0 !important;
  }
}
</style>
