import axios from 'axios'

export default {
  namespaced: true,
  state: {
    ppmCalculations: [],
    ppmCalculation: {},
    ppmCalculationValues: {},
    estimatedReturnable: [],
    calculatedValues: {}
  },
  getters: {
    ppmCalculations: state => state.ppmCalculations,
    ppmCalculation: state => state.ppmCalculation,
    ppmCalculationValues: state => state.ppmCalculationValues,
    estimatedReturnable: state => state.estimatedReturnable,
    calculatedValues: state => state.calculatedValues
  },
  mutations: {
    setPpmCalculations (state, ppmCalculations) {
      state.ppmCalculations = ppmCalculations
    },
    appendPpmCalculations (state, ppmCalculations) {
      state.ppmCalculations = [...state.ppmCalculations, ...ppmCalculations]
    },
    setPpmCalculation (state, ppmCalculation) {
      state.ppmCalculation = ppmCalculation
    },
    setCalculatedValues (state, calculatedValues) {
      state.calculatedValues = calculatedValues
    }
  },
  actions: {
    async calculatePpmValues ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/ppm_calculations/calculate/', { params })
        commit('setCalculatedValues', response.data)
      } catch (err) {}
    },
    /**
     * Gets all ppmCalculations
     * @param {commit} commit sets ppmCalculation objects to store
     * @param {Object} params - optional filters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchPpmCalculations ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/ppm_calculations/', { params })
        if (params && params.page && params.page > 1) commit('appendPpmCalculations', response.data.results)
        else commit('setPpmCalculations', response.data.results)
        return response.data.count
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to get the PPM Calculations', { root: true })
      }
    },
    /**
     * For individual ppmCalculation page, retrieves ppmCalculation information based off id
     * @param {object} commit Sets indicated ppmCalculation information
     * @param {Int} id Id of selected ppmCalculation
     */
    async fetchPpmCalculation ({ commit }, id) {
      try {
        const response = await axios.get(`/api/ppm_calculations/${id}/`)
        commit('setPpmCalculation', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to load this PPM Calculation', { root: true })
      }
    },
    /**
     * Update the given ppm calculator
     * @param {Object} commit Sets indicated ppmCalculation information
     * @param {Object} body
     */
    async updatePpmCalculation ({ commit, dispatch }, body) {
      try {
        let response = await axios.patch(`/api/ppm_calculations/${body.id}/`, body)
        commit('setPpmCalculation', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to load this PPM Calculation', { root: true })
        return false
      }
    },
    /**
     * Only name required to create PPM
     * @param {object} commit Sets indicated ppmCalculation information
     * @param {Object} body
     */
    async createPpmCalculation ({ commit }, body) {
      try {
        const response = await axios.post('/api/ppm_calculations/', body)
        commit('setPpmCalculation', response.data)
        return response.data.id
      } catch (err) {
        commit('setSnackbarError', 'An error occurred trying to load this PPM Calculation', { root: true })
      }
    }
  }
}
