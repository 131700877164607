<template>
  <v-icon x-small>
    mdi-currency-usd
  </v-icon>
</template>

<script>
export default {
  name: 'DollarSign'
}
</script>
