import axios from 'axios'

export default {
  namespaced: true,
  state: {
    nextResultsUrl: null,
    resultCount: 0,
    selectedConverters: [],
    searchResults: []
  },
  getters: {
    getSearchResults (state) {
      const selected = state.selectedConverters.map(s => s.id)
      return state.searchResults.map(r => {
        r.selected = selected.includes(r.id)
        return r
      })
    },
    getSelectedConverters (state) {
      return state.selectedConverters
    },
    // Get assays for selected converters
    getAssays (state) {
      let assays = []
      state.selectedConverters.forEach(c => {
        if (c.converter && c.converter.assay_details) {
          c.converter.assay_details.forEach(d => {
            d.catalog_id = c.id
            assays.push(d)
          })
        }
      })
      return assays
    },
    getAssayAverages (state, getters) {
      let averages = {
        pt_ppm: 0,
        pd_ppm: 0,
        rh_ppm: 0,
        yield_lbs: 0
      }
      getters.getAssays.forEach(a => {
        averages.pt_ppm += Number(a.pt_ppm)
        averages.pd_ppm += Number(a.pd_ppm)
        averages.rh_ppm += Number(a.rh_ppm)
        averages.yield_lbs += Number(a.yield_lbs)
      })
      const assayLength = getters.getAssays.length
      const avg = (val) => {
        let result = 0
        if (val !== 0) result = val / assayLength
        return result.toFixed(3)
      }
      averages.pt_ppm = avg(averages.pt_ppm)
      averages.pd_ppm = avg(averages.pd_ppm)
      averages.rh_ppm = avg(averages.rh_ppm)
      averages.yield_lbs = avg(averages.yield_lbs)
      return averages
    },
    getConverterOptions (state) {
      let options = {
        code: [],
        converter_group_name: [],
        notes: [],
        o2_sensor: [],
        height: [],
        width: [],
        catalyst_weight: [],
        debris_weight: [],
        empty_shell_weight: [],
        whole_weight: [],
        picture_set: []
      }
      state.selectedConverters.forEach(catalog => {
        Object.keys(options).forEach(key => {
          if (!catalog.converter[key]) return
          if (options[key].includes(catalog.converter[key])) return
          options[key].push(catalog.converter[key])
        })
      })
      // make picture sets unique
      const uniqueImages = {}
      options.picture_set.forEach(ps => { uniqueImages[ps.url] = ps })
      options.picture_set = Object.values(uniqueImages)
      return options
    }
  },
  mutations: {
    addSelectedConverter (state, converter) {
      // ensure the key is not already added
      const f = state.selectedConverters.findIndex(s => s.id === converter.id)
      if (f === -1) state.selectedConverters.push(converter)
    },
    removeSelectedConverter (state, converter) {
      if (state.destinationConverter && state.destinationConverter.id === converter.id) {
        state.destinationConverter = null
      }
      state.selectedConverters = state.selectedConverters.filter(c => c.id !== converter.id)
    },
    clearSelectedConverters (state) {
      state.selectedConverters = []
    },
    clearSearchResults (state) {
      state.nextResultsUrl = null
      state.resultCount = 0
      state.searchResults = []
    },
    updateSearchResults (state, data) {
      state.nextResultsUrl = data.next
      state.resultCount = data.count
      state.searchResults = state.searchResults.concat(data.results)
    }
  },
  actions: {
    converterSearch ({ dispatch, commit }, search) {
      commit('clearSearchResults')
      dispatch('_converterSearch', '/api/catalogs/?search=' + search)
    },
    converterSearchNext ({ dispatch, state }) {
      if (state.nextResultsUrl) {
        dispatch('_converterSearch', state.nextResultsUrl)
      }
    },
    async _converterSearch ({ commit }, page) {
      const params = { material_type: 'CV', page_size: 10 }
      const res = await axios.get(page, { params })
      if (res.status === 200) commit('updateSearchResults', res.data)
    },
    async mergeSelectedCatalogs ({ commit, getters }, catalog) {
      const inboundCatalogs = getters.getSelectedConverters.filter(c => c.id !== catalog.id)
      for (const inbound of inboundCatalogs) {
        try {
          await axios.post(catalog.url + 'merge_catalog/', { 'inbound_catalog': inbound.id })
          commit('setSnackbarInfo', 'Successfully merged catalog #' + inbound.id, { root: true })
        } catch (err) {
          commit('setSnackbarError', 'Failed to merge catalog #' + inbound.id, { root: true })
        }
      }
      commit('clearSelectedConverters')
      commit('clearSearchResults')
      return true
    },
    async updateCatalogConverter ({ commit, state }, { catalog, payload }) {
      if (!catalog || !catalog.converter) {
        commit('setSnackbarWarning', 'Converter Not Found (could not update)', { root: true })
        return
      }
      return axios.patch(catalog.converter.url, payload)
    }
  }
}
