import axios from 'axios'

export default {
  namespaced: true,
  state: {
    smelters: [],
    smelter: {}
  },
  getters: {
    smelters: state => state.smelters,
    smelter: state => state.smelter
  },
  mutations: {
    setSmelters (state, smelters) {
      state.smelters = smelters
    },
    appendSmelters (state, smelters) {
      state.smelters = [...state.smelters, ...smelters]
    },
    setSmelter (state, smelter) {
      state.smelter = smelter
    }
  },
  actions: {
    /**
     * Fetch all smelters
     * @param {Object} commit - to call store setters
     * @param {Object} params - filters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchSmelters ({ commit }, params) {
      try {
        const response = await axios.get('/api/smelters/', { params })
        if (params && params.page && params.page > 1) commit('appendSmelters', response.data.results)
        else commit('setSmelters', response.data.results)
        return response.data.count
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get smelters', { root: true })
      }
    },
    /**
     * Fetch a smelter by its id
     * @param {Object} commit - to call store setters
     * @param {Number} id - id of the smelter to get
     */
    async fetchSmelter ({ commit }, id) {
      try {
        const response = await axios.get(`/api/smelters/${id}/`)
        commit('setSmelter', response.data)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred while trying to get smelter', { root: true })
      }
    },
    /**
     * Update a smelter
     * @param {Object} commit - to call store setters
     * @param {Object} body - id of the smelter and params to update
     */
    async updateSmelter ({ commit }, body) {
      try {
        const res = await axios.patch(`/api/smelters/${body.id}/`, body.params)
        commit('setSmelter', res.data)
      } catch (err) {
        commit('setSnackbarError', `An error occurred trying to update smelter #${body.id}`, { root: true })
      }
    }
  }
}
