import factories from '../generics/factories'
const { factory, createMixin, fetchMixin, patchMixin, deleteMixin } = factories

const store = factory(
  {
    entityName: 'Broker',
    urlTemplate: id => id ? `logistics/brokers/${id}/` : `logistics/brokers/`
  },
  createMixin,
  fetchMixin,
  patchMixin,
  deleteMixin
)

export default store
