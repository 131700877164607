import factories from '../generics/factories'
const { factory, createMixin, fetchMixin, patchMixin } = factories

const store = factory(
  {
    entityName: 'Location',
    urlTemplate: id => id ? `logistics/locations/${id}/` : `logistics/locations/`
  },
  createMixin,
  fetchMixin,
  patchMixin
)

export default store
