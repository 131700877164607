<!--
Shows the conversion marker if converted if the currency is the same as global then
marker is not shown if currency is the same as global currency no marker is show
-->

<template>
  <span class="text-no-wrap">
    <span
      v-if="$store.state.showFx && cur !== $auth.user.fx_currency"
      class="d-print-none"
    >
      <info-tooltip v-if="value !== null && cur">
        <template v-slot:wrap-me>
          <sup style="opacity: 50%">
            cv
          </sup>
        </template>
        <span>
          {{ value | formatCurrencyV(cur) }}
        </span>
      </info-tooltip>
      <sup
        v-else
        style="opacity: 50%"
      >
        dv
      </sup>
    </span>
    <slot>
      {{ fx | formatCurrencyV | currencyParentheses }}
    </slot>
  </span>
</template>

<script>

export default {
  props: {
    // The Converted dollar value
    fx: {
      type: [String, Number],
      default: null
    },
    // The Original Value
    value: {
      type: [String, Number],
      default: null
    },
    // The Currency of the original value
    cur: {
      type: String,
      default: null
    }
  }
}
</script>
