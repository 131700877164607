<template>
  <v-menu
    ref="dateDialog"
    v-model="dateDialog"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="dateFormatted"
        prepend-inner-icon="mdi-calendar"
        persistent-hint
        v-on="on"
        readonly
        clearable
        :label="label"
        :disabled="disabled"
        :error-messages="errorMessages"
        @click:clear="alterDate('')"
      />
    </template>
    <v-date-picker
      :value="value"
      :min="min"
      :max="max"
      no-title
      scrollable
      @input="alterDate"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'DateInput',
  props: {
    value: { type: String, required: false, default: null },
    label: { type: String, required: false, default: 'Date' },
    min: { type: String, required: false, default: null },
    max: { type: String, required: false, default: null },
    errorMessages: { type: Array, required: false, default: () => [] },
    disabled: { type: Boolean, required: false, default: false }
  },
  computed: {
    dateFormatted: {
      get: function () {
        return this.formatDate(this.value)
      },
      set: function (val) {
        return val
      }
    }
  },
  data: () => ({
    dateDialog: false
  }),
  methods: {
    /**
     * Format the date
     * @param {String} date
     */
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    /***
     * Alter the value of the value
     * @param {String} val
     */
    alterDate (val) {
      this.$emit('input', val)
      this.dateDialog = false
    }
  }
}
</script>
