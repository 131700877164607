/**
 * See interceptor Docs
 * https://github.com/axios/axios/#interceptors
 *
 * Set the noInterceptorError on the axios config to bypass error catching
 */
import store from '../store'
import router from '@/router'
import Vue from 'vue'

/**
 * Adds a interceptor token
 * @param config
 * @returns {Promise<*>}
 */
async function startLoaderSuccess (config) {
  store.commit('addLoadingBarToken', config.method.toUpperCase())
  return config
}

/**
 * Removes a interceptor token
 * @param response
 * @returns {Promise<*>}
 */
async function stopLoaderSuccess (response) {
  store.commit('clearLoadingToken', response.config.method.toUpperCase())
  return response
}

/**
 * Clears all loading tokens
 * @param error
 * @returns {Promise<never>}
 */
async function stopLoaderError (error) {
  store.commit('clearPreviousGetLoadingTokens')
  return Promise.reject(error)
}

/**
 * Intercepts errors from responses Codes outside 200
 * Canceled request should not produce an error, any error not a 403 is sent to the logger as an error
 * and notifies the user via snackbar
 * @param {Error} error
 * @returns {Promise<never>}
 */
function errorInterceptor (error) {
  if (error.config.noInterceptorError) return Promise.reject(error)
  if (error.code && error.code === 'ERR_CANCELED') {
    Vue.prototype.$logger.debug('ErrorInterceptor: ' + error.message)
  } else if (error.response && error.response.status === 500) {
    store.commit('setSnackbarError', error.message)
  } else if (error.response && error.response.status !== 403) {
    let msg = `${error?.response?.status} ${error?.config?.url} - ${error?.response?.data}`
    Vue.prototype.$logger.error('ErrorInterceptor: ' + msg)
    let dataMessage = error?.response?.data?.message
    let message = dataMessage || 'Server request failed.'
    store.commit('setSnackbarError', message)
  }
  return Promise.reject(error)
}

/**
 * Intercept 4xx requests
 * 403 redirects user to login
 * 401 should never occur since cookie is deleted when it expires
 * @param config
 * @returns {function(*=): Promise<never>}
 */
function authInterceptor ({ publicRoutes }) {
  return function (error) {
    if (error.config.noInterceptorError) return Promise.reject(error)
    const to = router.currentRoute
    if (!error.response) return Promise.reject(error)
    if (error.response.status === 403) {
      // redirect user to login if they receive a 403 on a non-public page
      const isPublic = publicRoutes.some(route => (route.name === to.name || route.path === to.path))
      if (isPublic) return
      // check if the response was thrown because a user session expired
      if (error.response.data.detail && error.response.data.detail === 'Authentication credentials were not provided.') {
        // get the auth store instance
        // Vue.prototype.$auth.logout()
        store.commit('setSnackbarError', 'Authentication Failed, please try again.')
        return
      }
      store.commit('setSnackbarWarning', 'Some data requested was forbidden.')
      return
    }
    if (error.response.status === 401) {
      store.commit('setSnackbarError', 'Authentication Failure 401.')
      return
    }
    return Promise.reject(error)
  }
}

/**
 * Intercepts 404 error
 * @param {Error} error
 * @returns {Promise<never>}
 */
function notFoundRedirect (error) {
  if (error.config.noInterceptorError) return Promise.reject(error)
  if (error.response && error.response.status && error.response.status === 404) {
    store.commit('setSnackbarError', 'Missing Resource 404')
    return null
  }
}

export default {
  errorInterceptor,
  notFoundRedirect,
  startLoaderSuccess,
  authInterceptor,
  stopLoaderError,
  stopLoaderSuccess
}
