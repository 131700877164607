<template>
  <span class="text-no-wrap">
    <slot />
    <span class="uom-tag">
      {{ tag }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'UomTag',
  props: {
    tag: {
      type: String,
      default: 'lbs'
    }
  }
}
</script>

<style>
.uom-tag {
  font-size: 8px;
}
</style>
