<!--
Tooltip consists of two slots
DEFAULT slot is the tooltip info
Optional wrap-me slot replaces the info icon
<info-tooltip>
  <template v-slot:wrap-me>
    Something to wrap
  </template>
  the tooltip
</info-tooltip>
-->
<template>
  <v-tooltip
    v-bind="[$attrs, $props]"
    v-on="$listeners"
    bottom
  >
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <slot name="wrap-me">
          <v-icon
            class="pb-1 d-print-none"
            small
          >
            mdi-information-variant
          </v-icon>
        </slot>
      </span>
    </template>
    <span>
      <slot />
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'InfoTooltip'
}
</script>
