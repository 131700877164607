import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import Ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#37474F',
        secondary: '#FF1744',
        accent: '#FF1744',
        error: '#b71c1c'
      }
    }
  }
})

export default vuetify
