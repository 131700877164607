<!--Breadcrumbs can be build in three ways
* supply an array [name, to]
* provide a url with a registered reverse
* a simple name --- not a link
-->
<template>
  <v-breadcrumbs
    class="px-0 text-capitalize d-print-none"
    :items="crumbs"
  />
</template>

<script>
export default {
  name: 'UrlBreadcrumbs',
  props: {
    // Array of URLs / route names
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
  },
  computed: {
    crumbs () {
      const breadcrumbs = []
      this.items.forEach(item => {
        if (Array.isArray(item) && item.length === 2) {
          breadcrumbs.push({ text: item[0], to: item[1], exact: true })
          return
        }
        try {
          const [text, to] = this.urlToRouteTo(item)
          breadcrumbs.push({ text, to, exact: true })
        } catch (e) {
          breadcrumbs.push({ text: item, disabled: true, exact: true })
        }
      })
      return breadcrumbs
    }
  }
}
</script>
