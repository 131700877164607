import axios from 'axios'

export default {
  props: {
    baseUrl: { type: String, required: true },
    value: { type: [Object, String, Number, Array], required: false, default: null },
    minSearchLength: { type: Number, required: false, default: 1 },
    itemValue: { type: String, required: false, default: 'id' },
    disabled: { type: Boolean, required: false, default: false },
    searchField: { type: String, required: false, default: 'name' },
    searchParams: { type: Object, required: false, default: null },
    pluralName: { type: String, required: false, default: null },
    name: { type: String, required: false, default: null },
    returnObject: { type: Boolean, required: false, default () { return this.returnObjectDetailed } },
    unfiltered: { type: Boolean, required: false, default: false },
    addedText: { type: String, required: false, default: null },
    nonSearchItems: { type: Array, required: false, default: null },
    returnObjectDetailed: { type: Boolean, required: false, default: false }
  },
  data: () => ({
    distance: 0,
    entities: [],
    itemsPerPage: 20,
    loadingData: false,
    usingInitialLoader: true,
    page: 1,
    prevSearch: null,
    response: {},
    search: null,
    searchTimeout: null
  }),
  computed: {
    // count of total results
    count () {
      if (!this.response || typeof this.response !== 'object') return 0
      if (this.response.hasOwnProperty('count')) return this.response.count
      if (this.response.hasOwnProperty('query')) return this.response.query.results
      return 0
    },
    // number of pages
    pageCount () {
      return Math.ceil(this.count / this.itemsPerPage)
    },
    // get the plural name if one is not provided
    namePlural () {
      if (!this.pluralName) {
        const arr = this.baseUrl.split('/').filter(val => val !== '')
        return arr[arr.length - 1].split('_').map(val => this.capitalize(val)).join(' ')
      }
      return this.pluralName
    },
    nameSingular () {
      return this.name || this.namePlural.substring(0, this.namePlural.length - 1)
    },
    // Allow for multiple values to be selected without preventing search or removing the selected value
    allItems () {
      let result = []
      if (this.nonSearchItems) result = this.nonSearchItems
      result = [...result, ...this.entities]
      if (Array.isArray(this.value)) result = [...this.value, ...result]
      return result
    }
  },
  watch: {
    search ($state) {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        if ($state || $state === '') this.loadMore($state)
        this.prevSearch = this.search
      }, 1000)
    }
  },
  methods: {
    /**
     * Calls the api for entities related to the url string
     * @param {Object or Null} $state
     * @returns {Promise<void>}
     */
    async fetchPage ($state = null) {
      try {
        let params = { page: this.page }
        params.unfiltered_search = this.unfiltered
        params[`${this.searchField}__icontains`] = this.search
        if (this.search) params.search = this.search
        if (this.searchParams) params = { ...params, ...this.searchParams }
        if (this.page >= this.pageCount && this.isInfinite($state)) {
          $state.complete()
          this.loadingData = false
          return
        }
        const response = await axios.get(this.baseUrl, { params })
        if (this.isInfinite($state) && this.page <= this.pageCount) $state.loaded()
        this.response = response.data
        if (this.page === 1) this.entities = response.data.results
        else this.entities.push(...response.data.results)
        this.loadingData = false
        this.distance = 0
      } catch (err) {
        this.$store.commit(
          'setSnackbarError', `An error occurred while trying to get ${this.namePlural}`, { root: true }
        )
      }
    },
    /**
     * Clear the search and set search results to []
     */
    clearSearch () {
      this.search = ''
      this.page = 1
      if (this.$refs.InfiniteLoading) this.$refs.InfiniteLoading.stateChanger.reset()
      this.loadInitialData('', false)
    },
    /**
     * Load additional items based on either the search or infinite loader
     * @param {String or Object} $state
     */
    async loadMore ($state) {
      if (this.loadingData || (this.search < this.minSearchLength && !this.usingInitialLoader)) return
      this.loadingData = true
      this.distance = -Infinity
      if (!this.search) {
        if (!this.usingInitialLoader || this.search !== this.prevSearch) this.page = 0
        await this.loadInitialData($state)
        return
      }
      if (!this.isInfinite($state) || this.search !== this.prevSearch) {
        this.page = 1
        if (this.$refs.InfiniteLoading) this.$refs.InfiniteLoading.stateChanger.reset()
      } else this.page += 1
      await this.fetchPage($state)
    },
    /**
     * Load the initial set of data for the user to avoid confussion
     * @param {String || Object} $state
     * @param {boolean} page
     */
    async loadInitialData ($state, incrementPage = true) {
      this.usingInitialLoader = true
      this.loadingData = true
      if (incrementPage) this.page++
      await this.fetchPage($state)
    },
    /**
     * Is the value coming from infinite loadingData
     * @param {} $state
     * @return {Boolean}
     */
    isInfinite ($state) {
      return $state && typeof $state === 'object'
    },
    /**
     * Update the value of the v-model entity
     * @param {Integer} value
     */
    async updateValue (value) {
      if (value && this.returnObjectDetailed && !this.multiple) {
        const response = await axios.get(`${this.baseUrl}${value.id}/`)
        value = response.data
      }
      this.$emit('input', value)
    }
  }
}
