import factories from '../generics/factories'
const { factory, createMixin, fetchMixin, patchMixin } = factories

const store = factory(
  {
    entityName: 'InventoryBox',
    urlTemplate: id => id ? `api/inventory_boxes/${id}/` : `api/inventory_boxes/`
  },
  createMixin,
  fetchMixin,
  patchMixin
)

export default store
