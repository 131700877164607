<template>
  <v-container>
    <base-title v-if="pluralName">
      {{ pluralName }}
    </base-title>
    <v-list three-line>
      <template v-if="!items || items.length < 1">
        <v-card
          flat
          class="mx-auto"
        >
          <v-card-text
            class="text-center display-3 mt-10"
          >
            All Clear <v-icon class="display-3 mb-2">
              mdi-checkbox-marked-circle-outline
            </v-icon>
          </v-card-text>
        </v-card>
      </template>
      <template
        v-else
        v-for="(entity, index) in entityList"
      >
        <v-subheader
          v-if="entity.header"
          :key="entity.header"
          v-text="entity.header"
        />
        <v-divider
          v-else-if="entity.divider"
          :key="index"
        />
        <v-list-item
          v-else
          :key="entity.title"
          @click="$emit('click', entity)"
        >
          <v-list-item-content>
            <v-list-item-title>
              <strong>{{ entity.document }}</strong>
              {{ entity.description }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="text-right"
              v-html="entity.created"
            />
          </v-list-item-content>
        </v-list-item>
      </template>
      <infinite-loading
        class="mt-4"
        v-if="entityList.length >= apiPageSize"
        @infinite="loadMoreItems"
        spinner="waveDots"
      >
        <template v-slot:no-more>
          There are no more {{ pluralName.toLowerCase() }}
        </template>
        <template v-slot:no-results>
          There are no more {{ pluralName.toLowerCase() }}
        </template>
      </infinite-loading>
    </v-list>
  </v-container>
</template>

<script>
export default {
  name: 'InfiniteList',
  props: {
    items: { type: Array, required: true },
    apiPageSize: { type: Number, required: true },
    pluralName: { type: String, required: false, default: null }
  },
  computed: {
    entityList () {
      const entityList = this.items.reduce((carry, entity) => {
        carry.push({
          id: entity.id,
          document: entity.document,
          description: entity.description,
          created: 'created ' + new Date(entity.created_at).toDateString()
        })
        carry.push({ divider: true, inset: true })
        return carry
      }, [{ header: `Backlog ${this.pluralName}` }])
      entityList.pop()
      return entityList
    }
  },
  data: () => ({
    page: 1,
    prevNumResults: 0
  }),
  methods: {
    /**
     * Load more items
     * @param {Object} $state
     */
    loadMoreItems ($state) {
      this.page += 1
      this.$store.dispatch(`fetch${this.pluralName}`, { page: this.page }).then(() => {
        let moreResults = false
        const numItems = this.items.length
        if (numItems > this.prevNumResults && numItems % this.apiPageSize === 0) {
          moreResults = true
          this.prevNumResults = numItems
        }
        moreResults ? $state.loaded() : $state.complete()
      })
    }
  }
}
</script>
