<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
    >
      <date-input
        v-model="mutableStartDate"
        :label="startDateLabel ? startDateLabel : 'Start Date'"
        :min="minStartDate ? minStartDate : null"
        :max="maxStartDate ? maxStartDate : mutableEndDate === '' ? null : new Date(mutableEndDate).toISOString()"
        @input="alterStartDate"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-input
        v-model="mutableEndDate"
        :label="endDateLabel ? endDateLabel : 'End Date'"
        :min="minEndDate ? minEndDate : mutableStartDate === '' ? null : new Date(mutableStartDate).toISOString()"
        :max="maxEndDate ? maxEndDate : null"
        @input="alterEndDate"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DateRangeInput',
  props: {
    startDate: { type: String, required: true },
    endDate: { type: String, required: true },
    startDateLabel: { type: String, required: false, default: null },
    endDateLabel: { type: String, required: false, default: null },
    minStartDate: { type: String, required: false, default: null },
    minEndDate: { type: String, required: false, default: null },
    maxStartDate: { type: String, required: false, default: null },
    maxEndDate: { type: String, required: false, default: null },
    breakpoint: { type: String, required: false, default: 'xs' }
  },
  data: function () {
    return {
      endDateDialog: false,
      startDateDialog: false,
      mutableStartDate: this.startDate,
      mutableEndDate: this.endDate
    }
  },
  watch: {
    startDate (val) {
      this.mutableStartDate = val
    },
    endDate (val) {
      this.mutableEndDate = val
    }
  },
  methods: {
    alterEndDate (val) {
      this.$emit('alterEndDate', val)
      this.endDateDialog = false
    },
    alterStartDate (val) {
      this.$emit('alterStartDate', val)
      this.startDateDialog = false
    }
  }
}
</script>
