<template>
  <v-list-item
    v-if="noCols"
    :dense="dense"
  >
    <v-list-item-content>
      <v-list-item-subtitle class="ml-0">
        {{ title }}
        <slot name="title1" />
        <slot name="title-button" />
      </v-list-item-subtitle>
      <v-list-item-content>
        <slot>{{ content }}</slot>
      </v-list-item-content>
    </v-list-item-content>
  </v-list-item>
  <v-col
    v-else
    class="py-0"
    :cols="$vuetify.breakpoint[breakpoint] ? breakpointColVal : otherColVal"
  >
    <v-list-item
      :dense="dense"
    >
      <v-list-item-content>
        <v-list-item-subtitle>
          {{ title }}
          <slot name="title1" />
          <slot name="title-button" />
        </v-list-item-subtitle>
        <v-list-item-action class="ml-0">
          <slot>{{ content }}</slot>
        </v-list-item-action>
      </v-list-item-content>
    </v-list-item>
  </v-col>
</template>

<script>
export default {
  name: 'CardListItem',
  props: {
    title: { type: String, required: false, default: null },
    content: { type: String, required: false, default: null },
    breakpointColVal: { type: Number, required: false, default: 12 },
    otherColVal: { type: Number, required: false, default: 6 },
    breakpoint: { type: String, required: false, default: 'xs' },
    noCols: { type: Boolean, required: false, default: false },
    dense: { type: Boolean, required: false, default: false }
  }
}
</script>
