import notes from './patch-notes.json'
// Patch notes .json should be an Array[{title, date, by, body}]

export default {
  namespaced: true,
  state: {
    notes: notes,
    seenNoteName: window.localStorage.getItem('seen-notes')
  },
  getters: {
    getNotes: state => state.notes,
    newPatchNote: state => {
      if (state.notes.length === 0) return false
      return state.notes[0].title !== state.seenNoteName
    }
  },
  mutations: {
    markSeen (state) {
      const title = notes && notes[0].title
      window.localStorage.setItem('seen-notes', title)
      state.seenNoteName = title
    }
  }
}
